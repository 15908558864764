export const emojiBaseUrl = 'https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/';

export interface Emoji {
  name: string;
  glyph: string;
  id: number;
  img: string;
}
export const emojiData: Emoji[] = [
  {
    name: 'grinning face',
    glyph: '😀',
    id: 1,
    img: 'Grinning face/Color/grinning_face_color.svg',
  },
  {
    name: 'grinning face with big eyes',
    glyph: '😃',
    id: 2,
    img: 'Grinning face with big eyes/Color/grinning_face_with_big_eyes_color.svg',
  },
  {
    name: 'grinning face with smiling eyes',
    glyph: '😄',
    id: 3,
    img: 'Grinning face with smiling eyes/Color/grinning_face_with_smiling_eyes_color.svg',
  },
  {
    name: 'beaming face with smiling eyes',
    glyph: '😁',
    id: 4,
    img: 'Beaming face with smiling eyes/Color/beaming_face_with_smiling_eyes_color.svg',
  },
  {
    name: 'grinning squinting face',
    glyph: '😆',
    id: 5,
    img: 'Grinning squinting face/Color/grinning_squinting_face_color.svg',
  },
  {
    name: 'grinning face with sweat',
    glyph: '😅',
    id: 6,
    img: 'Grinning face with sweat/Color/grinning_face_with_sweat_color.svg',
  },
  {
    name: 'rolling on the floor laughing',
    glyph: '🤣',
    id: 7,
    img: 'Rolling on the floor laughing/Color/rolling_on_the_floor_laughing_color.svg',
  },
  {
    name: 'face with tears of joy',
    glyph: '😂',
    id: 8,
    img: 'Face with tears of joy/Color/face_with_tears_of_joy_color.svg',
  },
  {
    name: 'slightly smiling face',
    glyph: '🙂',
    id: 9,
    img: 'Slightly smiling face/Color/slightly_smiling_face_color.svg',
  },
  {
    name: 'upside-down face',
    glyph: '🙃',
    id: 10,
    img: 'Upside-down face/Color/upside-down_face_color.svg',
  },
  {
    name: 'melting face',
    glyph: '🫠',
    id: 11,
    img: 'Melting face/Color/melting_face_color.svg',
  },
  {
    name: 'winking face',
    glyph: '😉',
    id: 12,
    img: 'Winking face/Color/winking_face_color.svg',
  },
  {
    name: 'smiling face with smiling eyes',
    glyph: '😊',
    id: 13,
    img: 'Smiling face with smiling eyes/Color/smiling_face_with_smiling_eyes_color.svg',
  },
  {
    name: 'smiling face with halo',
    glyph: '😇',
    id: 14,
    img: 'Smiling face with halo/Color/smiling_face_with_halo_color.svg',
  },
  {
    name: 'smiling face with hearts',
    glyph: '🥰',
    id: 15,
    img: 'Smiling face with hearts/Color/smiling_face_with_hearts_color.svg',
  },
  {
    name: 'smiling face with heart-eyes',
    glyph: '😍',
    id: 16,
    img: 'Smiling face with heart-eyes/Color/smiling_face_with_heart-eyes_color.svg',
  },
  {
    name: 'star-struck',
    glyph: '🤩',
    id: 17,
    img: 'Star-struck/Color/star-struck_color.svg',
  },
  {
    name: 'face blowing a kiss',
    glyph: '😘',
    id: 18,
    img: 'Face blowing a kiss/Color/face_blowing_a_kiss_color.svg',
  },
  {
    name: 'kissing face',
    glyph: '😗',
    id: 19,
    img: 'Kissing face/Color/kissing_face_color.svg',
  },
  {
    name: 'smiling face',
    glyph: '☺️',
    id: 20,
    img: 'Smiling face/Color/smiling_face_color.svg',
  },
  {
    name: 'kissing face with closed eyes',
    glyph: '😚',
    id: 21,
    img: 'Kissing face with closed eyes/Color/kissing_face_with_closed_eyes_color.svg',
  },
  {
    name: 'kissing face with smiling eyes',
    glyph: '😙',
    id: 22,
    img: 'Kissing face with smiling eyes/Color/kissing_face_with_smiling_eyes_color.svg',
  },
  {
    name: 'smiling face with tear',
    glyph: '🥲',
    id: 23,
    img: 'Smiling face with tear/Color/smiling_face_with_tear_color.svg',
  },
  {
    name: 'face savoring food',
    glyph: '😋',
    id: 24,
    img: 'Face savoring food/Color/face_savoring_food_color.svg',
  },
  {
    name: 'face with tongue',
    glyph: '😛',
    id: 25,
    img: 'Face with tongue/Color/face_with_tongue_color.svg',
  },
  {
    name: 'winking face with tongue',
    glyph: '😜',
    id: 26,
    img: 'Winking face with tongue/Color/winking_face_with_tongue_color.svg',
  },
  {
    name: 'zany face',
    glyph: '🤪',
    id: 27,
    img: 'Zany face/Color/zany_face_color.svg',
  },
  {
    name: 'squinting face with tongue',
    glyph: '😝',
    id: 28,
    img: 'Squinting face with tongue/Color/squinting_face_with_tongue_color.svg',
  },
  {
    name: 'money-mouth face',
    glyph: '🤑',
    id: 29,
    img: 'Money-mouth face/Color/money-mouth_face_color.svg',
  },
  {
    name: 'hugging face',
    glyph: '🤗',
    id: 30,
    img: 'Hugging face/Color/hugging_face_color.svg',
  },
  {
    name: 'face with hand over mouth',
    glyph: '🤭',
    id: 31,
    img: 'Face with hand over mouth/Color/face_with_hand_over_mouth_color.svg',
  },
  {
    name: 'face with open eyes and hand over mouth',
    glyph: '🫢',
    id: 32,
    img: 'Face with open eyes and hand over mouth/Color/face_with_open_eyes_and_hand_over_mouth_color.svg',
  },
  {
    name: 'face with peeking eye',
    glyph: '🫣',
    id: 33,
    img: 'Face with peeking eye/Color/face_with_peeking_eye_color.svg',
  },
  {
    name: 'shushing face',
    glyph: '🤫',
    id: 34,
    img: 'Shushing face/Color/shushing_face_color.svg',
  },
  {
    name: 'thinking face',
    glyph: '🤔',
    id: 35,
    img: 'Thinking face/Color/thinking_face_color.svg',
  },
  {
    name: 'saluting face',
    glyph: '🫡',
    id: 36,
    img: 'Saluting face/Color/saluting_face_color.svg',
  },
  {
    name: 'zipper-mouth face',
    glyph: '🤐',
    id: 37,
    img: 'Zipper-mouth face/Color/zipper-mouth_face_color.svg',
  },
  {
    name: 'face with raised eyebrow',
    glyph: '🤨',
    id: 38,
    img: 'Face with raised eyebrow/Color/face_with_raised_eyebrow_color.svg',
  },
  {
    name: 'neutral face',
    glyph: '😐',
    id: 39,
    img: 'Neutral face/Color/neutral_face_color.svg',
  },
  {
    name: 'expressionless face',
    glyph: '😑',
    id: 40,
    img: 'Expressionless face/Color/expressionless_face_color.svg',
  },
  {
    name: 'face without mouth',
    glyph: '😶',
    id: 41,
    img: 'Face without mouth/Color/face_without_mouth_color.svg',
  },
  {
    name: 'dotted line face',
    glyph: '🫥',
    id: 42,
    img: 'Dotted line face/Color/dotted_line_face_color.svg',
  },
  {
    name: 'face in clouds',
    glyph: '😶‍🌫️',
    id: 43,
    img: 'Face in clouds/Color/face_in_clouds_color.svg',
  },
  {
    name: 'smirking face',
    glyph: '😏',
    id: 44,
    img: 'Smirking face/Color/smirking_face_color.svg',
  },
  {
    name: 'unamused face',
    glyph: '😒',
    id: 45,
    img: 'Unamused face/Color/unamused_face_color.svg',
  },
  {
    name: 'face with rolling eyes',
    glyph: '🙄',
    id: 46,
    img: 'Face with rolling eyes/Color/face_with_rolling_eyes_color.svg',
  },
  {
    name: 'grimacing face',
    glyph: '😬',
    id: 47,
    img: 'Grimacing face/Color/grimacing_face_color.svg',
  },
  {
    name: 'face exhaling',
    glyph: '😮‍💨',
    id: 48,
    img: 'Face exhaling/Color/face_exhaling_color.svg',
  },
  {
    name: 'lying face',
    glyph: '🤥',
    id: 49,
    img: 'Lying face/Color/lying_face_color.svg',
  },
  {
    name: 'relieved face',
    glyph: '😌',
    id: 50,
    img: 'Relieved face/Color/relieved_face_color.svg',
  },
  {
    name: 'pensive face',
    glyph: '😔',
    id: 51,
    img: 'Pensive face/Color/pensive_face_color.svg',
  },
  {
    name: 'sleepy face',
    glyph: '😪',
    id: 52,
    img: 'Sleepy face/Color/sleepy_face_color.svg',
  },
  {
    name: 'drooling face',
    glyph: '🤤',
    id: 53,
    img: 'Drooling face/Color/drooling_face_color.svg',
  },
  {
    name: 'sleeping face',
    glyph: '😴',
    id: 54,
    img: 'Sleeping face/Color/sleeping_face_color.svg',
  },
  {
    name: 'face with medical mask',
    glyph: '😷',
    id: 55,
    img: 'Face with medical mask/Color/face_with_medical_mask_color.svg',
  },
  {
    name: 'face with thermometer',
    glyph: '🤒',
    id: 56,
    img: 'Face with thermometer/Color/face_with_thermometer_color.svg',
  },
  {
    name: 'face with head-bandage',
    glyph: '🤕',
    id: 57,
    img: 'Face with head-bandage/Color/face_with_head-bandage_color.svg',
  },
  {
    name: 'nauseated face',
    glyph: '🤢',
    id: 58,
    img: 'Nauseated face/Color/nauseated_face_color.svg',
  },
  {
    name: 'face vomiting',
    glyph: '🤮',
    id: 59,
    img: 'Face vomiting/Color/face_vomiting_color.svg',
  },
  {
    name: 'sneezing face',
    glyph: '🤧',
    id: 60,
    img: 'Sneezing face/Color/sneezing_face_color.svg',
  },
  {
    name: 'hot face',
    glyph: '🥵',
    id: 61,
    img: 'Hot face/Color/hot_face_color.svg',
  },
  {
    name: 'cold face',
    glyph: '🥶',
    id: 62,
    img: 'Cold face/Color/cold_face_color.svg',
  },
  {
    name: 'woozy face',
    glyph: '🥴',
    id: 63,
    img: 'Woozy face/Color/woozy_face_color.svg',
  },
  {
    name: 'knocked-out face',
    glyph: '😵',
    id: 64,
    img: 'Knocked-out face/Color/knocked-out_face_color.svg',
  },
  {
    name: 'face with spiral eyes',
    glyph: '😵‍💫',
    id: 65,
    img: 'Face with spiral eyes/Color/face_with_spiral_eyes_color.svg',
  },
  {
    name: 'exploding head',
    glyph: '🤯',
    id: 66,
    img: 'Exploding head/Color/exploding_head_color.svg',
  },
  {
    name: 'cowboy hat face',
    glyph: '🤠',
    id: 67,
    img: 'Cowboy hat face/Color/cowboy_hat_face_color.svg',
  },
  {
    name: 'partying face',
    glyph: '🥳',
    id: 68,
    img: 'Partying face/Color/partying_face_color.svg',
  },
  {
    name: 'disguised face',
    glyph: '🥸',
    id: 69,
    img: 'Disguised face/Color/disguised_face_color.svg',
  },
  {
    name: 'smiling face with sunglasses',
    glyph: '😎',
    id: 70,
    img: 'Smiling face with sunglasses/Color/smiling_face_with_sunglasses_color.svg',
  },
  {
    name: 'nerd face',
    glyph: '🤓',
    id: 71,
    img: 'Nerd face/Color/nerd_face_color.svg',
  },
  {
    name: 'face with monocle',
    glyph: '🧐',
    id: 72,
    img: 'Face with monocle/Color/face_with_monocle_color.svg',
  },
  {
    name: 'confused face',
    glyph: '😕',
    id: 73,
    img: 'Confused face/Color/confused_face_color.svg',
  },
  {
    name: 'face with diagonal mouth',
    glyph: '🫤',
    id: 74,
    img: 'Face with diagonal mouth/Color/face_with_diagonal_mouth_color.svg',
  },
  {
    name: 'worried face',
    glyph: '😟',
    id: 75,
    img: 'Worried face/Color/worried_face_color.svg',
  },
  {
    name: 'slightly frowning face',
    glyph: '🙁',
    id: 76,
    img: 'Slightly frowning face/Color/slightly_frowning_face_color.svg',
  },
  {
    name: 'frowning face',
    glyph: '☹️',
    id: 77,
    img: 'Frowning face/Color/frowning_face_color.svg',
  },
  {
    name: 'face with open mouth',
    glyph: '😮',
    id: 78,
    img: 'Face with open mouth/Color/face_with_open_mouth_color.svg',
  },
  {
    name: 'hushed face',
    glyph: '😯',
    id: 79,
    img: 'Hushed face/Color/hushed_face_color.svg',
  },
  {
    name: 'astonished face',
    glyph: '😲',
    id: 80,
    img: 'Astonished face/Color/astonished_face_color.svg',
  },
  {
    name: 'flushed face',
    glyph: '😳',
    id: 81,
    img: 'Flushed face/Color/flushed_face_color.svg',
  },
  {
    name: 'pleading face',
    glyph: '🥺',
    id: 82,
    img: 'Pleading face/Color/pleading_face_color.svg',
  },
  {
    name: 'face holding back tears',
    glyph: '🥹',
    id: 83,
    img: 'Face holding back tears/Color/face_holding_back_tears_color.svg',
  },
  {
    name: 'frowning face with open mouth',
    glyph: '😦',
    id: 84,
    img: 'Frowning face with open mouth/Color/frowning_face_with_open_mouth_color.svg',
  },
  {
    name: 'anguished face',
    glyph: '😧',
    id: 85,
    img: 'Anguished face/Color/anguished_face_color.svg',
  },
  {
    name: 'fearful face',
    glyph: '😨',
    id: 86,
    img: 'Fearful face/Color/fearful_face_color.svg',
  },
  {
    name: 'anxious face with sweat',
    glyph: '😰',
    id: 87,
    img: 'Anxious face with sweat/Color/anxious_face_with_sweat_color.svg',
  },
  {
    name: 'sad but relieved face',
    glyph: '😥',
    id: 88,
    img: 'Sad but relieved face/Color/sad_but_relieved_face_color.svg',
  },
  {
    name: 'crying face',
    glyph: '😢',
    id: 89,
    img: 'Crying face/Color/crying_face_color.svg',
  },
  {
    name: 'loudly crying face',
    glyph: '😭',
    id: 90,
    img: 'Loudly crying face/Color/loudly_crying_face_color.svg',
  },
  {
    name: 'face screaming in fear',
    glyph: '😱',
    id: 91,
    img: 'Face screaming in fear/Color/face_screaming_in_fear_color.svg',
  },
  {
    name: 'confounded face',
    glyph: '😖',
    id: 92,
    img: 'Confounded face/Color/confounded_face_color.svg',
  },
  {
    name: 'persevering face',
    glyph: '😣',
    id: 93,
    img: 'Persevering face/Color/persevering_face_color.svg',
  },
  {
    name: 'disappointed face',
    glyph: '😞',
    id: 94,
    img: 'Disappointed face/Color/disappointed_face_color.svg',
  },
  {
    name: 'downcast face with sweat',
    glyph: '😓',
    id: 95,
    img: 'Downcast face with sweat/Color/downcast_face_with_sweat_color.svg',
  },
  {
    name: 'weary face',
    glyph: '😩',
    id: 96,
    img: 'Weary face/Color/weary_face_color.svg',
  },
  {
    name: 'tired face',
    glyph: '😫',
    id: 97,
    img: 'Tired face/Color/tired_face_color.svg',
  },
  {
    name: 'yawning face',
    glyph: '🥱',
    id: 98,
    img: 'Yawning face/Color/yawning_face_color.svg',
  },
  {
    name: 'face with steam from nose',
    glyph: '😤',
    id: 99,
    img: 'Face with steam from nose/Color/face_with_steam_from_nose_color.svg',
  },
  {
    name: 'pouting face',
    glyph: '😡',
    id: 100,
    img: 'Pouting face/Color/pouting_face_color.svg',
  },
  {
    name: 'angry face',
    glyph: '😠',
    id: 101,
    img: 'Angry face/Color/angry_face_color.svg',
  },
  {
    name: 'face with symbols on mouth',
    glyph: '🤬',
    id: 102,
    img: 'Face with symbols on mouth/Color/face_with_symbols_on_mouth_color.svg',
  },
  {
    name: 'smiling face with horns',
    glyph: '😈',
    id: 103,
    img: 'Smiling face with horns/Color/smiling_face_with_horns_color.svg',
  },
  {
    name: 'angry face with horns',
    glyph: '👿',
    id: 104,
    img: 'Angry face with horns/Color/angry_face_with_horns_color.svg',
  },
  {
    name: 'skull',
    glyph: '💀',
    id: 105,
    img: 'Skull/Color/skull_color.svg',
  },
  {
    name: 'skull and crossbones',
    glyph: '☠️',
    id: 106,
    img: 'Skull and crossbones/Color/skull_and_crossbones_color.svg',
  },
  {
    name: 'pile of poo',
    glyph: '💩',
    id: 107,
    img: 'Pile of poo/Color/pile_of_poo_color.svg',
  },
  {
    name: 'clown face',
    glyph: '🤡',
    id: 108,
    img: 'Clown face/Color/clown_face_color.svg',
  },
  {
    name: 'ogre',
    glyph: '👹',
    id: 109,
    img: 'Ogre/Color/ogre_color.svg',
  },
  {
    name: 'goblin',
    glyph: '👺',
    id: 110,
    img: 'Goblin/Color/goblin_color.svg',
  },
  {
    name: 'ghost',
    glyph: '👻',
    id: 111,
    img: 'Ghost/Color/ghost_color.svg',
  },
  {
    name: 'alien',
    glyph: '👽',
    id: 112,
    img: 'Alien/Color/alien_color.svg',
  },
  {
    name: 'alien monster',
    glyph: '👾',
    id: 113,
    img: 'Alien monster/Color/alien_monster_color.svg',
  },
  {
    name: 'robot',
    glyph: '🤖',
    id: 114,
    img: 'Robot/Color/robot_color.svg',
  },
  {
    name: 'grinning cat',
    glyph: '😺',
    id: 115,
    img: 'Grinning cat/Color/grinning_cat_color.svg',
  },
  {
    name: 'grinning cat with smiling eyes',
    glyph: '😸',
    id: 116,
    img: 'Grinning cat with smiling eyes/Color/grinning_cat_with_smiling_eyes_color.svg',
  },
  {
    name: 'cat with tears of joy',
    glyph: '😹',
    id: 117,
    img: 'Cat with tears of joy/Color/cat_with_tears_of_joy_color.svg',
  },
  {
    name: 'smiling cat with heart-eyes',
    glyph: '😻',
    id: 118,
    img: 'Smiling cat with heart-eyes/Color/smiling_cat_with_heart-eyes_color.svg',
  },
  {
    name: 'cat with wry smile',
    glyph: '😼',
    id: 119,
    img: 'Cat with wry smile/Color/cat_with_wry_smile_color.svg',
  },
  {
    name: 'kissing cat',
    glyph: '😽',
    id: 120,
    img: 'Kissing cat/Color/kissing_cat_color.svg',
  },
  {
    name: 'weary cat',
    glyph: '🙀',
    id: 121,
    img: 'Weary cat/Color/weary_cat_color.svg',
  },
  {
    name: 'crying cat',
    glyph: '😿',
    id: 122,
    img: 'Crying cat/Color/crying_cat_color.svg',
  },
  {
    name: 'pouting cat',
    glyph: '😾',
    id: 123,
    img: 'Pouting cat/Color/pouting_cat_color.svg',
  },
  {
    name: 'see-no-evil monkey',
    glyph: '🙈',
    id: 124,
    img: 'See-no-evil monkey/Color/see-no-evil_monkey_color.svg',
  },
  {
    name: 'hear-no-evil monkey',
    glyph: '🙉',
    id: 125,
    img: 'Hear-no-evil monkey/Color/hear-no-evil_monkey_color.svg',
  },
  {
    name: 'speak-no-evil monkey',
    glyph: '🙊',
    id: 126,
    img: 'Speak-no-evil monkey/Color/speak-no-evil_monkey_color.svg',
  },
  {
    name: 'kiss mark',
    glyph: '💋',
    id: 127,
    img: 'Kiss mark/Color/kiss_mark_color.svg',
  },
  {
    name: 'love letter',
    glyph: '💌',
    id: 128,
    img: 'Love letter/Color/love_letter_color.svg',
  },
  {
    name: 'heart with arrow',
    glyph: '💘',
    id: 129,
    img: 'Heart with arrow/Color/heart_with_arrow_color.svg',
  },
  {
    name: 'heart with ribbon',
    glyph: '💝',
    id: 130,
    img: 'Heart with ribbon/Color/heart_with_ribbon_color.svg',
  },
  {
    name: 'sparkling heart',
    glyph: '💖',
    id: 131,
    img: 'Sparkling heart/Color/sparkling_heart_color.svg',
  },
  {
    name: 'growing heart',
    glyph: '💗',
    id: 132,
    img: 'Growing heart/Color/growing_heart_color.svg',
  },
  {
    name: 'beating heart',
    glyph: '💓',
    id: 133,
    img: 'Beating heart/Color/beating_heart_color.svg',
  },
  {
    name: 'revolving hearts',
    glyph: '💞',
    id: 134,
    img: 'Revolving hearts/Color/revolving_hearts_color.svg',
  },
  {
    name: 'two hearts',
    glyph: '💕',
    id: 135,
    img: 'Two hearts/Color/two_hearts_color.svg',
  },
  {
    name: 'heart decoration',
    glyph: '💟',
    id: 136,
    img: 'Heart decoration/Color/heart_decoration_color.svg',
  },
  {
    name: 'heart exclamation',
    glyph: '❣️',
    id: 137,
    img: 'Heart exclamation/Color/heart_exclamation_color.svg',
  },
  {
    name: 'broken heart',
    glyph: '💔',
    id: 138,
    img: 'Broken heart/Color/broken_heart_color.svg',
  },
  {
    name: 'heart on fire',
    glyph: '❤️‍🔥',
    id: 139,
    img: 'Heart on fire/Color/heart_on_fire_color.svg',
  },
  {
    name: 'mending heart',
    glyph: '❤️‍🩹',
    id: 140,
    img: 'Mending heart/Color/mending_heart_color.svg',
  },
  {
    name: 'red heart',
    glyph: '❤️',
    id: 141,
    img: 'Red heart/Color/red_heart_color.svg',
  },
  {
    name: 'orange heart',
    glyph: '🧡',
    id: 142,
    img: 'Orange heart/Color/orange_heart_color.svg',
  },
  {
    name: 'yellow heart',
    glyph: '💛',
    id: 143,
    img: 'Yellow heart/Color/yellow_heart_color.svg',
  },
  {
    name: 'green heart',
    glyph: '💚',
    id: 144,
    img: 'Green heart/Color/green_heart_color.svg',
  },
  {
    name: 'blue heart',
    glyph: '💙',
    id: 145,
    img: 'Blue heart/Color/blue_heart_color.svg',
  },
  {
    name: 'purple heart',
    glyph: '💜',
    id: 146,
    img: 'Purple heart/Color/purple_heart_color.svg',
  },
  {
    name: 'brown heart',
    glyph: '🤎',
    id: 147,
    img: 'Brown heart/Color/brown_heart_color.svg',
  },
  {
    name: 'black heart',
    glyph: '🖤',
    id: 148,
    img: 'Black heart/Color/black_heart_color.svg',
  },
  {
    name: 'white heart',
    glyph: '🤍',
    id: 149,
    img: 'White heart/Color/white_heart_color.svg',
  },
  {
    name: 'hundred points',
    glyph: '💯',
    id: 150,
    img: 'Hundred points/Color/hundred_points_color.svg',
  },
  {
    name: 'anger symbol',
    glyph: '💢',
    id: 151,
    img: 'Anger symbol/Color/anger_symbol_color.svg',
  },
  {
    name: 'collision',
    glyph: '💥',
    id: 152,
    img: 'Collision/Color/collision_color.svg',
  },
  {
    name: 'dizzy',
    glyph: '💫',
    id: 153,
    img: 'Dizzy/Color/dizzy_color.svg',
  },
  {
    name: 'sweat droplets',
    glyph: '💦',
    id: 154,
    img: 'Sweat droplets/Color/sweat_droplets_color.svg',
  },
  {
    name: 'dashing away',
    glyph: '💨',
    id: 155,
    img: 'Dashing away/Color/dashing_away_color.svg',
  },
  {
    name: 'hole',
    glyph: '🕳️',
    id: 156,
    img: 'Hole/Color/hole_color.svg',
  },
  {
    name: 'bomb',
    glyph: '💣',
    id: 157,
    img: 'Bomb/Color/bomb_color.svg',
  },
  {
    name: 'speech balloon',
    glyph: '💬',
    id: 158,
    img: 'Speech balloon/Color/speech_balloon_color.svg',
  },
  {
    name: 'eye in speech bubble',
    glyph: '👁️‍🗨️',
    id: 159,
    img: 'Eye in speech bubble/Color/eye_in_speech_bubble_color.svg',
  },
  {
    name: 'left speech bubble',
    glyph: '🗨️',
    id: 160,
    img: 'Left speech bubble/Color/left_speech_bubble_color.svg',
  },
  {
    name: 'right anger bubble',
    glyph: '🗯️',
    id: 161,
    img: 'Right anger bubble/Color/right_anger_bubble_color.svg',
  },
  {
    name: 'thought balloon',
    glyph: '💭',
    id: 162,
    img: 'Thought balloon/Color/thought_balloon_color.svg',
  },
  {
    name: 'zzz',
    glyph: '💤',
    id: 163,
    img: 'Zzz/Color/zzz_color.svg',
  },
  {
    name: 'waving hand',
    glyph: '👋',
    id: 164,
    img: 'Waving hand/Default/Color/waving_hand_color_default.svg',
  },
  {
    name: 'raised back of hand',
    glyph: '🤚',
    id: 165,
    img: 'Raised back of hand/Default/Color/raised_back_of_hand_color_default.svg',
  },
  {
    name: 'hand with fingers splayed',
    glyph: '🖐️',
    id: 166,
    img: 'Hand with fingers splayed/Default/Color/hand_with_fingers_splayed_color_default.svg',
  },
  {
    name: 'raised hand',
    glyph: '✋',
    id: 167,
    img: 'Raised hand/Default/Color/raised_hand_color_default.svg',
  },
  {
    name: 'vulcan salute',
    glyph: '🖖',
    id: 168,
    img: 'Vulcan salute/Default/Color/vulcan_salute_color_default.svg',
  },
  {
    name: 'rightwards hand',
    glyph: '🫱',
    id: 169,
    img: 'Rightwards hand/Default/Color/rightwards_hand_color_default.svg',
  },
  {
    name: 'leftwards hand',
    glyph: '🫲',
    id: 170,
    img: 'Leftwards hand/Default/Color/leftwards_hand_color_default.svg',
  },
  {
    name: 'palm down hand',
    glyph: '🫳',
    id: 171,
    img: 'Palm down hand/Default/Color/palm_down_hand_color_default.svg',
  },
  {
    name: 'palm up hand',
    glyph: '🫴',
    id: 172,
    img: 'Palm up hand/Default/Color/palm_up_hand_color_default.svg',
  },
  {
    name: 'OK hand',
    glyph: '👌',
    id: 173,
    img: 'Ok hand/Default/Color/ok_hand_color_default.svg',
  },
  {
    name: 'pinched fingers',
    glyph: '🤌',
    id: 174,
    img: 'Pinched fingers/Default/Color/pinched_fingers_color_default.svg',
  },
  {
    name: 'pinching hand',
    glyph: '🤏',
    id: 175,
    img: 'Pinching hand/Default/Color/pinching_hand_color_default.svg',
  },
  {
    name: 'victory hand',
    glyph: '✌️',
    id: 176,
    img: 'Victory hand/Default/Color/victory_hand_color_default.svg',
  },
  {
    name: 'crossed fingers',
    glyph: '🤞',
    id: 177,
    img: 'Crossed fingers/Default/Color/crossed_fingers_color_default.svg',
  },
  {
    name: 'hand with index finger and thumb crossed',
    glyph: '🫰',
    id: 178,
    img: 'Hand with index finger and thumb crossed/Default/Color/hand_with_index_finger_and_thumb_crossed_color_default.svg',
  },
  {
    name: 'love-you gesture',
    glyph: '🤟',
    id: 179,
    img: 'Love-you gesture/Default/Color/love-you_gesture_color_default.svg',
  },
  {
    name: 'sign of the horns',
    glyph: '🤘',
    id: 180,
    img: 'Sign of the horns/Default/Color/sign_of_the_horns_color_default.svg',
  },
  {
    name: 'call me hand',
    glyph: '🤙',
    id: 181,
    img: 'Call me hand/Default/Color/call_me_hand_color_default.svg',
  },
  {
    name: 'backhand index pointing left',
    glyph: '👈',
    id: 182,
    img: 'Backhand index pointing left/Default/Color/backhand_index_pointing_left_color_default.svg',
  },
  {
    name: 'backhand index pointing right',
    glyph: '👉',
    id: 183,
    img: 'Backhand index pointing right/Default/Color/backhand_index_pointing_right_color_default.svg',
  },
  {
    name: 'backhand index pointing up',
    glyph: '👆',
    id: 184,
    img: 'Backhand index pointing up/Default/Color/backhand_index_pointing_up_color_default.svg',
  },
  {
    name: 'middle finger',
    glyph: '🖕',
    id: 185,
    img: 'Middle finger/Default/Color/middle_finger_color_default.svg',
  },
  {
    name: 'backhand index pointing down',
    glyph: '👇',
    id: 186,
    img: 'Backhand index pointing down/Default/Color/backhand_index_pointing_down_color_default.svg',
  },
  {
    name: 'index pointing up',
    glyph: '☝️',
    id: 187,
    img: 'Index pointing up/Default/Color/index_pointing_up_color_default.svg',
  },
  {
    name: 'index pointing at the viewer',
    glyph: '🫵',
    id: 188,
    img: 'Index pointing at the viewer/Default/Color/index_pointing_at_the_viewer_color_default.svg',
  },
  {
    name: 'thumbs up',
    glyph: '👍',
    id: 189,
    img: 'Thumbs up/Default/Color/thumbs_up_color_default.svg',
  },
  {
    name: 'thumbs down',
    glyph: '👎',
    id: 190,
    img: 'Thumbs down/Default/Color/thumbs_down_color_default.svg',
  },
  {
    name: 'raised fist',
    glyph: '✊',
    id: 191,
    img: 'Raised fist/Default/Color/raised_fist_color_default.svg',
  },
  {
    name: 'oncoming fist',
    glyph: '👊',
    id: 192,
    img: 'Oncoming fist/Default/Color/oncoming_fist_color_default.svg',
  },
  {
    name: 'left-facing fist',
    glyph: '🤛',
    id: 193,
    img: 'Left-facing fist/Default/Color/left-facing_fist_color_default.svg',
  },
  {
    name: 'right-facing fist',
    glyph: '🤜',
    id: 194,
    img: 'Right-facing fist/Default/Color/right-facing_fist_color_default.svg',
  },
  {
    name: 'clapping hands',
    glyph: '👏',
    id: 195,
    img: 'Clapping hands/Default/Color/clapping_hands_color_default.svg',
  },
  {
    name: 'raising hands',
    glyph: '🙌',
    id: 196,
    img: 'Raising hands/Default/Color/raising_hands_color_default.svg',
  },
  {
    name: 'heart hands',
    glyph: '🫶',
    id: 197,
    img: 'Heart hands/Default/Color/heart_hands_color_default.svg',
  },
  {
    name: 'open hands',
    glyph: '👐',
    id: 198,
    img: 'Open hands/Default/Color/open_hands_color_default.svg',
  },
  {
    name: 'palms up together',
    glyph: '🤲',
    id: 199,
    img: 'Palms up together/Default/Color/palms_up_together_color_default.svg',
  },
  {
    name: 'handshake',
    glyph: '🤝',
    id: 200,
    img: 'Handshake/Color/handshake_color.svg',
  },
  {
    name: 'folded hands',
    glyph: '🙏',
    id: 201,
    img: 'Folded hands/Default/Color/folded_hands_color_default.svg',
  },
  {
    name: 'writing hand',
    glyph: '✍️',
    id: 202,
    img: 'Writing hand/Default/Color/writing_hand_color_default.svg',
  },
  {
    name: 'nail polish',
    glyph: '💅',
    id: 203,
    img: 'Nail polish/Default/Color/nail_polish_color_default.svg',
  },
  {
    name: 'selfie',
    glyph: '🤳',
    id: 204,
    img: 'Selfie/Default/Color/selfie_color_default.svg',
  },
  {
    name: 'flexed biceps',
    glyph: '💪',
    id: 205,
    img: 'Flexed biceps/Default/Color/flexed_biceps_color_default.svg',
  },
  {
    name: 'mechanical arm',
    glyph: '🦾',
    id: 206,
    img: 'Mechanical arm/Color/mechanical_arm_color.svg',
  },
  {
    name: 'mechanical leg',
    glyph: '🦿',
    id: 207,
    img: 'Mechanical leg/Color/mechanical_leg_color.svg',
  },
  {
    name: 'leg',
    glyph: '🦵',
    id: 208,
    img: 'Leg/Default/Color/leg_color_default.svg',
  },
  {
    name: 'foot',
    glyph: '🦶',
    id: 209,
    img: 'Foot/Default/Color/foot_color_default.svg',
  },
  {
    name: 'ear',
    glyph: '👂',
    id: 210,
    img: 'Ear/Default/Color/ear_color_default.svg',
  },
  {
    name: 'ear with hearing aid',
    glyph: '🦻',
    id: 211,
    img: 'Ear with hearing aid/Default/Color/ear_with_hearing_aid_color_default.svg',
  },
  {
    name: 'nose',
    glyph: '👃',
    id: 212,
    img: 'Nose/Default/Color/nose_color_default.svg',
  },
  {
    name: 'brain',
    glyph: '🧠',
    id: 213,
    img: 'Brain/Color/brain_color.svg',
  },
  {
    name: 'anatomical heart',
    glyph: '🫀',
    id: 214,
    img: 'Anatomical heart/Color/anatomical_heart_color.svg',
  },
  {
    name: 'lungs',
    glyph: '🫁',
    id: 215,
    img: 'Lungs/Color/lungs_color.svg',
  },
  {
    name: 'tooth',
    glyph: '🦷',
    id: 216,
    img: 'Tooth/Color/tooth_color.svg',
  },
  {
    name: 'bone',
    glyph: '🦴',
    id: 217,
    img: 'Bone/Color/bone_color.svg',
  },
  {
    name: 'eyes',
    glyph: '👀',
    id: 218,
    img: 'Eyes/Color/eyes_color.svg',
  },
  {
    name: 'eye',
    glyph: '👁️',
    id: 219,
    img: 'Eye/Color/eye_color.svg',
  },
  {
    name: 'tongue',
    glyph: '👅',
    id: 220,
    img: 'Tongue/Color/tongue_color.svg',
  },
  {
    name: 'mouth',
    glyph: '👄',
    id: 221,
    img: 'Mouth/Color/mouth_color.svg',
  },
  {
    name: 'biting lip',
    glyph: '🫦',
    id: 222,
    img: 'Biting lip/Color/biting_lip_color.svg',
  },
  {
    name: 'baby',
    glyph: '👶',
    id: 223,
    img: 'Baby/Default/Color/baby_color_default.svg',
  },
  {
    name: 'child',
    glyph: '🧒',
    id: 224,
    img: 'Child/Default/Color/child_color_default.svg',
  },
  {
    name: 'boy',
    glyph: '👦',
    id: 225,
    img: 'Boy/Default/Color/boy_color_default.svg',
  },
  {
    name: 'girl',
    glyph: '👧',
    id: 226,
    img: 'Girl/Default/Color/girl_color_default.svg',
  },
  {
    name: 'person',
    glyph: '🧑',
    id: 227,
    img: 'Person/Default/Color/person_color_default.svg',
  },
  {
    name: 'person: blond hair',
    glyph: '👱',
    id: 228,
    img: 'Person blonde hair/Default/Color/person_blonde_hair_color_default.svg',
  },
  {
    name: 'man',
    glyph: '👨',
    id: 229,
    img: 'Man/Default/Color/man_color_default.svg',
  },
  {
    name: 'person: beard',
    glyph: '🧔',
    id: 230,
    img: 'Person beard/Default/Color/person_beard_color_default.svg',
  },
  {
    name: 'man: beard',
    glyph: '🧔‍♂️',
    id: 231,
    img: 'Man beard/Default/Color/man_beard_color_default.svg',
  },
  {
    name: 'woman: beard',
    glyph: '🧔‍♀️',
    id: 232,
    img: 'Woman beard/Default/Color/woman_beard_color_default.svg',
  },
  {
    name: 'man: red hair',
    glyph: '👨‍🦰',
    id: 233,
    img: 'Man red hair/Default/Color/man_red_hair_color_default.svg',
  },
  {
    name: 'man: curly hair',
    glyph: '👨‍🦱',
    id: 234,
    img: 'Man curly hair/Default/Color/man_curly_hair_color_default.svg',
  },
  {
    name: 'man: white hair',
    glyph: '👨‍🦳',
    id: 235,
    img: 'Man white hair/Default/Color/man_white_hair_color_default.svg',
  },
  {
    name: 'man: bald',
    glyph: '👨‍🦲',
    id: 236,
    img: 'Man bald/Default/Color/man_bald_color_default.svg',
  },
  {
    name: 'woman',
    glyph: '👩',
    id: 237,
    img: 'Woman/Default/Color/woman_color_default.svg',
  },
  {
    name: 'woman: red hair',
    glyph: '👩‍🦰',
    id: 238,
    img: 'Woman red hair/Default/Color/woman_red_hair_color_default.svg',
  },
  {
    name: 'person: red hair',
    glyph: '🧑‍🦰',
    id: 239,
    img: 'Person red hair/Default/Color/person_red_hair_color_default.svg',
  },
  {
    name: 'woman: curly hair',
    glyph: '👩‍🦱',
    id: 240,
    img: 'Woman curly hair/Default/Color/woman_curly_hair_color_default.svg',
  },
  {
    name: 'person: curly hair',
    glyph: '🧑‍🦱',
    id: 241,
    img: 'Person curly hair/Default/Color/person_curly_hair_color_default.svg',
  },
  {
    name: 'woman: white hair',
    glyph: '👩‍🦳',
    id: 242,
    img: 'Woman white hair/Default/Color/woman_white_hair_color_default.svg',
  },
  {
    name: 'person: white hair',
    glyph: '🧑‍🦳',
    id: 243,
    img: 'Person white hair/Default/Color/person_white_hair_color_default.svg',
  },
  {
    name: 'woman: bald',
    glyph: '👩‍🦲',
    id: 244,
    img: 'Woman bald/Default/Color/woman_bald_color_default.svg',
  },
  {
    name: 'person: bald',
    glyph: '🧑‍🦲',
    id: 245,
    img: 'Person bald/Default/Color/person_bald_color_default.svg',
  },
  {
    name: 'woman: blond hair',
    glyph: '👱‍♀️',
    id: 246,
    img: 'Woman blonde hair/Default/Color/woman_blonde_hair_color_default.svg',
  },
  {
    name: 'man: blond hair',
    glyph: '👱‍♂️',
    id: 247,
    img: 'Man blonde hair/Default/Color/man_blonde_hair_color_default.svg',
  },
  {
    name: 'older person',
    glyph: '🧓',
    id: 248,
    img: 'Older person/Default/Color/older_person_color_default.svg',
  },
  {
    name: 'old man',
    glyph: '👴',
    id: 249,
    img: 'Old man/Default/Color/old_man_color_default.svg',
  },
  {
    name: 'old woman',
    glyph: '👵',
    id: 250,
    img: 'Old woman/Default/Color/old_woman_color_default.svg',
  },
  {
    name: 'person frowning',
    glyph: '🙍',
    id: 251,
    img: 'Person frowning/Default/Color/person_frowning_color_default.svg',
  },
  {
    name: 'man frowning',
    glyph: '🙍‍♂️',
    id: 252,
    img: 'Man frowning/Default/Color/man_frowning_color_default.svg',
  },
  {
    name: 'woman frowning',
    glyph: '🙍‍♀️',
    id: 253,
    img: 'Woman frowning/Default/Color/woman_frowning_color_default.svg',
  },
  {
    name: 'person pouting',
    glyph: '🙎',
    id: 254,
    img: 'Person pouting/Default/Color/person_pouting_color_default.svg',
  },
  {
    name: 'man pouting',
    glyph: '🙎‍♂️',
    id: 255,
    img: 'Man pouting/Default/Color/man_pouting_color_default.svg',
  },
  {
    name: 'woman pouting',
    glyph: '🙎‍♀️',
    id: 256,
    img: 'Woman pouting/Default/Color/woman_pouting_color_default.svg',
  },
  {
    name: 'person gesturing NO',
    glyph: '🙅',
    id: 257,
    img: 'Person gesturing no/Default/Color/person_gesturing_no_color_default.svg',
  },
  {
    name: 'man gesturing NO',
    glyph: '🙅‍♂️',
    id: 258,
    img: 'Man gesturing no/Default/Color/man_gesturing_no_color_default.svg',
  },
  {
    name: 'woman gesturing NO',
    glyph: '🙅‍♀️',
    id: 259,
    img: 'Woman gesturing no/Default/Color/woman_gesturing_no_color_default.svg',
  },
  {
    name: 'person gesturing OK',
    glyph: '🙆',
    id: 260,
    img: 'Person gesturing ok/Default/Color/person_gesturing_ok_color_default.svg',
  },
  {
    name: 'man gesturing OK',
    glyph: '🙆‍♂️',
    id: 261,
    img: 'Man gesturing ok/Default/Color/man_gesturing_ok_color_default.svg',
  },
  {
    name: 'woman gesturing OK',
    glyph: '🙆‍♀️',
    id: 262,
    img: 'Woman gesturing ok/Default/Color/woman_gesturing_ok_color_default.svg',
  },
  {
    name: 'person tipping hand',
    glyph: '💁',
    id: 263,
    img: 'Person tipping hand/Default/Color/person_tipping_hand_color_default.svg',
  },
  {
    name: 'man tipping hand',
    glyph: '💁‍♂️',
    id: 264,
    img: 'Man tipping hand/Default/Color/man_tipping_hand_color_default.svg',
  },
  {
    name: 'woman tipping hand',
    glyph: '💁‍♀️',
    id: 265,
    img: 'Woman tipping hand/Default/Color/woman_tipping_hand_color_default.svg',
  },
  {
    name: 'person raising hand',
    glyph: '🙋',
    id: 266,
    img: 'Person raising hand/Default/Color/person_raising_hand_color_default.svg',
  },
  {
    name: 'man raising hand',
    glyph: '🙋‍♂️',
    id: 267,
    img: 'Man raising hand/Default/Color/man_raising_hand_color_default.svg',
  },
  {
    name: 'woman raising hand',
    glyph: '🙋‍♀️',
    id: 268,
    img: 'Woman raising hand/Default/Color/woman_raising_hand_color_default.svg',
  },
  {
    name: 'deaf person',
    glyph: '🧏',
    id: 269,
    img: 'Person deaf/Default/Color/person_deaf_color_default.svg',
  },
  {
    name: 'deaf man',
    glyph: '🧏‍♂️',
    id: 270,
    img: 'Man deaf/Default/Color/man_deaf_color_default.svg',
  },
  {
    name: 'deaf woman',
    glyph: '🧏‍♀️',
    id: 271,
    img: 'Woman deaf/Default/Color/woman_deaf_color_default.svg',
  },
  {
    name: 'person bowing',
    glyph: '🙇',
    id: 272,
    img: 'Person bowing/Default/Color/person_bowing_color_default.svg',
  },
  {
    name: 'man bowing',
    glyph: '🙇‍♂️',
    id: 273,
    img: 'Man bowing/Default/Color/man_bowing_color_default.svg',
  },
  {
    name: 'woman bowing',
    glyph: '🙇‍♀️',
    id: 274,
    img: 'Woman bowing/Default/Color/woman_bowing_color_default.svg',
  },
  {
    name: 'person facepalming',
    glyph: '🤦',
    id: 275,
    img: 'Person facepalming/Default/Color/person_facepalming_color_default.svg',
  },
  {
    name: 'man facepalming',
    glyph: '🤦‍♂️',
    id: 276,
    img: 'Man facepalming/Default/Color/man_facepalming_color_default.svg',
  },
  {
    name: 'woman facepalming',
    glyph: '🤦‍♀️',
    id: 277,
    img: 'Woman facepalming/Default/Color/woman_facepalming_color_default.svg',
  },
  {
    name: 'person shrugging',
    glyph: '🤷',
    id: 278,
    img: 'Person shrugging/Default/Color/person_shrugging_color_default.svg',
  },
  {
    name: 'man shrugging',
    glyph: '🤷‍♂️',
    id: 279,
    img: 'Man shrugging/Default/Color/man_shrugging_color_default.svg',
  },
  {
    name: 'woman shrugging',
    glyph: '🤷‍♀️',
    id: 280,
    img: 'Woman shrugging/Default/Color/woman_shrugging_color_default.svg',
  },
  {
    name: 'health worker',
    glyph: '🧑‍⚕️',
    id: 281,
    img: 'Health worker/Default/Color/health_worker_color_default.svg',
  },
  {
    name: 'man health worker',
    glyph: '👨‍⚕️',
    id: 282,
    img: 'Man health worker/Default/Color/man_health_worker_color_default.svg',
  },
  {
    name: 'woman health worker',
    glyph: '👩‍⚕️',
    id: 283,
    img: 'Woman health worker/Default/Color/woman_health_worker_color_default.svg',
  },
  {
    name: 'student',
    glyph: '🧑‍🎓',
    id: 284,
    img: 'Student/Default/Color/student_color_default.svg',
  },
  {
    name: 'man student',
    glyph: '👨‍🎓',
    id: 285,
    img: 'Man student/Default/Color/man_student_color_default.svg',
  },
  {
    name: 'woman student',
    glyph: '👩‍🎓',
    id: 286,
    img: 'Woman student/Default/Color/woman_student_color_default.svg',
  },
  {
    name: 'teacher',
    glyph: '🧑‍🏫',
    id: 287,
    img: 'Teacher/Default/Color/teacher_color_default.svg',
  },
  {
    name: 'man teacher',
    glyph: '👨‍🏫',
    id: 288,
    img: 'Man teacher/Default/Color/man_teacher_color_default.svg',
  },
  {
    name: 'woman teacher',
    glyph: '👩‍🏫',
    id: 289,
    img: 'Woman teacher/Default/Color/woman_teacher_color_default.svg',
  },
  {
    name: 'judge',
    glyph: '🧑‍⚖️',
    id: 290,
    img: 'Judge/Default/Color/judge_color_default.svg',
  },
  {
    name: 'man judge',
    glyph: '👨‍⚖️',
    id: 291,
    img: 'Man judge/Default/Color/man_judge_color_default.svg',
  },
  {
    name: 'woman judge',
    glyph: '👩‍⚖️',
    id: 292,
    img: 'Woman judge/Default/Color/woman_judge_color_default.svg',
  },
  {
    name: 'farmer',
    glyph: '🧑‍🌾',
    id: 293,
    img: 'Farmer/Default/Color/farmer_color_default.svg',
  },
  {
    name: 'man farmer',
    glyph: '👨‍🌾',
    id: 294,
    img: 'Man farmer/Default/Color/man_farmer_color_default.svg',
  },
  {
    name: 'woman farmer',
    glyph: '👩‍🌾',
    id: 295,
    img: 'Woman farmer/Default/Color/woman_farmer_color_default.svg',
  },
  {
    name: 'cook',
    glyph: '🧑‍🍳',
    id: 296,
    img: 'Cook/Default/Color/cook_color_default.svg',
  },
  {
    name: 'man cook',
    glyph: '👨‍🍳',
    id: 297,
    img: 'Man cook/Default/Color/man_cook_color_default.svg',
  },
  {
    name: 'woman cook',
    glyph: '👩‍🍳',
    id: 298,
    img: 'Woman cook/Default/Color/woman_cook_color_default.svg',
  },
  {
    name: 'mechanic',
    glyph: '🧑‍🔧',
    id: 299,
    img: 'Mechanic/Default/Color/mechanic_color_default.svg',
  },
  {
    name: 'man mechanic',
    glyph: '👨‍🔧',
    id: 300,
    img: 'Man mechanic/Default/Color/man_mechanic_color_default.svg',
  },
  {
    name: 'woman mechanic',
    glyph: '👩‍🔧',
    id: 301,
    img: 'Woman mechanic/Default/Color/woman_mechanic_color_default.svg',
  },
  {
    name: 'factory worker',
    glyph: '🧑‍🏭',
    id: 302,
    img: 'Factory worker/Default/Color/factory_worker_color_default.svg',
  },
  {
    name: 'man factory worker',
    glyph: '👨‍🏭',
    id: 303,
    img: 'Man factory worker/Default/Color/man_factory_worker_color_default.svg',
  },
  {
    name: 'woman factory worker',
    glyph: '👩‍🏭',
    id: 304,
    img: 'Woman factory worker/Default/Color/woman_factory_worker_color_default.svg',
  },
  {
    name: 'office worker',
    glyph: '🧑‍💼',
    id: 305,
    img: 'Office worker/Default/Color/office_worker_color_default.svg',
  },
  {
    name: 'man office worker',
    glyph: '👨‍💼',
    id: 306,
    img: 'Man office worker/Default/Color/man_office_worker_color_default.svg',
  },
  {
    name: 'woman office worker',
    glyph: '👩‍💼',
    id: 307,
    img: 'Woman office worker/Default/Color/woman_office_worker_color_default.svg',
  },
  {
    name: 'scientist',
    glyph: '🧑‍🔬',
    id: 308,
    img: 'Scientist/Default/Color/scientist_color_default.svg',
  },
  {
    name: 'man scientist',
    glyph: '👨‍🔬',
    id: 309,
    img: 'Man scientist/Default/Color/man_scientist_color_default.svg',
  },
  {
    name: 'woman scientist',
    glyph: '👩‍🔬',
    id: 310,
    img: 'Woman scientist/Default/Color/woman_scientist_color_default.svg',
  },
  {
    name: 'singer',
    glyph: '🧑‍🎤',
    id: 314,
    img: 'Singer/Default/Color/singer_color_default.svg',
  },
  {
    name: 'man singer',
    glyph: '👨‍🎤',
    id: 315,
    img: 'Man singer/Default/Color/man_singer_color_default.svg',
  },
  {
    name: 'woman singer',
    glyph: '👩‍🎤',
    id: 316,
    img: 'Woman singer/Default/Color/woman_singer_color_default.svg',
  },
  {
    name: 'artist',
    glyph: '🧑‍🎨',
    id: 317,
    img: 'Artist/Default/Color/artist_color_default.svg',
  },
  {
    name: 'man artist',
    glyph: '👨‍🎨',
    id: 318,
    img: 'Man artist/Default/Color/man_artist_color_default.svg',
  },
  {
    name: 'woman artist',
    glyph: '👩‍🎨',
    id: 319,
    img: 'Woman artist/Default/Color/woman_artist_color_default.svg',
  },
  {
    name: 'pilot',
    glyph: '🧑‍✈️',
    id: 320,
    img: 'Pilot/Default/Color/pilot_color_default.svg',
  },
  {
    name: 'man pilot',
    glyph: '👨‍✈️',
    id: 321,
    img: 'Man pilot/Default/Color/man_pilot_color_default.svg',
  },
  {
    name: 'woman pilot',
    glyph: '👩‍✈️',
    id: 322,
    img: 'Woman pilot/Default/Color/woman_pilot_color_default.svg',
  },
  {
    name: 'astronaut',
    glyph: '🧑‍🚀',
    id: 323,
    img: 'Astronaut/Default/Color/astronaut_color_default.svg',
  },
  {
    name: 'man astronaut',
    glyph: '👨‍🚀',
    id: 324,
    img: 'Man astronaut/Default/Color/man_astronaut_color_default.svg',
  },
  {
    name: 'woman astronaut',
    glyph: '👩‍🚀',
    id: 325,
    img: 'Woman astronaut/Default/Color/woman_astronaut_color_default.svg',
  },
  {
    name: 'firefighter',
    glyph: '🧑‍🚒',
    id: 326,
    img: 'Firefighter/Default/Color/firefighter_color_default.svg',
  },
  {
    name: 'man firefighter',
    glyph: '👨‍🚒',
    id: 327,
    img: 'Man firefighter/Default/Color/man_firefighter_color_default.svg',
  },
  {
    name: 'woman firefighter',
    glyph: '👩‍🚒',
    id: 328,
    img: 'Woman firefighter/Default/Color/woman_firefighter_color_default.svg',
  },
  {
    name: 'police officer',
    glyph: '👮',
    id: 329,
    img: 'Police officer/Default/Color/police_officer_color_default.svg',
  },
  {
    name: 'man police officer',
    glyph: '👮‍♂️',
    id: 330,
    img: 'Man police officer/Default/Color/man_police_officer_color_default.svg',
  },
  {
    name: 'woman police officer',
    glyph: '👮‍♀️',
    id: 331,
    img: 'Woman police officer/Default/Color/woman_police_officer_color_default.svg',
  },
  {
    name: 'detective',
    glyph: '🕵️',
    id: 332,
    img: 'Detective/Default/Color/detective_color_default.svg',
  },
  {
    name: 'man detective',
    glyph: '🕵️‍♂️',
    id: 333,
    img: 'Man detective/Default/Color/man_detective_color_default.svg',
  },
  {
    name: 'woman detective',
    glyph: '🕵️‍♀️',
    id: 334,
    img: 'Woman detective/Default/Color/woman_detective_color_default.svg',
  },
  {
    name: 'guard',
    glyph: '💂',
    id: 335,
    img: 'Guard/Default/Color/guard_color_default.svg',
  },
  {
    name: 'man guard',
    glyph: '💂‍♂️',
    id: 336,
    img: 'Man guard/Default/Color/man_guard_color_default.svg',
  },
  {
    name: 'woman guard',
    glyph: '💂‍♀️',
    id: 337,
    img: 'Woman guard/Default/Color/woman_guard_color_default.svg',
  },
  {
    name: 'ninja',
    glyph: '🥷',
    id: 338,
    img: 'Ninja/Default/Color/ninja_color_default.svg',
  },
  {
    name: 'construction worker',
    glyph: '👷',
    id: 339,
    img: 'Construction worker/Default/Color/construction_worker_color_default.svg',
  },
  {
    name: 'man construction worker',
    glyph: '👷‍♂️',
    id: 340,
    img: 'Man construction worker/Default/Color/man_construction_worker_color_default.svg',
  },
  {
    name: 'woman construction worker',
    glyph: '👷‍♀️',
    id: 341,
    img: 'Woman construction worker/Default/Color/woman_construction_worker_color_default.svg',
  },
  {
    name: 'person with crown',
    glyph: '🫅',
    id: 342,
    img: 'Person with crown/Default/Color/person_with_crown_color_default.svg',
  },
  {
    name: 'prince',
    glyph: '🤴',
    id: 343,
    img: 'Prince/Default/Color/prince_color_default.svg',
  },
  {
    name: 'princess',
    glyph: '👸',
    id: 344,
    img: 'Princess/Default/Color/princess_color_default.svg',
  },
  {
    name: 'person wearing turban',
    glyph: '👳',
    id: 345,
    img: 'Person wearing turban/Default/Color/person_wearing_turban_color_default.svg',
  },
  {
    name: 'man wearing turban',
    glyph: '👳‍♂️',
    id: 346,
    img: 'Man wearing turban/Default/Color/man_wearing_turban_color_default.svg',
  },
  {
    name: 'woman wearing turban',
    glyph: '👳‍♀️',
    id: 347,
    img: 'Woman wearing turban/Default/Color/woman_wearing_turban_color_default.svg',
  },
  {
    name: 'person with skullcap',
    glyph: '👲',
    id: 348,
    img: 'Person with skullcap/Default/Color/person_with_skullcap_color_default.svg',
  },
  {
    name: 'woman with headscarf',
    glyph: '🧕',
    id: 349,
    img: 'Woman with headscarf/Default/Color/woman_with_headscarf_color_default.svg',
  },
  {
    name: 'person in tuxedo',
    glyph: '🤵',
    id: 350,
    img: 'Person in tuxedo/Default/Color/person_in_tuxedo_color_default.svg',
  },
  {
    name: 'man in tuxedo',
    glyph: '🤵‍♂️',
    id: 351,
    img: 'Man in tuxedo/Default/Color/man_in_tuxedo_color_default.svg',
  },
  {
    name: 'woman in tuxedo',
    glyph: '🤵‍♀️',
    id: 352,
    img: 'Woman in tuxedo/Default/Color/woman_in_tuxedo_color_default.svg',
  },
  {
    name: 'person with veil',
    glyph: '👰',
    id: 353,
    img: 'Person with veil/Default/Color/person_with_veil_color_default.svg',
  },
  {
    name: 'man with veil',
    glyph: '👰‍♂️',
    id: 354,
    img: 'Man with veil/Default/Color/man_with_veil_color_default.svg',
  },
  {
    name: 'woman with veil',
    glyph: '👰‍♀️',
    id: 355,
    img: 'Woman with veil/Default/Color/woman_with_veil_color_default.svg',
  },
  {
    name: 'pregnant woman',
    glyph: '🤰',
    id: 356,
    img: 'Pregnant woman/Default/Color/pregnant_woman_color_default.svg',
  },
  {
    name: 'pregnant man',
    glyph: '🫃',
    id: 357,
    img: 'Pregnant man/Default/Color/pregnant_man_color_default.svg',
  },
  {
    name: 'pregnant person',
    glyph: '🫄',
    id: 358,
    img: 'Pregnant person/Default/Color/pregnant_person_color_default.svg',
  },
  {
    name: 'breast-feeding',
    glyph: '🤱',
    id: 359,
    img: 'Breast feeding/Default/Color/breast_feeding_color_default.svg',
  },
  {
    name: 'woman feeding baby',
    glyph: '👩‍🍼',
    id: 360,
    img: 'Woman feeding baby/Default/Color/woman_feeding_baby_color_default.svg',
  },
  {
    name: 'man feeding baby',
    glyph: '👨‍🍼',
    id: 361,
    img: 'Man feeding baby/Default/Color/man_feeding_baby_color_default.svg',
  },
  {
    name: 'person feeding baby',
    glyph: '🧑‍🍼',
    id: 362,
    img: 'Person feeding baby/Default/Color/person_feeding_baby_color_default.svg',
  },
  {
    name: 'baby angel',
    glyph: '👼',
    id: 363,
    img: 'Baby angel/Default/Color/baby_angel_color_default.svg',
  },
  {
    name: 'Santa Claus',
    glyph: '🎅',
    id: 364,
    img: 'Santa claus/Default/Color/santa_claus_color_default.svg',
  },
  {
    name: 'Mrs. Claus',
    glyph: '🤶',
    id: 365,
    img: 'Mrs claus/Default/Color/mrs_claus_color_default.svg',
  },
  {
    name: 'mx claus',
    glyph: '🧑‍🎄',
    id: 366,
    img: 'Mx claus/Default/Color/mx_claus_color_default.svg',
  },
  {
    name: 'superhero',
    glyph: '🦸',
    id: 367,
    img: 'Person superhero/Default/Color/person_superhero_color_default.svg',
  },
  {
    name: 'man superhero',
    glyph: '🦸‍♂️',
    id: 368,
    img: 'Man superhero/Default/Color/man_superhero_color_default.svg',
  },
  {
    name: 'woman superhero',
    glyph: '🦸‍♀️',
    id: 369,
    img: 'Woman superhero/Default/Color/woman_superhero_color_default.svg',
  },
  {
    name: 'supervillain',
    glyph: '🦹',
    id: 370,
    img: 'Person supervillain/Default/Color/person_supervillain_color_default.svg',
  },
  {
    name: 'man supervillain',
    glyph: '🦹‍♂️',
    id: 371,
    img: 'Man supervillain/Default/Color/man_supervillain_color_default.svg',
  },
  {
    name: 'woman supervillain',
    glyph: '🦹‍♀️',
    id: 372,
    img: 'Woman supervillain/Default/Color/woman_supervillain_color_default.svg',
  },
  {
    name: 'mage',
    glyph: '🧙',
    id: 373,
    img: 'Person mage/Default/Color/person_mage_color_default.svg',
  },
  {
    name: 'man mage',
    glyph: '🧙‍♂️',
    id: 374,
    img: 'Man mage/Default/Color/man_mage_color_default.svg',
  },
  {
    name: 'woman mage',
    glyph: '🧙‍♀️',
    id: 375,
    img: 'Woman mage/Default/Color/woman_mage_color_default.svg',
  },
  {
    name: 'fairy',
    glyph: '🧚',
    id: 376,
    img: 'Person fairy/Default/Color/person_fairy_color_default.svg',
  },
  {
    name: 'man fairy',
    glyph: '🧚‍♂️',
    id: 377,
    img: 'Man fairy/Default/Color/man_fairy_color_default.svg',
  },
  {
    name: 'woman fairy',
    glyph: '🧚‍♀️',
    id: 378,
    img: 'Woman fairy/Default/Color/woman_fairy_color_default.svg',
  },
  {
    name: 'vampire',
    glyph: '🧛',
    id: 379,
    img: 'Person vampire/Default/Color/person_vampire_color_default.svg',
  },
  {
    name: 'man vampire',
    glyph: '🧛‍♂️',
    id: 380,
    img: 'Man vampire/Default/Color/man_vampire_color_default.svg',
  },
  {
    name: 'woman vampire',
    glyph: '🧛‍♀️',
    id: 381,
    img: 'Woman vampire/Default/Color/woman_vampire_color_default.svg',
  },
  {
    name: 'merperson',
    glyph: '🧜',
    id: 382,
    img: 'Person merpeople/Default/Color/person_merpeople_color_default.svg',
  },
  {
    name: 'merman',
    glyph: '🧜‍♂️',
    id: 383,
    img: 'Man merpeople/Default/Color/man_merpeople_color_default.svg',
  },
  {
    name: 'mermaid',
    glyph: '🧜‍♀️',
    id: 384,
    img: 'Woman merpeople/Default/Color/woman_merpeople_color_default.svg',
  },
  {
    name: 'elf',
    glyph: '🧝',
    id: 385,
    img: 'Person elf/Default/Color/person_elf_color_default.svg',
  },
  {
    name: 'man elf',
    glyph: '🧝‍♂️',
    id: 386,
    img: 'Man elf/Default/Color/man_elf_color_default.svg',
  },
  {
    name: 'woman elf',
    glyph: '🧝‍♀️',
    id: 387,
    img: 'Woman elf/Default/Color/woman_elf_color_default.svg',
  },
  {
    name: 'genie',
    glyph: '🧞',
    id: 388,
    img: 'Person genie/Color/person_genie_color.svg',
  },
  {
    name: 'man genie',
    glyph: '🧞‍♂️',
    id: 389,
    img: 'Man genie/Color/man_genie_color.svg',
  },
  {
    name: 'woman genie',
    glyph: '🧞‍♀️',
    id: 390,
    img: 'Woman genie/Color/woman_genie_color.svg',
  },
  {
    name: 'zombie',
    glyph: '🧟',
    id: 391,
    img: 'Person zombie/Color/person_zombie_color.svg',
  },
  {
    name: 'man zombie',
    glyph: '🧟‍♂️',
    id: 392,
    img: 'Man zombie/Color/man_zombie_color.svg',
  },
  {
    name: 'woman zombie',
    glyph: '🧟‍♀️',
    id: 393,
    img: 'Woman zombie/Color/woman_zombie_color.svg',
  },
  {
    name: 'troll',
    glyph: '🧌',
    id: 394,
    img: 'Troll/Color/troll_color.svg',
  },
  {
    name: 'person getting massage',
    glyph: '💆',
    id: 395,
    img: 'Person getting massage/Default/Color/person_getting_massage_color_default.svg',
  },
  {
    name: 'man getting massage',
    glyph: '💆‍♂️',
    id: 396,
    img: 'Man getting massage/Default/Color/man_getting_massage_color_default.svg',
  },
  {
    name: 'woman getting massage',
    glyph: '💆‍♀️',
    id: 397,
    img: 'Woman getting massage/Default/Color/woman_getting_massage_color_default.svg',
  },
  {
    name: 'person getting haircut',
    glyph: '💇',
    id: 398,
    img: 'Person getting haircut/Default/Color/person_getting_haircut_color_default.svg',
  },
  {
    name: 'man getting haircut',
    glyph: '💇‍♂️',
    id: 399,
    img: 'Man getting haircut/Default/Color/man_getting_haircut_color_default.svg',
  },
  {
    name: 'woman getting haircut',
    glyph: '💇‍♀️',
    id: 400,
    img: 'Woman getting haircut/Default/Color/woman_getting_haircut_color_default.svg',
  },
  {
    name: 'person walking',
    glyph: '🚶',
    id: 401,
    img: 'Person walking/Default/Color/person_walking_color_default.svg',
  },
  {
    name: 'man walking',
    glyph: '🚶‍♂️',
    id: 402,
    img: 'Man walking/Default/Color/man_walking_color_default.svg',
  },
  {
    name: 'woman walking',
    glyph: '🚶‍♀️',
    id: 403,
    img: 'Woman walking/Default/Color/woman_walking_color_default.svg',
  },
  {
    name: 'person standing',
    glyph: '🧍',
    id: 404,
    img: 'Person standing/Default/Color/person_standing_color_default.svg',
  },
  {
    name: 'man standing',
    glyph: '🧍‍♂️',
    id: 405,
    img: 'Man standing/Default/Color/man_standing_color_default.svg',
  },
  {
    name: 'woman standing',
    glyph: '🧍‍♀️',
    id: 406,
    img: 'Woman standing/Default/Color/woman_standing_color_default.svg',
  },
  {
    name: 'person kneeling',
    glyph: '🧎',
    id: 407,
    img: 'Person kneeling/Default/Color/person_kneeling_color_default.svg',
  },
  {
    name: 'man kneeling',
    glyph: '🧎‍♂️',
    id: 408,
    img: 'Man kneeling/Default/Color/man_kneeling_color_default.svg',
  },
  {
    name: 'woman kneeling',
    glyph: '🧎‍♀️',
    id: 409,
    img: 'Woman kneeling/Default/Color/woman_kneeling_color_default.svg',
  },
  {
    name: 'person with white cane',
    glyph: '🧑‍🦯',
    id: 410,
    img: 'Person with white cane/Default/Color/person_with_white_cane_color_default.svg',
  },
  {
    name: 'man with white cane',
    glyph: '👨‍🦯',
    id: 411,
    img: 'Man with white cane/Default/Color/man_with_white_cane_color_default.svg',
  },
  {
    name: 'woman with white cane',
    glyph: '👩‍🦯',
    id: 412,
    img: 'Woman with white cane/Default/Color/woman_with_white_cane_color_default.svg',
  },
  {
    name: 'person in motorized wheelchair',
    glyph: '🧑‍🦼',
    id: 413,
    img: 'Person in motorized wheelchair/Default/Color/person_in_motorized_wheelchair_color_default.svg',
  },
  {
    name: 'man in motorized wheelchair',
    glyph: '👨‍🦼',
    id: 414,
    img: 'Man in motorized wheelchair/Default/Color/man_in_motorized_wheelchair_color_default.svg',
  },
  {
    name: 'woman in motorized wheelchair',
    glyph: '👩‍🦼',
    id: 415,
    img: 'Woman in motorized wheelchair/Default/Color/woman_in_motorized_wheelchair_color_default.svg',
  },
  {
    name: 'person in manual wheelchair',
    glyph: '🧑‍🦽',
    id: 416,
    img: 'Person in manual wheelchair/Default/Color/person_in_manual_wheelchair_color_default.svg',
  },
  {
    name: 'man in manual wheelchair',
    glyph: '👨‍🦽',
    id: 417,
    img: 'Man in manual wheelchair/Default/Color/man_in_manual_wheelchair_color_default.svg',
  },
  {
    name: 'woman in manual wheelchair',
    glyph: '👩‍🦽',
    id: 418,
    img: 'Woman in manual wheelchair/Default/Color/woman_in_manual_wheelchair_color_default.svg',
  },
  {
    name: 'person running',
    glyph: '🏃',
    id: 419,
    img: 'Person running/Default/Color/person_running_color_default.svg',
  },
  {
    name: 'man running',
    glyph: '🏃‍♂️',
    id: 420,
    img: 'Man running/Default/Color/man_running_color_default.svg',
  },
  {
    name: 'woman running',
    glyph: '🏃‍♀️',
    id: 421,
    img: 'Woman running/Default/Color/woman_running_color_default.svg',
  },
  {
    name: 'woman dancing',
    glyph: '💃',
    id: 422,
    img: 'Woman dancing/Default/Color/woman_dancing_color_default.svg',
  },
  {
    name: 'man dancing',
    glyph: '🕺',
    id: 423,
    img: 'Man dancing/Default/Color/man_dancing_color_default.svg',
  },
  {
    name: 'person in suit levitating',
    glyph: '🕴️',
    id: 424,
    img: 'Person in suit levitating/Default/Color/person_in_suit_levitating_color_default.svg',
  },
  {
    name: 'people with bunny ears',
    glyph: '👯',
    id: 425,
    img: 'Person with bunny ears/Color/person_with_bunny_ears_color.svg',
  },
  {
    name: 'men with bunny ears',
    glyph: '👯‍♂️',
    id: 426,
    img: 'Man with bunny ears/Color/man_with_bunny_ears_color.svg',
  },
  {
    name: 'women with bunny ears',
    glyph: '👯‍♀️',
    id: 427,
    img: 'Woman with bunny ears/Color/woman_with_bunny_ears_color.svg',
  },
  {
    name: 'person in steamy room',
    glyph: '🧖',
    id: 428,
    img: 'Person in steamy room/Default/Color/person_in_steamy_room_color_default.svg',
  },
  {
    name: 'man in steamy room',
    glyph: '🧖‍♂️',
    id: 429,
    img: 'Man in steamy room/Default/Color/man_in_steamy_room_color_default.svg',
  },
  {
    name: 'woman in steamy room',
    glyph: '🧖‍♀️',
    id: 430,
    img: 'Woman in steamy room/Default/Color/woman_in_steamy_room_color_default.svg',
  },
  {
    name: 'person climbing',
    glyph: '🧗',
    id: 431,
    img: 'Person climbing/Default/Color/person_climbing_color_default.svg',
  },
  {
    name: 'man climbing',
    glyph: '🧗‍♂️',
    id: 432,
    img: 'Man climbing/Default/Color/man_climbing_color_default.svg',
  },
  {
    name: 'woman climbing',
    glyph: '🧗‍♀️',
    id: 433,
    img: 'Woman climbing/Default/Color/woman_climbing_color_default.svg',
  },
  {
    name: 'person fencing',
    glyph: '🤺',
    id: 434,
    img: 'Person fencing/Color/person_fencing_color.svg',
  },
  {
    name: 'horse racing',
    glyph: '🏇',
    id: 435,
    img: 'Horse racing/Default/Color/horse_racing_color_default.svg',
  },
  {
    name: 'skier',
    glyph: '⛷️',
    id: 436,
    img: 'Skier/Color/skier_color.svg',
  },
  {
    name: 'snowboarder',
    glyph: '🏂',
    id: 437,
    img: 'Snowboarder/Default/Color/snowboarder_color_default.svg',
  },
  {
    name: 'person golfing',
    glyph: '🏌️',
    id: 438,
    img: 'Person golfing/Default/Color/person_golfing_color_default.svg',
  },
  {
    name: 'man golfing',
    glyph: '🏌️‍♂️',
    id: 439,
    img: 'Man golfing/Default/Color/man_golfing_color_default.svg',
  },
  {
    name: 'woman golfing',
    glyph: '🏌️‍♀️',
    id: 440,
    img: 'Woman golfing/Default/Color/woman_golfing_color_default.svg',
  },
  {
    name: 'person surfing',
    glyph: '🏄',
    id: 441,
    img: 'Person surfing/Default/Color/person_surfing_color_default.svg',
  },
  {
    name: 'man surfing',
    glyph: '🏄‍♂️',
    id: 442,
    img: 'Man surfing/Default/Color/man_surfing_color_default.svg',
  },
  {
    name: 'woman surfing',
    glyph: '🏄‍♀️',
    id: 443,
    img: 'Woman surfing/Default/Color/woman_surfing_color_default.svg',
  },
  {
    name: 'person rowing boat',
    glyph: '🚣',
    id: 444,
    img: 'Person rowing boat/Default/Color/person_rowing_boat_color_default.svg',
  },
  {
    name: 'man rowing boat',
    glyph: '🚣‍♂️',
    id: 445,
    img: 'Man rowing boat/Default/Color/man_rowing_boat_color_default.svg',
  },
  {
    name: 'woman rowing boat',
    glyph: '🚣‍♀️',
    id: 446,
    img: 'Woman rowing boat/Default/Color/woman_rowing_boat_color_default.svg',
  },
  {
    name: 'person swimming',
    glyph: '🏊',
    id: 447,
    img: 'Person swimming/Default/Color/person_swimming_color_default.svg',
  },
  {
    name: 'man swimming',
    glyph: '🏊‍♂️',
    id: 448,
    img: 'Man swimming/Default/Color/man_swimming_color_default.svg',
  },
  {
    name: 'woman swimming',
    glyph: '🏊‍♀️',
    id: 449,
    img: 'Woman swimming/Default/Color/woman_swimming_color_default.svg',
  },
  {
    name: 'person bouncing ball',
    glyph: '⛹️',
    id: 450,
    img: 'Person bouncing ball/Default/Color/person_bouncing_ball_color_default.svg',
  },
  {
    name: 'man bouncing ball',
    glyph: '⛹️‍♂️',
    id: 451,
    img: 'Man bouncing ball/Default/Color/man_bouncing_ball_color_default.svg',
  },
  {
    name: 'woman bouncing ball',
    glyph: '⛹️‍♀️',
    id: 452,
    img: 'Woman bouncing ball/Default/Color/woman_bouncing_ball_color_default.svg',
  },
  {
    name: 'person lifting weights',
    glyph: '🏋️',
    id: 453,
    img: 'Person lifting weights/Default/Color/person_lifting_weights_color_default.svg',
  },
  {
    name: 'man lifting weights',
    glyph: '🏋️‍♂️',
    id: 454,
    img: 'Man lifting weights/Default/Color/man_lifting_weights_color_default.svg',
  },
  {
    name: 'woman lifting weights',
    glyph: '🏋️‍♀️',
    id: 455,
    img: 'Woman lifting weights/Default/Color/woman_lifting_weights_color_default.svg',
  },
  {
    name: 'person biking',
    glyph: '🚴',
    id: 456,
    img: 'Person biking/Default/Color/person_biking_color_default.svg',
  },
  {
    name: 'man biking',
    glyph: '🚴‍♂️',
    id: 457,
    img: 'Man biking/Default/Color/man_biking_color_default.svg',
  },
  {
    name: 'woman biking',
    glyph: '🚴‍♀️',
    id: 458,
    img: 'Woman biking/Default/Color/woman_biking_color_default.svg',
  },
  {
    name: 'person mountain biking',
    glyph: '🚵',
    id: 459,
    img: 'Person mountain biking/Default/Color/person_mountain_biking_color_default.svg',
  },
  {
    name: 'man mountain biking',
    glyph: '🚵‍♂️',
    id: 460,
    img: 'Man mountain biking/Default/Color/man_mountain_biking_color_default.svg',
  },
  {
    name: 'woman mountain biking',
    glyph: '🚵‍♀️',
    id: 461,
    img: 'Woman mountain biking/Default/Color/woman_mountain_biking_color_default.svg',
  },
  {
    name: 'person cartwheeling',
    glyph: '🤸',
    id: 462,
    img: 'Person cartwheeling/Default/Color/person_cartwheeling_color_default.svg',
  },
  {
    name: 'man cartwheeling',
    glyph: '🤸‍♂️',
    id: 463,
    img: 'Man cartwheeling/Default/Color/man_cartwheeling_color_default.svg',
  },
  {
    name: 'woman cartwheeling',
    glyph: '🤸‍♀️',
    id: 464,
    img: 'Woman cartwheeling/Default/Color/woman_cartwheeling_color_default.svg',
  },
  {
    name: 'people wrestling',
    glyph: '🤼',
    id: 465,
    img: 'Person wrestling/Color/person_wrestling_color.svg',
  },
  {
    name: 'men wrestling',
    glyph: '🤼‍♂️',
    id: 466,
    img: 'Man wrestling/Color/man_wrestling_color.svg',
  },
  {
    name: 'women wrestling',
    glyph: '🤼‍♀️',
    id: 467,
    img: 'Woman wrestling/Color/woman_wrestling_color.svg',
  },
  {
    name: 'person playing water polo',
    glyph: '🤽',
    id: 468,
    img: 'Person playing water polo/Default/Color/person_playing_water_polo_color_default.svg',
  },
  {
    name: 'man playing water polo',
    glyph: '🤽‍♂️',
    id: 469,
    img: 'Man playing water polo/Default/Color/man_playing_water_polo_color_default.svg',
  },
  {
    name: 'woman playing water polo',
    glyph: '🤽‍♀️',
    id: 470,
    img: 'Woman playing water polo/Default/Color/woman_playing_water_polo_color_default.svg',
  },
  {
    name: 'person playing handball',
    glyph: '🤾',
    id: 471,
    img: 'Person playing handball/Default/Color/person_playing_handball_color_default.svg',
  },
  {
    name: 'man playing handball',
    glyph: '🤾‍♂️',
    id: 472,
    img: 'Man playing handball/Default/Color/man_playing_handball_color_default.svg',
  },
  {
    name: 'woman playing handball',
    glyph: '🤾‍♀️',
    id: 473,
    img: 'Woman playing handball/Default/Color/woman_playing_handball_color_default.svg',
  },
  {
    name: 'person juggling',
    glyph: '🤹',
    id: 474,
    img: 'Person juggling/Default/Color/person_juggling_color_default.svg',
  },
  {
    name: 'man juggling',
    glyph: '🤹‍♂️',
    id: 475,
    img: 'Man juggling/Default/Color/man_juggling_color_default.svg',
  },
  {
    name: 'woman juggling',
    glyph: '🤹‍♀️',
    id: 476,
    img: 'Woman juggling/Default/Color/woman_juggling_color_default.svg',
  },
  {
    name: 'person in lotus position',
    glyph: '🧘',
    id: 477,
    img: 'Person in lotus position/Default/Color/person_in_lotus_position_color_default.svg',
  },
  {
    name: 'man in lotus position',
    glyph: '🧘‍♂️',
    id: 478,
    img: 'Man in lotus position/Default/Color/man_in_lotus_position_color_default.svg',
  },
  {
    name: 'woman in lotus position',
    glyph: '🧘‍♀️',
    id: 479,
    img: 'Woman in lotus position/Default/Color/woman_in_lotus_position_color_default.svg',
  },
  {
    name: 'person taking bath',
    glyph: '🛀',
    id: 480,
    img: 'Person taking bath/Default/Color/person_taking_bath_color_default.svg',
  },
  {
    name: 'person in bed',
    glyph: '🛌',
    id: 481,
    img: 'Person in bed/Default/Color/person_in_bed_color_default.svg',
  },
  {
    name: 'speaking head',
    glyph: '🗣️',
    id: 520,
    img: 'Speaking head/Color/speaking_head_color.svg',
  },
  {
    name: 'bust in silhouette',
    glyph: '👤',
    id: 521,
    img: 'Bust in silhouette/Color/bust_in_silhouette_color.svg',
  },
  {
    name: 'busts in silhouette',
    glyph: '👥',
    id: 522,
    img: 'Busts in silhouette/Color/busts_in_silhouette_color.svg',
  },
  {
    name: 'people hugging',
    glyph: '🫂',
    id: 523,
    img: 'People hugging/Color/people_hugging_color.svg',
  },
  {
    name: 'footprints',
    glyph: '👣',
    id: 524,
    img: 'Footprints/Color/footprints_color.svg',
  },
  {
    name: 'monkey face',
    glyph: '🐵',
    id: 530,
    img: 'Monkey face/Color/monkey_face_color.svg',
  },
  {
    name: 'monkey',
    glyph: '🐒',
    id: 531,
    img: 'Monkey/Color/monkey_color.svg',
  },
  {
    name: 'gorilla',
    glyph: '🦍',
    id: 532,
    img: 'Gorilla/Color/gorilla_color.svg',
  },
  {
    name: 'orangutan',
    glyph: '🦧',
    id: 533,
    img: 'Orangutan/Color/orangutan_color.svg',
  },
  {
    name: 'dog face',
    glyph: '🐶',
    id: 534,
    img: 'Dog face/Color/dog_face_color.svg',
  },
  {
    name: 'dog',
    glyph: '🐕',
    id: 535,
    img: 'Dog/Color/dog_color.svg',
  },
  {
    name: 'guide dog',
    glyph: '🦮',
    id: 536,
    img: 'Guide dog/Color/guide_dog_color.svg',
  },
  {
    name: 'service dog',
    glyph: '🐕‍🦺',
    id: 537,
    img: 'Service dog/Color/service_dog_color.svg',
  },
  {
    name: 'poodle',
    glyph: '🐩',
    id: 538,
    img: 'Poodle/Color/poodle_color.svg',
  },
  {
    name: 'wolf',
    glyph: '🐺',
    id: 539,
    img: 'Wolf/Color/wolf_color.svg',
  },
  {
    name: 'fox',
    glyph: '🦊',
    id: 540,
    img: 'Fox/Color/fox_color.svg',
  },
  {
    name: 'raccoon',
    glyph: '🦝',
    id: 541,
    img: 'Raccoon/Color/raccoon_color.svg',
  },
  {
    name: 'cat face',
    glyph: '🐱',
    id: 542,
    img: 'Cat face/Color/cat_face_color.svg',
  },
  {
    name: 'cat',
    glyph: '🐈',
    id: 543,
    img: 'Cat/Color/cat_color.svg',
  },
  {
    name: 'black cat',
    glyph: '🐈‍⬛',
    id: 544,
    img: 'Black cat/Color/black_cat_color.svg',
  },
  {
    name: 'lion',
    glyph: '🦁',
    id: 545,
    img: 'Lion/Color/lion_color.svg',
  },
  {
    name: 'tiger face',
    glyph: '🐯',
    id: 546,
    img: 'Tiger face/Color/tiger_face_color.svg',
  },
  {
    name: 'tiger',
    glyph: '🐅',
    id: 547,
    img: 'Tiger/Color/tiger_color.svg',
  },
  {
    name: 'leopard',
    glyph: '🐆',
    id: 548,
    img: 'Leopard/Color/leopard_color.svg',
  },
  {
    name: 'horse face',
    glyph: '🐴',
    id: 549,
    img: 'Horse face/Color/horse_face_color.svg',
  },
  {
    name: 'horse',
    glyph: '🐎',
    id: 550,
    img: 'Horse/Color/horse_color.svg',
  },
  {
    name: 'unicorn',
    glyph: '🦄',
    id: 551,
    img: 'Unicorn/Color/unicorn_color.svg',
  },
  {
    name: 'zebra',
    glyph: '🦓',
    id: 552,
    img: 'Zebra/Color/zebra_color.svg',
  },
  {
    name: 'deer',
    glyph: '🦌',
    id: 553,
    img: 'Deer/Color/deer_color.svg',
  },
  {
    name: 'bison',
    glyph: '🦬',
    id: 554,
    img: 'Bison/Color/bison_color.svg',
  },
  {
    name: 'cow face',
    glyph: '🐮',
    id: 555,
    img: 'Cow face/Color/cow_face_color.svg',
  },
  {
    name: 'ox',
    glyph: '🐂',
    id: 556,
    img: 'Ox/Color/ox_color.svg',
  },
  {
    name: 'water buffalo',
    glyph: '🐃',
    id: 557,
    img: 'Water buffalo/Color/water_buffalo_color.svg',
  },
  {
    name: 'cow',
    glyph: '🐄',
    id: 558,
    img: 'Cow/Color/cow_color.svg',
  },
  {
    name: 'pig face',
    glyph: '🐷',
    id: 559,
    img: 'Pig face/Color/pig_face_color.svg',
  },
  {
    name: 'pig',
    glyph: '🐖',
    id: 560,
    img: 'Pig/Color/pig_color.svg',
  },
  {
    name: 'boar',
    glyph: '🐗',
    id: 561,
    img: 'Boar/Color/boar_color.svg',
  },
  {
    name: 'pig nose',
    glyph: '🐽',
    id: 562,
    img: 'Pig nose/Color/pig_nose_color.svg',
  },
  {
    name: 'ram',
    glyph: '🐏',
    id: 563,
    img: 'Ram/Color/ram_color.svg',
  },
  {
    name: 'ewe',
    glyph: '🐑',
    id: 564,
    img: 'Ewe/Color/ewe_color.svg',
  },
  {
    name: 'goat',
    glyph: '🐐',
    id: 565,
    img: 'Goat/Color/goat_color.svg',
  },
  {
    name: 'camel',
    glyph: '🐪',
    id: 566,
    img: 'Camel/Color/camel_color.svg',
  },
  {
    name: 'two-hump camel',
    glyph: '🐫',
    id: 567,
    img: 'Two-hump camel/Color/two-hump_camel_color.svg',
  },
  {
    name: 'llama',
    glyph: '🦙',
    id: 568,
    img: 'Llama/Color/llama_color.svg',
  },
  {
    name: 'giraffe',
    glyph: '🦒',
    id: 569,
    img: 'Giraffe/Color/giraffe_color.svg',
  },
  {
    name: 'elephant',
    glyph: '🐘',
    id: 570,
    img: 'Elephant/Color/elephant_color.svg',
  },
  {
    name: 'mammoth',
    glyph: '🦣',
    id: 571,
    img: 'Mammoth/Color/mammoth_color.svg',
  },
  {
    name: 'rhinoceros',
    glyph: '🦏',
    id: 572,
    img: 'Rhinoceros/Color/rhinoceros_color.svg',
  },
  {
    name: 'hippopotamus',
    glyph: '🦛',
    id: 573,
    img: 'Hippopotamus/Color/hippopotamus_color.svg',
  },
  {
    name: 'mouse face',
    glyph: '🐭',
    id: 574,
    img: 'Mouse face/Color/mouse_face_color.svg',
  },
  {
    name: 'mouse',
    glyph: '🐁',
    id: 575,
    img: 'Mouse/Color/mouse_color.svg',
  },
  {
    name: 'rat',
    glyph: '🐀',
    id: 576,
    img: 'Rat/Color/rat_color.svg',
  },
  {
    name: 'hamster',
    glyph: '🐹',
    id: 577,
    img: 'Hamster/Color/hamster_color.svg',
  },
  {
    name: 'rabbit face',
    glyph: '🐰',
    id: 578,
    img: 'Rabbit face/Color/rabbit_face_color.svg',
  },
  {
    name: 'rabbit',
    glyph: '🐇',
    id: 579,
    img: 'Rabbit/Color/rabbit_color.svg',
  },
  {
    name: 'chipmunk',
    glyph: '🐿️',
    id: 580,
    img: 'Chipmunk/Color/chipmunk_color.svg',
  },
  {
    name: 'beaver',
    glyph: '🦫',
    id: 581,
    img: 'Beaver/Color/beaver_color.svg',
  },
  {
    name: 'hedgehog',
    glyph: '🦔',
    id: 582,
    img: 'Hedgehog/Color/hedgehog_color.svg',
  },
  {
    name: 'bat',
    glyph: '🦇',
    id: 583,
    img: 'Bat/Color/bat_color.svg',
  },
  {
    name: 'bear',
    glyph: '🐻',
    id: 584,
    img: 'Bear/Color/bear_color.svg',
  },
  {
    name: 'polar bear',
    glyph: '🐻‍❄️',
    id: 585,
    img: 'Polar bear/Color/polar_bear_color.svg',
  },
  {
    name: 'koala',
    glyph: '🐨',
    id: 586,
    img: 'Koala/Color/koala_color.svg',
  },
  {
    name: 'panda',
    glyph: '🐼',
    id: 587,
    img: 'Panda/Color/panda_color.svg',
  },
  {
    name: 'sloth',
    glyph: '🦥',
    id: 588,
    img: 'Sloth/Color/sloth_color.svg',
  },
  {
    name: 'otter',
    glyph: '🦦',
    id: 589,
    img: 'Otter/Color/otter_color.svg',
  },
  {
    name: 'skunk',
    glyph: '🦨',
    id: 590,
    img: 'Skunk/Color/skunk_color.svg',
  },
  {
    name: 'kangaroo',
    glyph: '🦘',
    id: 591,
    img: 'Kangaroo/Color/kangaroo_color.svg',
  },
  {
    name: 'badger',
    glyph: '🦡',
    id: 592,
    img: 'Badger/Color/badger_color.svg',
  },
  {
    name: 'paw prints',
    glyph: '🐾',
    id: 593,
    img: 'Paw prints/Color/paw_prints_color.svg',
  },
  {
    name: 'turkey',
    glyph: '🦃',
    id: 594,
    img: 'Turkey/Color/turkey_color.svg',
  },
  {
    name: 'chicken',
    glyph: '🐔',
    id: 595,
    img: 'Chicken/Color/chicken_color.svg',
  },
  {
    name: 'rooster',
    glyph: '🐓',
    id: 596,
    img: 'Rooster/Color/rooster_color.svg',
  },
  {
    name: 'hatching chick',
    glyph: '🐣',
    id: 597,
    img: 'Hatching chick/Color/hatching_chick_color.svg',
  },
  {
    name: 'baby chick',
    glyph: '🐤',
    id: 598,
    img: 'Baby chick/Color/baby_chick_color.svg',
  },
  {
    name: 'front-facing baby chick',
    glyph: '🐥',
    id: 599,
    img: 'Front-facing baby chick/Color/front-facing_baby_chick_color.svg',
  },
  {
    name: 'bird',
    glyph: '🐦',
    id: 600,
    img: 'Bird/Color/bird_color.svg',
  },
  {
    name: 'penguin',
    glyph: '🐧',
    id: 601,
    img: 'Penguin/Color/penguin_color.svg',
  },
  {
    name: 'dove',
    glyph: '🕊️',
    id: 602,
    img: 'Dove/Color/dove_color.svg',
  },
  {
    name: 'eagle',
    glyph: '🦅',
    id: 603,
    img: 'Eagle/Color/eagle_color.svg',
  },
  {
    name: 'duck',
    glyph: '🦆',
    id: 604,
    img: 'Duck/Color/duck_color.svg',
  },
  {
    name: 'swan',
    glyph: '🦢',
    id: 605,
    img: 'Swan/Color/swan_color.svg',
  },
  {
    name: 'owl',
    glyph: '🦉',
    id: 606,
    img: 'Owl/Color/owl_color.svg',
  },
  {
    name: 'dodo',
    glyph: '🦤',
    id: 607,
    img: 'Dodo/Color/dodo_color.svg',
  },
  {
    name: 'feather',
    glyph: '🪶',
    id: 608,
    img: 'Feather/Color/feather_color.svg',
  },
  {
    name: 'flamingo',
    glyph: '🦩',
    id: 609,
    img: 'Flamingo/Color/flamingo_color.svg',
  },
  {
    name: 'peacock',
    glyph: '🦚',
    id: 610,
    img: 'Peacock/Color/peacock_color.svg',
  },
  {
    name: 'parrot',
    glyph: '🦜',
    id: 611,
    img: 'Parrot/Color/parrot_color.svg',
  },
  {
    name: 'frog',
    glyph: '🐸',
    id: 612,
    img: 'Frog/Color/frog_color.svg',
  },
  {
    name: 'crocodile',
    glyph: '🐊',
    id: 613,
    img: 'Crocodile/Color/crocodile_color.svg',
  },
  {
    name: 'turtle',
    glyph: '🐢',
    id: 614,
    img: 'Turtle/Color/turtle_color.svg',
  },
  {
    name: 'lizard',
    glyph: '🦎',
    id: 615,
    img: 'Lizard/Color/lizard_color.svg',
  },
  {
    name: 'snake',
    glyph: '🐍',
    id: 616,
    img: 'Snake/Color/snake_color.svg',
  },
  {
    name: 'dragon face',
    glyph: '🐲',
    id: 617,
    img: 'Dragon face/Color/dragon_face_color.svg',
  },
  {
    name: 'dragon',
    glyph: '🐉',
    id: 618,
    img: 'Dragon/Color/dragon_color.svg',
  },
  {
    name: 'sauropod',
    glyph: '🦕',
    id: 619,
    img: 'Sauropod/Color/sauropod_color.svg',
  },
  {
    name: 'T-Rex',
    glyph: '🦖',
    id: 620,
    img: 'T-rex/Color/t-rex_color.svg',
  },
  {
    name: 'spouting whale',
    glyph: '🐳',
    id: 621,
    img: 'Spouting whale/Color/spouting_whale_color.svg',
  },
  {
    name: 'whale',
    glyph: '🐋',
    id: 622,
    img: 'Whale/Color/whale_color.svg',
  },
  {
    name: 'dolphin',
    glyph: '🐬',
    id: 623,
    img: 'Dolphin/Color/dolphin_color.svg',
  },
  {
    name: 'seal',
    glyph: '🦭',
    id: 624,
    img: 'Seal/Color/seal_color.svg',
  },
  {
    name: 'fish',
    glyph: '🐟',
    id: 625,
    img: 'Fish/Color/fish_color.svg',
  },
  {
    name: 'tropical fish',
    glyph: '🐠',
    id: 626,
    img: 'Tropical fish/Color/tropical_fish_color.svg',
  },
  {
    name: 'blowfish',
    glyph: '🐡',
    id: 627,
    img: 'Blowfish/Color/blowfish_color.svg',
  },
  {
    name: 'shark',
    glyph: '🦈',
    id: 628,
    img: 'Shark/Color/shark_color.svg',
  },
  {
    name: 'octopus',
    glyph: '🐙',
    id: 629,
    img: 'Octopus/Color/octopus_color.svg',
  },
  {
    name: 'spiral shell',
    glyph: '🐚',
    id: 630,
    img: 'Spiral shell/Color/spiral_shell_color.svg',
  },
  {
    name: 'coral',
    glyph: '🪸',
    id: 631,
    img: 'Coral/Color/coral_color.svg',
  },
  {
    name: 'snail',
    glyph: '🐌',
    id: 632,
    img: 'Snail/Color/snail_color.svg',
  },
  {
    name: 'butterfly',
    glyph: '🦋',
    id: 633,
    img: 'Butterfly/Color/butterfly_color.svg',
  },
  {
    name: 'bug',
    glyph: '🐛',
    id: 634,
    img: 'Bug/Color/bug_color.svg',
  },
  {
    name: 'ant',
    glyph: '🐜',
    id: 635,
    img: 'Ant/Color/ant_color.svg',
  },
  {
    name: 'honeybee',
    glyph: '🐝',
    id: 636,
    img: 'Honeybee/Color/honeybee_color.svg',
  },
  {
    name: 'beetle',
    glyph: '🪲',
    id: 637,
    img: 'Beetle/Color/beetle_color.svg',
  },
  {
    name: 'lady beetle',
    glyph: '🐞',
    id: 638,
    img: 'Lady beetle/Color/lady_beetle_color.svg',
  },
  {
    name: 'cricket',
    glyph: '🦗',
    id: 639,
    img: 'Cricket/Color/cricket_color.svg',
  },
  {
    name: 'cockroach',
    glyph: '🪳',
    id: 640,
    img: 'Cockroach/Color/cockroach_color.svg',
  },
  {
    name: 'spider',
    glyph: '🕷️',
    id: 641,
    img: 'Spider/Color/spider_color.svg',
  },
  {
    name: 'spider web',
    glyph: '🕸️',
    id: 642,
    img: 'Spider web/Color/spider_web_color.svg',
  },
  {
    name: 'scorpion',
    glyph: '🦂',
    id: 643,
    img: 'Scorpion/Color/scorpion_color.svg',
  },
  {
    name: 'mosquito',
    glyph: '🦟',
    id: 644,
    img: 'Mosquito/Color/mosquito_color.svg',
  },
  {
    name: 'fly',
    glyph: '🪰',
    id: 645,
    img: 'Fly/Color/fly_color.svg',
  },
  {
    name: 'worm',
    glyph: '🪱',
    id: 646,
    img: 'Worm/Color/worm_color.svg',
  },
  {
    name: 'microbe',
    glyph: '🦠',
    id: 647,
    img: 'Microbe/Color/microbe_color.svg',
  },
  {
    name: 'bouquet',
    glyph: '💐',
    id: 648,
    img: 'Bouquet/Color/bouquet_color.svg',
  },
  {
    name: 'cherry blossom',
    glyph: '🌸',
    id: 649,
    img: 'Cherry blossom/Color/cherry_blossom_color.svg',
  },
  {
    name: 'white flower',
    glyph: '💮',
    id: 650,
    img: 'White flower/Color/white_flower_color.svg',
  },
  {
    name: 'lotus',
    glyph: '🪷',
    id: 651,
    img: 'Lotus/Color/lotus_color.svg',
  },
  {
    name: 'rosette',
    glyph: '🏵️',
    id: 652,
    img: 'Rosette/Color/rosette_color.svg',
  },
  {
    name: 'rose',
    glyph: '🌹',
    id: 653,
    img: 'Rose/Color/rose_color.svg',
  },
  {
    name: 'wilted flower',
    glyph: '🥀',
    id: 654,
    img: 'Wilted flower/Color/wilted_flower_color.svg',
  },
  {
    name: 'hibiscus',
    glyph: '🌺',
    id: 655,
    img: 'Hibiscus/Color/hibiscus_color.svg',
  },
  {
    name: 'sunflower',
    glyph: '🌻',
    id: 656,
    img: 'Sunflower/Color/sunflower_color.svg',
  },
  {
    name: 'blossom',
    glyph: '🌼',
    id: 657,
    img: 'Blossom/Color/blossom_color.svg',
  },
  {
    name: 'tulip',
    glyph: '🌷',
    id: 658,
    img: 'Tulip/Color/tulip_color.svg',
  },
  {
    name: 'seedling',
    glyph: '🌱',
    id: 659,
    img: 'Seedling/Color/seedling_color.svg',
  },
  {
    name: 'potted plant',
    glyph: '🪴',
    id: 660,
    img: 'Potted plant/Color/potted_plant_color.svg',
  },
  {
    name: 'evergreen tree',
    glyph: '🌲',
    id: 661,
    img: 'Evergreen tree/Color/evergreen_tree_color.svg',
  },
  {
    name: 'deciduous tree',
    glyph: '🌳',
    id: 662,
    img: 'Deciduous tree/Color/deciduous_tree_color.svg',
  },
  {
    name: 'palm tree',
    glyph: '🌴',
    id: 663,
    img: 'Palm tree/Color/palm_tree_color.svg',
  },
  {
    name: 'cactus',
    glyph: '🌵',
    id: 664,
    img: 'Cactus/Color/cactus_color.svg',
  },
  {
    name: 'sheaf of rice',
    glyph: '🌾',
    id: 665,
    img: 'Sheaf of rice/Color/sheaf_of_rice_color.svg',
  },
  {
    name: 'herb',
    glyph: '🌿',
    id: 666,
    img: 'Herb/Color/herb_color.svg',
  },
  {
    name: 'shamrock',
    glyph: '☘️',
    id: 667,
    img: 'Shamrock/Color/shamrock_color.svg',
  },
  {
    name: 'four leaf clover',
    glyph: '🍀',
    id: 668,
    img: 'Four leaf clover/Color/four_leaf_clover_color.svg',
  },
  {
    name: 'maple leaf',
    glyph: '🍁',
    id: 669,
    img: 'Maple leaf/Color/maple_leaf_color.svg',
  },
  {
    name: 'fallen leaf',
    glyph: '🍂',
    id: 670,
    img: 'Fallen leaf/Color/fallen_leaf_color.svg',
  },
  {
    name: 'leaf fluttering in wind',
    glyph: '🍃',
    id: 671,
    img: 'Leaf fluttering in wind/Color/leaf_fluttering_in_wind_color.svg',
  },
  {
    name: 'empty nest',
    glyph: '🪹',
    id: 672,
    img: 'Empty nest/Color/empty_nest_color.svg',
  },
  {
    name: 'nest with eggs',
    glyph: '🪺',
    id: 673,
    img: 'Nest with eggs/Color/nest_with_eggs_color.svg',
  },
  {
    name: 'grapes',
    glyph: '🍇',
    id: 674,
    img: 'Grapes/Color/grapes_color.svg',
  },
  {
    name: 'melon',
    glyph: '🍈',
    id: 675,
    img: 'Melon/Color/melon_color.svg',
  },
  {
    name: 'watermelon',
    glyph: '🍉',
    id: 676,
    img: 'Watermelon/Color/watermelon_color.svg',
  },
  {
    name: 'tangerine',
    glyph: '🍊',
    id: 677,
    img: 'Tangerine/Color/tangerine_color.svg',
  },
  {
    name: 'lemon',
    glyph: '🍋',
    id: 678,
    img: 'Lemon/Color/lemon_color.svg',
  },
  {
    name: 'banana',
    glyph: '🍌',
    id: 679,
    img: 'Banana/Color/banana_color.svg',
  },
  {
    name: 'pineapple',
    glyph: '🍍',
    id: 680,
    img: 'Pineapple/Color/pineapple_color.svg',
  },
  {
    name: 'mango',
    glyph: '🥭',
    id: 681,
    img: 'Mango/Color/mango_color.svg',
  },
  {
    name: 'red apple',
    glyph: '🍎',
    id: 682,
    img: 'Red apple/Color/red_apple_color.svg',
  },
  {
    name: 'green apple',
    glyph: '🍏',
    id: 683,
    img: 'Green apple/Color/green_apple_color.svg',
  },
  {
    name: 'pear',
    glyph: '🍐',
    id: 684,
    img: 'Pear/Color/pear_color.svg',
  },
  {
    name: 'peach',
    glyph: '🍑',
    id: 685,
    img: 'Peach/Color/peach_color.svg',
  },
  {
    name: 'cherries',
    glyph: '🍒',
    id: 686,
    img: 'Cherries/Color/cherries_color.svg',
  },
  {
    name: 'strawberry',
    glyph: '🍓',
    id: 687,
    img: 'Strawberry/Color/strawberry_color.svg',
  },
  {
    name: 'blueberries',
    glyph: '🫐',
    id: 688,
    img: 'Blueberries/Color/blueberries_color.svg',
  },
  {
    name: 'kiwi fruit',
    glyph: '🥝',
    id: 689,
    img: 'Kiwi fruit/Color/kiwi_fruit_color.svg',
  },
  {
    name: 'tomato',
    glyph: '🍅',
    id: 690,
    img: 'Tomato/Color/tomato_color.svg',
  },
  {
    name: 'olive',
    glyph: '🫒',
    id: 691,
    img: 'Olive/Color/olive_color.svg',
  },
  {
    name: 'coconut',
    glyph: '🥥',
    id: 692,
    img: 'Coconut/Color/coconut_color.svg',
  },
  {
    name: 'avocado',
    glyph: '🥑',
    id: 693,
    img: 'Avocado/Color/avocado_color.svg',
  },
  {
    name: 'eggplant',
    glyph: '🍆',
    id: 694,
    img: 'Eggplant/Color/eggplant_color.svg',
  },
  {
    name: 'potato',
    glyph: '🥔',
    id: 695,
    img: 'Potato/Color/potato_color.svg',
  },
  {
    name: 'carrot',
    glyph: '🥕',
    id: 696,
    img: 'Carrot/Color/carrot_color.svg',
  },
  {
    name: 'ear of corn',
    glyph: '🌽',
    id: 697,
    img: 'Ear of corn/Color/ear_of_corn_color.svg',
  },
  {
    name: 'hot pepper',
    glyph: '🌶️',
    id: 698,
    img: 'Hot pepper/Color/hot_pepper_color.svg',
  },
  {
    name: 'bell pepper',
    glyph: '🫑',
    id: 699,
    img: 'Bell pepper/Color/bell_pepper_color.svg',
  },
  {
    name: 'cucumber',
    glyph: '🥒',
    id: 700,
    img: 'Cucumber/Color/cucumber_color.svg',
  },
  {
    name: 'leafy green',
    glyph: '🥬',
    id: 701,
    img: 'Leafy green/Color/leafy_green_color.svg',
  },
  {
    name: 'broccoli',
    glyph: '🥦',
    id: 702,
    img: 'Broccoli/Color/broccoli_color.svg',
  },
  {
    name: 'garlic',
    glyph: '🧄',
    id: 703,
    img: 'Garlic/Color/garlic_color.svg',
  },
  {
    name: 'onion',
    glyph: '🧅',
    id: 704,
    img: 'Onion/Color/onion_color.svg',
  },
  {
    name: 'mushroom',
    glyph: '🍄',
    id: 705,
    img: 'Mushroom/Color/mushroom_color.svg',
  },
  {
    name: 'peanuts',
    glyph: '🥜',
    id: 706,
    img: 'Peanuts/Color/peanuts_color.svg',
  },
  {
    name: 'beans',
    glyph: '🫘',
    id: 707,
    img: 'Beans/Color/beans_color.svg',
  },
  {
    name: 'chestnut',
    glyph: '🌰',
    id: 708,
    img: 'Chestnut/Color/chestnut_color.svg',
  },
  {
    name: 'bread',
    glyph: '🍞',
    id: 709,
    img: 'Bread/Color/bread_color.svg',
  },
  {
    name: 'croissant',
    glyph: '🥐',
    id: 710,
    img: 'Croissant/Color/croissant_color.svg',
  },
  {
    name: 'baguette bread',
    glyph: '🥖',
    id: 711,
    img: 'Baguette bread/Color/baguette_bread_color.svg',
  },
  {
    name: 'flatbread',
    glyph: '🫓',
    id: 712,
    img: 'Flatbread/Color/flatbread_color.svg',
  },
  {
    name: 'pretzel',
    glyph: '🥨',
    id: 713,
    img: 'Pretzel/Color/pretzel_color.svg',
  },
  {
    name: 'bagel',
    glyph: '🥯',
    id: 714,
    img: 'Bagel/Color/bagel_color.svg',
  },
  {
    name: 'pancakes',
    glyph: '🥞',
    id: 715,
    img: 'Pancakes/Color/pancakes_color.svg',
  },
  {
    name: 'waffle',
    glyph: '🧇',
    id: 716,
    img: 'Waffle/Color/waffle_color.svg',
  },
  {
    name: 'cheese wedge',
    glyph: '🧀',
    id: 717,
    img: 'Cheese wedge/Color/cheese_wedge_color.svg',
  },
  {
    name: 'meat on bone',
    glyph: '🍖',
    id: 718,
    img: 'Meat on bone/Color/meat_on_bone_color.svg',
  },
  {
    name: 'poultry leg',
    glyph: '🍗',
    id: 719,
    img: 'Poultry leg/Color/poultry_leg_color.svg',
  },
  {
    name: 'cut of meat',
    glyph: '🥩',
    id: 720,
    img: 'Cut of meat/Color/cut_of_meat_color.svg',
  },
  {
    name: 'bacon',
    glyph: '🥓',
    id: 721,
    img: 'Bacon/Color/bacon_color.svg',
  },
  {
    name: 'hamburger',
    glyph: '🍔',
    id: 722,
    img: 'Hamburger/Color/hamburger_color.svg',
  },
  {
    name: 'french fries',
    glyph: '🍟',
    id: 723,
    img: 'French fries/Color/french_fries_color.svg',
  },
  {
    name: 'pizza',
    glyph: '🍕',
    id: 724,
    img: 'Pizza/Color/pizza_color.svg',
  },
  {
    name: 'hot dog',
    glyph: '🌭',
    id: 725,
    img: 'Hot dog/Color/hot_dog_color.svg',
  },
  {
    name: 'sandwich',
    glyph: '🥪',
    id: 726,
    img: 'Sandwich/Color/sandwich_color.svg',
  },
  {
    name: 'taco',
    glyph: '🌮',
    id: 727,
    img: 'Taco/Color/taco_color.svg',
  },
  {
    name: 'burrito',
    glyph: '🌯',
    id: 728,
    img: 'Burrito/Color/burrito_color.svg',
  },
  {
    name: 'tamale',
    glyph: '🫔',
    id: 729,
    img: 'Tamale/Color/tamale_color.svg',
  },
  {
    name: 'stuffed flatbread',
    glyph: '🥙',
    id: 730,
    img: 'Stuffed flatbread/Color/stuffed_flatbread_color.svg',
  },
  {
    name: 'falafel',
    glyph: '🧆',
    id: 731,
    img: 'Falafel/Color/falafel_color.svg',
  },
  {
    name: 'egg',
    glyph: '🥚',
    id: 732,
    img: 'Egg/Color/egg_color.svg',
  },
  {
    name: 'cooking',
    glyph: '🍳',
    id: 733,
    img: 'Cooking/Color/cooking_color.svg',
  },
  {
    name: 'shallow pan of food',
    glyph: '🥘',
    id: 734,
    img: 'Shallow pan of food/Color/shallow_pan_of_food_color.svg',
  },
  {
    name: 'pot of food',
    glyph: '🍲',
    id: 735,
    img: 'Pot of food/Color/pot_of_food_color.svg',
  },
  {
    name: 'fondue',
    glyph: '🫕',
    id: 736,
    img: 'Fondue/Color/fondue_color.svg',
  },
  {
    name: 'bowl with spoon',
    glyph: '🥣',
    id: 737,
    img: 'Bowl with spoon/Color/bowl_with_spoon_color.svg',
  },
  {
    name: 'green salad',
    glyph: '🥗',
    id: 738,
    img: 'Green salad/Color/green_salad_color.svg',
  },
  {
    name: 'popcorn',
    glyph: '🍿',
    id: 739,
    img: 'Popcorn/Color/popcorn_color.svg',
  },
  {
    name: 'butter',
    glyph: '🧈',
    id: 740,
    img: 'Butter/Color/butter_color.svg',
  },
  {
    name: 'salt',
    glyph: '🧂',
    id: 741,
    img: 'Salt/Color/salt_color.svg',
  },
  {
    name: 'canned food',
    glyph: '🥫',
    id: 742,
    img: 'Canned food/Color/canned_food_color.svg',
  },
  {
    name: 'bento box',
    glyph: '🍱',
    id: 743,
    img: 'Bento box/Color/bento_box_color.svg',
  },
  {
    name: 'rice cracker',
    glyph: '🍘',
    id: 744,
    img: 'Rice cracker/Color/rice_cracker_color.svg',
  },
  {
    name: 'rice ball',
    glyph: '🍙',
    id: 745,
    img: 'Rice ball/Color/rice_ball_color.svg',
  },
  {
    name: 'cooked rice',
    glyph: '🍚',
    id: 746,
    img: 'Cooked rice/Color/cooked_rice_color.svg',
  },
  {
    name: 'curry rice',
    glyph: '🍛',
    id: 747,
    img: 'Curry rice/Color/curry_rice_color.svg',
  },
  {
    name: 'steaming bowl',
    glyph: '🍜',
    id: 748,
    img: 'Steaming bowl/Color/steaming_bowl_color.svg',
  },
  {
    name: 'spaghetti',
    glyph: '🍝',
    id: 749,
    img: 'Spaghetti/Color/spaghetti_color.svg',
  },
  {
    name: 'roasted sweet potato',
    glyph: '🍠',
    id: 750,
    img: 'Roasted sweet potato/Color/roasted_sweet_potato_color.svg',
  },
  {
    name: 'oden',
    glyph: '🍢',
    id: 751,
    img: 'Oden/Color/oden_color.svg',
  },
  {
    name: 'sushi',
    glyph: '🍣',
    id: 752,
    img: 'Sushi/Color/sushi_color.svg',
  },
  {
    name: 'fried shrimp',
    glyph: '🍤',
    id: 753,
    img: 'Fried shrimp/Color/fried_shrimp_color.svg',
  },
  {
    name: 'fish cake with swirl',
    glyph: '🍥',
    id: 754,
    img: 'Fish cake with swirl/Color/fish_cake_with_swirl_color.svg',
  },
  {
    name: 'moon cake',
    glyph: '🥮',
    id: 755,
    img: 'Moon cake/Color/moon_cake_color.svg',
  },
  {
    name: 'dango',
    glyph: '🍡',
    id: 756,
    img: 'Dango/Color/dango_color.svg',
  },
  {
    name: 'dumpling',
    glyph: '🥟',
    id: 757,
    img: 'Dumpling/Color/dumpling_color.svg',
  },
  {
    name: 'fortune cookie',
    glyph: '🥠',
    id: 758,
    img: 'Fortune cookie/Color/fortune_cookie_color.svg',
  },
  {
    name: 'takeout box',
    glyph: '🥡',
    id: 759,
    img: 'Takeout box/Color/takeout_box_color.svg',
  },
  {
    name: 'crab',
    glyph: '🦀',
    id: 760,
    img: 'Crab/Color/crab_color.svg',
  },
  {
    name: 'lobster',
    glyph: '🦞',
    id: 761,
    img: 'Lobster/Color/lobster_color.svg',
  },
  {
    name: 'shrimp',
    glyph: '🦐',
    id: 762,
    img: 'Shrimp/Color/shrimp_color.svg',
  },
  {
    name: 'squid',
    glyph: '🦑',
    id: 763,
    img: 'Squid/Color/squid_color.svg',
  },
  {
    name: 'oyster',
    glyph: '🦪',
    id: 764,
    img: 'Oyster/Color/oyster_color.svg',
  },
  {
    name: 'soft ice cream',
    glyph: '🍦',
    id: 765,
    img: 'Soft ice cream/Color/soft_ice_cream_color.svg',
  },
  {
    name: 'shaved ice',
    glyph: '🍧',
    id: 766,
    img: 'Shaved ice/Color/shaved_ice_color.svg',
  },
  {
    name: 'ice cream',
    glyph: '🍨',
    id: 767,
    img: 'Ice cream/Color/ice_cream_color.svg',
  },
  {
    name: 'doughnut',
    glyph: '🍩',
    id: 768,
    img: 'Doughnut/Color/doughnut_color.svg',
  },
  {
    name: 'cookie',
    glyph: '🍪',
    id: 769,
    img: 'Cookie/Color/cookie_color.svg',
  },
  {
    name: 'birthday cake',
    glyph: '🎂',
    id: 770,
    img: 'Birthday cake/Color/birthday_cake_color.svg',
  },
  {
    name: 'shortcake',
    glyph: '🍰',
    id: 771,
    img: 'Shortcake/Color/shortcake_color.svg',
  },
  {
    name: 'cupcake',
    glyph: '🧁',
    id: 772,
    img: 'Cupcake/Color/cupcake_color.svg',
  },
  {
    name: 'pie',
    glyph: '🥧',
    id: 773,
    img: 'Pie/Color/pie_color.svg',
  },
  {
    name: 'chocolate bar',
    glyph: '🍫',
    id: 774,
    img: 'Chocolate bar/Color/chocolate_bar_color.svg',
  },
  {
    name: 'candy',
    glyph: '🍬',
    id: 775,
    img: 'Candy/Color/candy_color.svg',
  },
  {
    name: 'lollipop',
    glyph: '🍭',
    id: 776,
    img: 'Lollipop/Color/lollipop_color.svg',
  },
  {
    name: 'custard',
    glyph: '🍮',
    id: 777,
    img: 'Custard/Color/custard_color.svg',
  },
  {
    name: 'honey pot',
    glyph: '🍯',
    id: 778,
    img: 'Honey pot/Color/honey_pot_color.svg',
  },
  {
    name: 'baby bottle',
    glyph: '🍼',
    id: 779,
    img: 'Baby bottle/Color/baby_bottle_color.svg',
  },
  {
    name: 'glass of milk',
    glyph: '🥛',
    id: 780,
    img: 'Glass of milk/Color/glass_of_milk_color.svg',
  },
  {
    name: 'hot beverage',
    glyph: '☕',
    id: 781,
    img: 'Hot beverage/Color/hot_beverage_color.svg',
  },
  {
    name: 'teapot',
    glyph: '🫖',
    id: 782,
    img: 'Teapot/Color/teapot_color.svg',
  },
  {
    name: 'teacup without handle',
    glyph: '🍵',
    id: 783,
    img: 'Teacup without handle/Color/teacup_without_handle_color.svg',
  },
  {
    name: 'sake',
    glyph: '🍶',
    id: 784,
    img: 'Sake/Color/sake_color.svg',
  },
  {
    name: 'bottle with popping cork',
    glyph: '🍾',
    id: 785,
    img: 'Bottle with popping cork/Color/bottle_with_popping_cork_color.svg',
  },
  {
    name: 'wine glass',
    glyph: '🍷',
    id: 786,
    img: 'Wine glass/Color/wine_glass_color.svg',
  },
  {
    name: 'cocktail glass',
    glyph: '🍸',
    id: 787,
    img: 'Cocktail glass/Color/cocktail_glass_color.svg',
  },
  {
    name: 'tropical drink',
    glyph: '🍹',
    id: 788,
    img: 'Tropical drink/Color/tropical_drink_color.svg',
  },
  {
    name: 'beer mug',
    glyph: '🍺',
    id: 789,
    img: 'Beer mug/Color/beer_mug_color.svg',
  },
  {
    name: 'clinking beer mugs',
    glyph: '🍻',
    id: 790,
    img: 'Clinking beer mugs/Color/clinking_beer_mugs_color.svg',
  },
  {
    name: 'clinking glasses',
    glyph: '🥂',
    id: 791,
    img: 'Clinking glasses/Color/clinking_glasses_color.svg',
  },
  {
    name: 'tumbler glass',
    glyph: '🥃',
    id: 792,
    img: 'Tumbler glass/Color/tumbler_glass_color.svg',
  },
  {
    name: 'pouring liquid',
    glyph: '🫗',
    id: 793,
    img: 'Pouring liquid/Color/pouring_liquid_color.svg',
  },
  {
    name: 'cup with straw',
    glyph: '🥤',
    id: 794,
    img: 'Cup with straw/Color/cup_with_straw_color.svg',
  },
  {
    name: 'bubble tea',
    glyph: '🧋',
    id: 795,
    img: 'Bubble tea/Color/bubble_tea_color.svg',
  },
  {
    name: 'beverage box',
    glyph: '🧃',
    id: 796,
    img: 'Beverage box/Color/beverage_box_color.svg',
  },
  {
    name: 'mate',
    glyph: '🧉',
    id: 797,
    img: 'Mate/Color/mate_color.svg',
  },
  {
    name: 'ice',
    glyph: '🧊',
    id: 798,
    img: 'Ice/Color/ice_color.svg',
  },
  {
    name: 'chopsticks',
    glyph: '🥢',
    id: 799,
    img: 'Chopsticks/Color/chopsticks_color.svg',
  },
  {
    name: 'fork and knife with plate',
    glyph: '🍽️',
    id: 800,
    img: 'Fork and knife with plate/Color/fork_and_knife_with_plate_color.svg',
  },
  {
    name: 'fork and knife',
    glyph: '🍴',
    id: 801,
    img: 'Fork and knife/Color/fork_and_knife_color.svg',
  },
  {
    name: 'spoon',
    glyph: '🥄',
    id: 802,
    img: 'Spoon/Color/spoon_color.svg',
  },
  {
    name: 'kitchen knife',
    glyph: '🔪',
    id: 803,
    img: 'Kitchen knife/Color/kitchen_knife_color.svg',
  },
  {
    name: 'jar',
    glyph: '🫙',
    id: 804,
    img: 'Jar/Color/jar_color.svg',
  },
  {
    name: 'amphora',
    glyph: '🏺',
    id: 805,
    img: 'Amphora/Color/amphora_color.svg',
  },
  {
    name: 'globe showing Europe-Africa',
    glyph: '🌍',
    id: 806,
    img: 'Globe showing europe-africa/Color/globe_showing_europe-africa_color.svg',
  },
  {
    name: 'globe showing Americas',
    glyph: '🌎',
    id: 807,
    img: 'Globe showing americas/Color/globe_showing_americas_color.svg',
  },
  {
    name: 'globe showing Asia-Australia',
    glyph: '🌏',
    id: 808,
    img: 'Globe showing asia-australia/Color/globe_showing_asia-australia_color.svg',
  },
  {
    name: 'globe with meridians',
    glyph: '🌐',
    id: 809,
    img: 'Globe with meridians/Color/globe_with_meridians_color.svg',
  },
  {
    name: 'world map',
    glyph: '🗺️',
    id: 810,
    img: 'World map/Color/world_map_color.svg',
  },
  {
    name: 'map of Japan',
    glyph: '🗾',
    id: 811,
    img: 'Map of japan/Color/map_of_japan_color.svg',
  },
  {
    name: 'compass',
    glyph: '🧭',
    id: 812,
    img: 'Compass/Color/compass_color.svg',
  },
  {
    name: 'snow-capped mountain',
    glyph: '🏔️',
    id: 813,
    img: 'Snow-capped mountain/Color/snow-capped_mountain_color.svg',
  },
  {
    name: 'mountain',
    glyph: '⛰️',
    id: 814,
    img: 'Mountain/Color/mountain_color.svg',
  },
  {
    name: 'volcano',
    glyph: '🌋',
    id: 815,
    img: 'Volcano/Color/volcano_color.svg',
  },
  {
    name: 'mount fuji',
    glyph: '🗻',
    id: 816,
    img: 'Mount fuji/Color/mount_fuji_color.svg',
  },
  {
    name: 'camping',
    glyph: '🏕️',
    id: 817,
    img: 'Camping/Color/camping_color.svg',
  },
  {
    name: 'beach with umbrella',
    glyph: '🏖️',
    id: 818,
    img: 'Beach with umbrella/Color/beach_with_umbrella_color.svg',
  },
  {
    name: 'desert',
    glyph: '🏜️',
    id: 819,
    img: 'Desert/Color/desert_color.svg',
  },
  {
    name: 'desert island',
    glyph: '🏝️',
    id: 820,
    img: 'Desert island/Color/desert_island_color.svg',
  },
  {
    name: 'national park',
    glyph: '🏞️',
    id: 821,
    img: 'National park/Color/national_park_color.svg',
  },
  {
    name: 'stadium',
    glyph: '🏟️',
    id: 822,
    img: 'Stadium/Color/stadium_color.svg',
  },
  {
    name: 'classical building',
    glyph: '🏛️',
    id: 823,
    img: 'Classical building/Color/classical_building_color.svg',
  },
  {
    name: 'building construction',
    glyph: '🏗️',
    id: 824,
    img: 'Building construction/Color/building_construction_color.svg',
  },
  {
    name: 'brick',
    glyph: '🧱',
    id: 825,
    img: 'Brick/Color/brick_color.svg',
  },
  {
    name: 'rock',
    glyph: '🪨',
    id: 826,
    img: 'Rock/Color/rock_color.svg',
  },
  {
    name: 'wood',
    glyph: '🪵',
    id: 827,
    img: 'Wood/Color/wood_color.svg',
  },
  {
    name: 'hut',
    glyph: '🛖',
    id: 828,
    img: 'Hut/Color/hut_color.svg',
  },
  {
    name: 'houses',
    glyph: '🏘️',
    id: 829,
    img: 'Houses/Color/houses_color.svg',
  },
  {
    name: 'derelict house',
    glyph: '🏚️',
    id: 830,
    img: 'Derelict house/Color/derelict_house_color.svg',
  },
  {
    name: 'house',
    glyph: '🏠',
    id: 831,
    img: 'House/Color/house_color.svg',
  },
  {
    name: 'house with garden',
    glyph: '🏡',
    id: 832,
    img: 'House with garden/Color/house_with_garden_color.svg',
  },
  {
    name: 'office building',
    glyph: '🏢',
    id: 833,
    img: 'Office building/Color/office_building_color.svg',
  },
  {
    name: 'Japanese post office',
    glyph: '🏣',
    id: 834,
    img: 'Japanese post office/Color/japanese_post_office_color.svg',
  },
  {
    name: 'post office',
    glyph: '🏤',
    id: 835,
    img: 'Post office/Color/post_office_color.svg',
  },
  {
    name: 'hospital',
    glyph: '🏥',
    id: 836,
    img: 'Hospital/Color/hospital_color.svg',
  },
  {
    name: 'bank',
    glyph: '🏦',
    id: 837,
    img: 'Bank/Color/bank_color.svg',
  },
  {
    name: 'hotel',
    glyph: '🏨',
    id: 838,
    img: 'Hotel/Color/hotel_color.svg',
  },
  {
    name: 'love hotel',
    glyph: '🏩',
    id: 839,
    img: 'Love hotel/Color/love_hotel_color.svg',
  },
  {
    name: 'convenience store',
    glyph: '🏪',
    id: 840,
    img: 'Convenience store/Color/convenience_store_color.svg',
  },
  {
    name: 'school',
    glyph: '🏫',
    id: 841,
    img: 'School/Color/school_color.svg',
  },
  {
    name: 'department store',
    glyph: '🏬',
    id: 842,
    img: 'Department store/Color/department_store_color.svg',
  },
  {
    name: 'factory',
    glyph: '🏭',
    id: 843,
    img: 'Factory/Color/factory_color.svg',
  },
  {
    name: 'Japanese castle',
    glyph: '🏯',
    id: 844,
    img: 'Japanese castle/Color/japanese_castle_color.svg',
  },
  {
    name: 'castle',
    glyph: '🏰',
    id: 845,
    img: 'Castle/Color/castle_color.svg',
  },
  {
    name: 'wedding',
    glyph: '💒',
    id: 846,
    img: 'Wedding/Color/wedding_color.svg',
  },
  {
    name: 'Tokyo tower',
    glyph: '🗼',
    id: 847,
    img: 'Tokyo tower/Color/tokyo_tower_color.svg',
  },
  {
    name: 'Statue of Liberty',
    glyph: '🗽',
    id: 848,
    img: 'Statue of liberty/Color/statue_of_liberty_color.svg',
  },
  {
    name: 'church',
    glyph: '⛪',
    id: 849,
    img: 'Church/Color/church_color.svg',
  },
  {
    name: 'mosque',
    glyph: '🕌',
    id: 850,
    img: 'Mosque/Color/mosque_color.svg',
  },
  {
    name: 'hindu temple',
    glyph: '🛕',
    id: 851,
    img: 'Hindu temple/Color/hindu_temple_color.svg',
  },
  {
    name: 'synagogue',
    glyph: '🕍',
    id: 852,
    img: 'Synagogue/Color/synagogue_color.svg',
  },
  {
    name: 'shinto shrine',
    glyph: '⛩️',
    id: 853,
    img: 'Shinto shrine/Color/shinto_shrine_color.svg',
  },
  {
    name: 'kaaba',
    glyph: '🕋',
    id: 854,
    img: 'Kaaba/Color/kaaba_color.svg',
  },
  {
    name: 'fountain',
    glyph: '⛲',
    id: 855,
    img: 'Fountain/Color/fountain_color.svg',
  },
  {
    name: 'tent',
    glyph: '⛺',
    id: 856,
    img: 'Tent/Color/tent_color.svg',
  },
  {
    name: 'foggy',
    glyph: '🌁',
    id: 857,
    img: 'Foggy/Color/foggy_color.svg',
  },
  {
    name: 'night with stars',
    glyph: '🌃',
    id: 858,
    img: 'Night with stars/Color/night_with_stars_color.svg',
  },
  {
    name: 'cityscape',
    glyph: '🏙️',
    id: 859,
    img: 'Cityscape/Color/cityscape_color.svg',
  },
  {
    name: 'sunrise over mountains',
    glyph: '🌄',
    id: 860,
    img: 'Sunrise over mountains/Color/sunrise_over_mountains_color.svg',
  },
  {
    name: 'sunrise',
    glyph: '🌅',
    id: 861,
    img: 'Sunrise/Color/sunrise_color.svg',
  },
  {
    name: 'cityscape at dusk',
    glyph: '🌆',
    id: 862,
    img: 'Cityscape at dusk/Color/cityscape_at_dusk_color.svg',
  },
  {
    name: 'sunset',
    glyph: '🌇',
    id: 863,
    img: 'Sunset/Color/sunset_color.svg',
  },
  {
    name: 'bridge at night',
    glyph: '🌉',
    id: 864,
    img: 'Bridge at night/Color/bridge_at_night_color.svg',
  },
  {
    name: 'hot springs',
    glyph: '♨️',
    id: 865,
    img: 'Hot springs/Color/hot_springs_color.svg',
  },
  {
    name: 'carousel horse',
    glyph: '🎠',
    id: 866,
    img: 'Carousel horse/Color/carousel_horse_color.svg',
  },
  {
    name: 'playground slide',
    glyph: '🛝',
    id: 867,
    img: 'Playground slide/Color/playground_slide_color.svg',
  },
  {
    name: 'ferris wheel',
    glyph: '🎡',
    id: 868,
    img: 'Ferris wheel/Color/ferris_wheel_color.svg',
  },
  {
    name: 'roller coaster',
    glyph: '🎢',
    id: 869,
    img: 'Roller coaster/Color/roller_coaster_color.svg',
  },
  {
    name: 'barber pole',
    glyph: '💈',
    id: 870,
    img: 'Barber pole/Color/barber_pole_color.svg',
  },
  {
    name: 'circus tent',
    glyph: '🎪',
    id: 871,
    img: 'Circus tent/Color/circus_tent_color.svg',
  },
  {
    name: 'locomotive',
    glyph: '🚂',
    id: 872,
    img: 'Locomotive/Color/locomotive_color.svg',
  },
  {
    name: 'railway car',
    glyph: '🚃',
    id: 873,
    img: 'Railway car/Color/railway_car_color.svg',
  },
  {
    name: 'high-speed train',
    glyph: '🚄',
    id: 874,
    img: 'High-speed train/Color/high-speed_train_color.svg',
  },
  {
    name: 'bullet train',
    glyph: '🚅',
    id: 875,
    img: 'Bullet train/Color/bullet_train_color.svg',
  },
  {
    name: 'train',
    glyph: '🚆',
    id: 876,
    img: 'Train/Color/train_color.svg',
  },
  {
    name: 'metro',
    glyph: '🚇',
    id: 877,
    img: 'Metro/Color/metro_color.svg',
  },
  {
    name: 'light rail',
    glyph: '🚈',
    id: 878,
    img: 'Light rail/Color/light_rail_color.svg',
  },
  {
    name: 'station',
    glyph: '🚉',
    id: 879,
    img: 'Station/Color/station_color.svg',
  },
  {
    name: 'tram',
    glyph: '🚊',
    id: 880,
    img: 'Tram/Color/tram_color.svg',
  },
  {
    name: 'monorail',
    glyph: '🚝',
    id: 881,
    img: 'Monorail/Color/monorail_color.svg',
  },
  {
    name: 'mountain railway',
    glyph: '🚞',
    id: 882,
    img: 'Mountain railway/Color/mountain_railway_color.svg',
  },
  {
    name: 'tram car',
    glyph: '🚋',
    id: 883,
    img: 'Tram car/Color/tram_car_color.svg',
  },
  {
    name: 'bus',
    glyph: '🚌',
    id: 884,
    img: 'Bus/Color/bus_color.svg',
  },
  {
    name: 'oncoming bus',
    glyph: '🚍',
    id: 885,
    img: 'Oncoming bus/Color/oncoming_bus_color.svg',
  },
  {
    name: 'trolleybus',
    glyph: '🚎',
    id: 886,
    img: 'Trolleybus/Color/trolleybus_color.svg',
  },
  {
    name: 'minibus',
    glyph: '🚐',
    id: 887,
    img: 'Minibus/Color/minibus_color.svg',
  },
  {
    name: 'ambulance',
    glyph: '🚑',
    id: 888,
    img: 'Ambulance/Color/ambulance_color.svg',
  },
  {
    name: 'fire engine',
    glyph: '🚒',
    id: 889,
    img: 'Fire engine/Color/fire_engine_color.svg',
  },
  {
    name: 'police car',
    glyph: '🚓',
    id: 890,
    img: 'Police car/Color/police_car_color.svg',
  },
  {
    name: 'oncoming police car',
    glyph: '🚔',
    id: 891,
    img: 'Oncoming police car/Color/oncoming_police_car_color.svg',
  },
  {
    name: 'taxi',
    glyph: '🚕',
    id: 892,
    img: 'Taxi/Color/taxi_color.svg',
  },
  {
    name: 'oncoming taxi',
    glyph: '🚖',
    id: 893,
    img: 'Oncoming taxi/Color/oncoming_taxi_color.svg',
  },
  {
    name: 'automobile',
    glyph: '🚗',
    id: 894,
    img: 'Automobile/Color/automobile_color.svg',
  },
  {
    name: 'oncoming automobile',
    glyph: '🚘',
    id: 895,
    img: 'Oncoming automobile/Color/oncoming_automobile_color.svg',
  },
  {
    name: 'sport utility vehicle',
    glyph: '🚙',
    id: 896,
    img: 'Sport utility vehicle/Color/sport_utility_vehicle_color.svg',
  },
  {
    name: 'pickup truck',
    glyph: '🛻',
    id: 897,
    img: 'Pickup truck/Color/pickup_truck_color.svg',
  },
  {
    name: 'delivery truck',
    glyph: '🚚',
    id: 898,
    img: 'Delivery truck/Color/delivery_truck_color.svg',
  },
  {
    name: 'articulated lorry',
    glyph: '🚛',
    id: 899,
    img: 'Articulated lorry/Color/articulated_lorry_color.svg',
  },
  {
    name: 'tractor',
    glyph: '🚜',
    id: 900,
    img: 'Tractor/Color/tractor_color.svg',
  },
  {
    name: 'racing car',
    glyph: '🏎️',
    id: 901,
    img: 'Racing car/Color/racing_car_color.svg',
  },
  {
    name: 'motorcycle',
    glyph: '🏍️',
    id: 902,
    img: 'Motorcycle/Color/motorcycle_color.svg',
  },
  {
    name: 'motor scooter',
    glyph: '🛵',
    id: 903,
    img: 'Motor scooter/Color/motor_scooter_color.svg',
  },
  {
    name: 'manual wheelchair',
    glyph: '🦽',
    id: 904,
    img: 'Manual wheelchair/Color/manual_wheelchair_color.svg',
  },
  {
    name: 'motorized wheelchair',
    glyph: '🦼',
    id: 905,
    img: 'Motorized wheelchair/Color/motorized_wheelchair_color.svg',
  },
  {
    name: 'auto rickshaw',
    glyph: '🛺',
    id: 906,
    img: 'Auto rickshaw/Color/auto_rickshaw_color.svg',
  },
  {
    name: 'bicycle',
    glyph: '🚲',
    id: 907,
    img: 'Bicycle/Color/bicycle_color.svg',
  },
  {
    name: 'kick scooter',
    glyph: '🛴',
    id: 908,
    img: 'Kick scooter/Color/kick_scooter_color.svg',
  },
  {
    name: 'skateboard',
    glyph: '🛹',
    id: 909,
    img: 'Skateboard/Color/skateboard_color.svg',
  },
  {
    name: 'roller skate',
    glyph: '🛼',
    id: 910,
    img: 'Roller skate/Color/roller_skate_color.svg',
  },
  {
    name: 'bus stop',
    glyph: '🚏',
    id: 911,
    img: 'Bus stop/Color/bus_stop_color.svg',
  },
  {
    name: 'motorway',
    glyph: '🛣️',
    id: 912,
    img: 'Motorway/Color/motorway_color.svg',
  },
  {
    name: 'railway track',
    glyph: '🛤️',
    id: 913,
    img: 'Railway track/Color/railway_track_color.svg',
  },
  {
    name: 'oil drum',
    glyph: '🛢️',
    id: 914,
    img: 'Oil drum/Color/oil_drum_color.svg',
  },
  {
    name: 'fuel pump',
    glyph: '⛽',
    id: 915,
    img: 'Fuel pump/Color/fuel_pump_color.svg',
  },
  {
    name: 'wheel',
    glyph: '🛞',
    id: 916,
    img: 'Wheel/Color/wheel_color.svg',
  },
  {
    name: 'police car light',
    glyph: '🚨',
    id: 917,
    img: 'Police car light/Color/police_car_light_color.svg',
  },
  {
    name: 'horizontal traffic light',
    glyph: '🚥',
    id: 918,
    img: 'Horizontal traffic light/Color/horizontal_traffic_light_color.svg',
  },
  {
    name: 'vertical traffic light',
    glyph: '🚦',
    id: 919,
    img: 'Vertical traffic light/Color/vertical_traffic_light_color.svg',
  },
  {
    name: 'stop sign',
    glyph: '🛑',
    id: 920,
    img: 'Stop sign/Color/stop_sign_color.svg',
  },
  {
    name: 'construction',
    glyph: '🚧',
    id: 921,
    img: 'Construction/Color/construction_color.svg',
  },
  {
    name: 'anchor',
    glyph: '⚓',
    id: 922,
    img: 'Anchor/Color/anchor_color.svg',
  },
  {
    name: 'ring buoy',
    glyph: '🛟',
    id: 923,
    img: 'Ring buoy/Color/ring_buoy_color.svg',
  },
  {
    name: 'sailboat',
    glyph: '⛵',
    id: 924,
    img: 'Sailboat/Color/sailboat_color.svg',
  },
  {
    name: 'canoe',
    glyph: '🛶',
    id: 925,
    img: 'Canoe/Color/canoe_color.svg',
  },
  {
    name: 'speedboat',
    glyph: '🚤',
    id: 926,
    img: 'Speedboat/Color/speedboat_color.svg',
  },
  {
    name: 'passenger ship',
    glyph: '🛳️',
    id: 927,
    img: 'Passenger ship/Color/passenger_ship_color.svg',
  },
  {
    name: 'ferry',
    glyph: '⛴️',
    id: 928,
    img: 'Ferry/Color/ferry_color.svg',
  },
  {
    name: 'motor boat',
    glyph: '🛥️',
    id: 929,
    img: 'Motor boat/Color/motor_boat_color.svg',
  },
  {
    name: 'ship',
    glyph: '🚢',
    id: 930,
    img: 'Ship/Color/ship_color.svg',
  },
  {
    name: 'airplane',
    glyph: '✈️',
    id: 931,
    img: 'Airplane/Color/airplane_color.svg',
  },
  {
    name: 'small airplane',
    glyph: '🛩️',
    id: 932,
    img: 'Small airplane/Color/small_airplane_color.svg',
  },
  {
    name: 'airplane departure',
    glyph: '🛫',
    id: 933,
    img: 'Airplane departure/Color/airplane_departure_color.svg',
  },
  {
    name: 'airplane arrival',
    glyph: '🛬',
    id: 934,
    img: 'Airplane arrival/Color/airplane_arrival_color.svg',
  },
  {
    name: 'parachute',
    glyph: '🪂',
    id: 935,
    img: 'Parachute/Color/parachute_color.svg',
  },
  {
    name: 'seat',
    glyph: '💺',
    id: 936,
    img: 'Seat/Color/seat_color.svg',
  },
  {
    name: 'helicopter',
    glyph: '🚁',
    id: 937,
    img: 'Helicopter/Color/helicopter_color.svg',
  },
  {
    name: 'suspension railway',
    glyph: '🚟',
    id: 938,
    img: 'Suspension railway/Color/suspension_railway_color.svg',
  },
  {
    name: 'mountain cableway',
    glyph: '🚠',
    id: 939,
    img: 'Mountain cableway/Color/mountain_cableway_color.svg',
  },
  {
    name: 'aerial tramway',
    glyph: '🚡',
    id: 940,
    img: 'Aerial tramway/Color/aerial_tramway_color.svg',
  },
  {
    name: 'satellite',
    glyph: '🛰️',
    id: 941,
    img: 'Satellite/Color/satellite_color.svg',
  },
  {
    name: 'rocket',
    glyph: '🚀',
    id: 942,
    img: 'Rocket/Color/rocket_color.svg',
  },
  {
    name: 'flying saucer',
    glyph: '🛸',
    id: 943,
    img: 'Flying saucer/Color/flying_saucer_color.svg',
  },
  {
    name: 'bellhop bell',
    glyph: '🛎️',
    id: 944,
    img: 'Bellhop bell/Color/bellhop_bell_color.svg',
  },
  {
    name: 'luggage',
    glyph: '🧳',
    id: 945,
    img: 'Luggage/Color/luggage_color.svg',
  },
  {
    name: 'hourglass done',
    glyph: '⌛',
    id: 946,
    img: 'Hourglass done/Color/hourglass_done_color.svg',
  },
  {
    name: 'hourglass not done',
    glyph: '⏳',
    id: 947,
    img: 'Hourglass not done/Color/hourglass_not_done_color.svg',
  },
  {
    name: 'watch',
    glyph: '⌚',
    id: 948,
    img: 'Watch/Color/watch_color.svg',
  },
  {
    name: 'alarm clock',
    glyph: '⏰',
    id: 949,
    img: 'Alarm clock/Color/alarm_clock_color.svg',
  },
  {
    name: 'stopwatch',
    glyph: '⏱️',
    id: 950,
    img: 'Stopwatch/Color/stopwatch_color.svg',
  },
  {
    name: 'timer clock',
    glyph: '⏲️',
    id: 951,
    img: 'Timer clock/Color/timer_clock_color.svg',
  },
  {
    name: 'mantelpiece clock',
    glyph: '🕰️',
    id: 952,
    img: 'Mantelpiece clock/Color/mantelpiece_clock_color.svg',
  },
  {
    name: 'twelve o’clock',
    glyph: '🕛',
    id: 953,
    img: 'Twelve oclock/Color/twelve_oclock_color.svg',
  },
  {
    name: 'twelve-thirty',
    glyph: '🕧',
    id: 954,
    img: 'Twelve-thirty/Color/twelve-thirty_color.svg',
  },
  {
    name: 'one o’clock',
    glyph: '🕐',
    id: 955,
    img: 'One oclock/Color/one_oclock_color.svg',
  },
  {
    name: 'one-thirty',
    glyph: '🕜',
    id: 956,
    img: 'One-thirty/Color/one-thirty_color.svg',
  },
  {
    name: 'two o’clock',
    glyph: '🕑',
    id: 957,
    img: 'Two oclock/Color/two_oclock_color.svg',
  },
  {
    name: 'two-thirty',
    glyph: '🕝',
    id: 958,
    img: 'Two-thirty/Color/two-thirty_color.svg',
  },
  {
    name: 'three o’clock',
    glyph: '🕒',
    id: 959,
    img: 'Three oclock/Color/three_oclock_color.svg',
  },
  {
    name: 'three-thirty',
    glyph: '🕞',
    id: 960,
    img: 'Three-thirty/Color/three-thirty_color.svg',
  },
  {
    name: 'four o’clock',
    glyph: '🕓',
    id: 961,
    img: 'Four oclock/Color/four_oclock_color.svg',
  },
  {
    name: 'four-thirty',
    glyph: '🕟',
    id: 962,
    img: 'Four-thirty/Color/four-thirty_color.svg',
  },
  {
    name: 'five o’clock',
    glyph: '🕔',
    id: 963,
    img: 'Five oclock/Color/five_oclock_color.svg',
  },
  {
    name: 'five-thirty',
    glyph: '🕠',
    id: 964,
    img: 'Five-thirty/Color/five-thirty_color.svg',
  },
  {
    name: 'six o’clock',
    glyph: '🕕',
    id: 965,
    img: 'Six oclock/Color/six_oclock_color.svg',
  },
  {
    name: 'six-thirty',
    glyph: '🕡',
    id: 966,
    img: 'Six-thirty/Color/six-thirty_color.svg',
  },
  {
    name: 'seven o’clock',
    glyph: '🕖',
    id: 967,
    img: 'Seven oclock/Color/seven_oclock_color.svg',
  },
  {
    name: 'seven-thirty',
    glyph: '🕢',
    id: 968,
    img: 'Seven-thirty/Color/seven-thirty_color.svg',
  },
  {
    name: 'eight o’clock',
    glyph: '🕗',
    id: 969,
    img: 'Eight oclock/Color/eight_oclock_color.svg',
  },
  {
    name: 'eight-thirty',
    glyph: '🕣',
    id: 970,
    img: 'Eight-thirty/Color/eight-thirty_color.svg',
  },
  {
    name: 'nine o’clock',
    glyph: '🕘',
    id: 971,
    img: 'Nine oclock/Color/nine_oclock_color.svg',
  },
  {
    name: 'nine-thirty',
    glyph: '🕤',
    id: 972,
    img: 'Nine-thirty/Color/nine-thirty_color.svg',
  },
  {
    name: 'ten o’clock',
    glyph: '🕙',
    id: 973,
    img: 'Ten oclock/Color/ten_oclock_color.svg',
  },
  {
    name: 'ten-thirty',
    glyph: '🕥',
    id: 974,
    img: 'Ten-thirty/Color/ten-thirty_color.svg',
  },
  {
    name: 'eleven o’clock',
    glyph: '🕚',
    id: 975,
    img: 'Eleven oclock/Color/eleven_oclock_color.svg',
  },
  {
    name: 'eleven-thirty',
    glyph: '🕦',
    id: 976,
    img: 'Eleven-thirty/Color/eleven-thirty_color.svg',
  },
  {
    name: 'new moon',
    glyph: '🌑',
    id: 977,
    img: 'New moon/Color/new_moon_color.svg',
  },
  {
    name: 'waxing crescent moon',
    glyph: '🌒',
    id: 978,
    img: 'Waxing crescent moon/Color/waxing_crescent_moon_color.svg',
  },
  {
    name: 'first quarter moon',
    glyph: '🌓',
    id: 979,
    img: 'First quarter moon/Color/first_quarter_moon_color.svg',
  },
  {
    name: 'waxing gibbous moon',
    glyph: '🌔',
    id: 980,
    img: 'Waxing gibbous moon/Color/waxing_gibbous_moon_color.svg',
  },
  {
    name: 'full moon',
    glyph: '🌕',
    id: 981,
    img: 'Full moon/Color/full_moon_color.svg',
  },
  {
    name: 'waning gibbous moon',
    glyph: '🌖',
    id: 982,
    img: 'Waning gibbous moon/Color/waning_gibbous_moon_color.svg',
  },
  {
    name: 'last quarter moon',
    glyph: '🌗',
    id: 983,
    img: 'Last quarter moon/Color/last_quarter_moon_color.svg',
  },
  {
    name: 'waning crescent moon',
    glyph: '🌘',
    id: 984,
    img: 'Waning crescent moon/Color/waning_crescent_moon_color.svg',
  },
  {
    name: 'crescent moon',
    glyph: '🌙',
    id: 985,
    img: 'Crescent moon/Color/crescent_moon_color.svg',
  },
  {
    name: 'new moon face',
    glyph: '🌚',
    id: 986,
    img: 'New moon face/Color/new_moon_face_color.svg',
  },
  {
    name: 'first quarter moon face',
    glyph: '🌛',
    id: 987,
    img: 'First quarter moon face/Color/first_quarter_moon_face_color.svg',
  },
  {
    name: 'last quarter moon face',
    glyph: '🌜',
    id: 988,
    img: 'Last quarter moon face/Color/last_quarter_moon_face_color.svg',
  },
  {
    name: 'thermometer',
    glyph: '🌡️',
    id: 989,
    img: 'Thermometer/Color/thermometer_color.svg',
  },
  {
    name: 'sun',
    glyph: '☀️',
    id: 990,
    img: 'Sun/Color/sun_color.svg',
  },
  {
    name: 'full moon face',
    glyph: '🌝',
    id: 991,
    img: 'Full moon face/Color/full_moon_face_color.svg',
  },
  {
    name: 'sun with face',
    glyph: '🌞',
    id: 992,
    img: 'Sun with face/Color/sun_with_face_color.svg',
  },
  {
    name: 'ringed planet',
    glyph: '🪐',
    id: 993,
    img: 'Ringed planet/Color/ringed_planet_color.svg',
  },
  {
    name: 'star',
    glyph: '⭐',
    id: 994,
    img: 'Star/Color/star_color.svg',
  },
  {
    name: 'glowing star',
    glyph: '🌟',
    id: 995,
    img: 'Glowing star/Color/glowing_star_color.svg',
  },
  {
    name: 'shooting star',
    glyph: '🌠',
    id: 996,
    img: 'Shooting star/Color/shooting_star_color.svg',
  },
  {
    name: 'milky way',
    glyph: '🌌',
    id: 997,
    img: 'Milky way/Color/milky_way_color.svg',
  },
  {
    name: 'cloud',
    glyph: '☁️',
    id: 998,
    img: 'Cloud/Color/cloud_color.svg',
  },
  {
    name: 'sun behind cloud',
    glyph: '⛅',
    id: 999,
    img: 'Sun behind cloud/Color/sun_behind_cloud_color.svg',
  },
  {
    name: 'cloud with lightning and rain',
    glyph: '⛈️',
    id: 1000,
    img: 'Cloud with lightning and rain/Color/cloud_with_lightning_and_rain_color.svg',
  },
  {
    name: 'sun behind small cloud',
    glyph: '🌤️',
    id: 1001,
    img: 'Sun behind small cloud/Color/sun_behind_small_cloud_color.svg',
  },
  {
    name: 'sun behind large cloud',
    glyph: '🌥️',
    id: 1002,
    img: 'Sun behind large cloud/Color/sun_behind_large_cloud_color.svg',
  },
  {
    name: 'sun behind rain cloud',
    glyph: '🌦️',
    id: 1003,
    img: 'Sun behind rain cloud/Color/sun_behind_rain_cloud_color.svg',
  },
  {
    name: 'cloud with rain',
    glyph: '🌧️',
    id: 1004,
    img: 'Cloud with rain/Color/cloud_with_rain_color.svg',
  },
  {
    name: 'cloud with snow',
    glyph: '🌨️',
    id: 1005,
    img: 'Cloud with snow/Color/cloud_with_snow_color.svg',
  },
  {
    name: 'cloud with lightning',
    glyph: '🌩️',
    id: 1006,
    img: 'Cloud with lightning/Color/cloud_with_lightning_color.svg',
  },
  {
    name: 'tornado',
    glyph: '🌪️',
    id: 1007,
    img: 'Tornado/Color/tornado_color.svg',
  },
  {
    name: 'fog',
    glyph: '🌫️',
    id: 1008,
    img: 'Fog/Color/fog_color.svg',
  },
  {
    name: 'wind face',
    glyph: '🌬️',
    id: 1009,
    img: 'Wind face/Color/wind_face_color.svg',
  },
  {
    name: 'cyclone',
    glyph: '🌀',
    id: 1010,
    img: 'Cyclone/Color/cyclone_color.svg',
  },
  {
    name: 'rainbow',
    glyph: '🌈',
    id: 1011,
    img: 'Rainbow/Color/rainbow_color.svg',
  },
  {
    name: 'closed umbrella',
    glyph: '🌂',
    id: 1012,
    img: 'Closed umbrella/Color/closed_umbrella_color.svg',
  },
  {
    name: 'umbrella',
    glyph: '☂️',
    id: 1013,
    img: 'Umbrella/Color/umbrella_color.svg',
  },
  {
    name: 'umbrella with rain drops',
    glyph: '☔',
    id: 1014,
    img: 'Umbrella with rain drops/Color/umbrella_with_rain_drops_color.svg',
  },
  {
    name: 'umbrella on ground',
    glyph: '⛱️',
    id: 1015,
    img: 'Umbrella on ground/Color/umbrella_on_ground_color.svg',
  },
  {
    name: 'high voltage',
    glyph: '⚡',
    id: 1016,
    img: 'High voltage/Color/high_voltage_color.svg',
  },
  {
    name: 'snowflake',
    glyph: '❄️',
    id: 1017,
    img: 'Snowflake/Color/snowflake_color.svg',
  },
  {
    name: 'snowman',
    glyph: '☃️',
    id: 1018,
    img: 'Snowman/Color/snowman_color.svg',
  },
  {
    name: 'snowman without snow',
    glyph: '⛄',
    id: 1019,
    img: 'Snowman without snow/Color/snowman_without_snow_color.svg',
  },
  {
    name: 'comet',
    glyph: '☄️',
    id: 1020,
    img: 'Comet/Color/comet_color.svg',
  },
  {
    name: 'fire',
    glyph: '🔥',
    id: 1021,
    img: 'Fire/Color/fire_color.svg',
  },
  {
    name: 'droplet',
    glyph: '💧',
    id: 1022,
    img: 'Droplet/Color/droplet_color.svg',
  },
  {
    name: 'water wave',
    glyph: '🌊',
    id: 1023,
    img: 'Water wave/Color/water_wave_color.svg',
  },
  {
    name: 'jack-o-lantern',
    glyph: '🎃',
    id: 1024,
    img: 'Jack-o-lantern/Color/jack-o-lantern_color.svg',
  },
  {
    name: 'Christmas tree',
    glyph: '🎄',
    id: 1025,
    img: 'Christmas tree/Color/christmas_tree_color.svg',
  },
  {
    name: 'fireworks',
    glyph: '🎆',
    id: 1026,
    img: 'Fireworks/Color/fireworks_color.svg',
  },
  {
    name: 'sparkler',
    glyph: '🎇',
    id: 1027,
    img: 'Sparkler/Color/sparkler_color.svg',
  },
  {
    name: 'firecracker',
    glyph: '🧨',
    id: 1028,
    img: 'Firecracker/Color/firecracker_color.svg',
  },
  {
    name: 'sparkles',
    glyph: '✨',
    id: 1029,
    img: 'Sparkles/Color/sparkles_color.svg',
  },
  {
    name: 'balloon',
    glyph: '🎈',
    id: 1030,
    img: 'Balloon/Color/balloon_color.svg',
  },
  {
    name: 'party popper',
    glyph: '🎉',
    id: 1031,
    img: 'Party popper/Color/party_popper_color.svg',
  },
  {
    name: 'confetti ball',
    glyph: '🎊',
    id: 1032,
    img: 'Confetti ball/Color/confetti_ball_color.svg',
  },
  {
    name: 'tanabata tree',
    glyph: '🎋',
    id: 1033,
    img: 'Tanabata tree/Color/tanabata_tree_color.svg',
  },
  {
    name: 'pine decoration',
    glyph: '🎍',
    id: 1034,
    img: 'Pine decoration/Color/pine_decoration_color.svg',
  },
  {
    name: 'Japanese dolls',
    glyph: '🎎',
    id: 1035,
    img: 'Japanese dolls/Color/japanese_dolls_color.svg',
  },
  {
    name: 'carp streamer',
    glyph: '🎏',
    id: 1036,
    img: 'Carp streamer/Color/carp_streamer_color.svg',
  },
  {
    name: 'wind chime',
    glyph: '🎐',
    id: 1037,
    img: 'Wind chime/Color/wind_chime_color.svg',
  },
  {
    name: 'moon viewing ceremony',
    glyph: '🎑',
    id: 1038,
    img: 'Moon viewing ceremony/Color/moon_viewing_ceremony_color.svg',
  },
  {
    name: 'red envelope',
    glyph: '🧧',
    id: 1039,
    img: 'Red envelope/Color/red_envelope_color.svg',
  },
  {
    name: 'ribbon',
    glyph: '🎀',
    id: 1040,
    img: 'Ribbon/Color/ribbon_color.svg',
  },
  {
    name: 'wrapped gift',
    glyph: '🎁',
    id: 1041,
    img: 'Wrapped gift/Color/wrapped_gift_color.svg',
  },
  {
    name: 'reminder ribbon',
    glyph: '🎗️',
    id: 1042,
    img: 'Reminder ribbon/Color/reminder_ribbon_color.svg',
  },
  {
    name: 'admission tickets',
    glyph: '🎟️',
    id: 1043,
    img: 'Admission tickets/Color/admission_tickets_color.svg',
  },
  {
    name: 'ticket',
    glyph: '🎫',
    id: 1044,
    img: 'Ticket/Color/ticket_color.svg',
  },
  {
    name: 'military medal',
    glyph: '🎖️',
    id: 1045,
    img: 'Military medal/Color/military_medal_color.svg',
  },
  {
    name: 'trophy',
    glyph: '🏆',
    id: 1046,
    img: 'Trophy/Color/trophy_color.svg',
  },
  {
    name: 'sports medal',
    glyph: '🏅',
    id: 1047,
    img: 'Sports medal/Color/sports_medal_color.svg',
  },
  {
    name: '1st place medal',
    glyph: '🥇',
    id: 1048,
    img: '1st place medal/Color/1st_place_medal_color.svg',
  },
  {
    name: '2nd place medal',
    glyph: '🥈',
    id: 1049,
    img: '2nd place medal/Color/2nd_place_medal_color.svg',
  },
  {
    name: '3rd place medal',
    glyph: '🥉',
    id: 1050,
    img: '3rd place medal/Color/3rd_place_medal_color.svg',
  },
  {
    name: 'soccer ball',
    glyph: '⚽',
    id: 1051,
    img: 'Soccer ball/Color/soccer_ball_color.svg',
  },
  {
    name: 'baseball',
    glyph: '⚾',
    id: 1052,
    img: 'Baseball/Color/baseball_color.svg',
  },
  {
    name: 'softball',
    glyph: '🥎',
    id: 1053,
    img: 'Softball/Color/softball_color.svg',
  },
  {
    name: 'basketball',
    glyph: '🏀',
    id: 1054,
    img: 'Basketball/Color/basketball_color.svg',
  },
  {
    name: 'volleyball',
    glyph: '🏐',
    id: 1055,
    img: 'Volleyball/Color/volleyball_color.svg',
  },
  {
    name: 'american football',
    glyph: '🏈',
    id: 1056,
    img: 'American football/Color/american_football_color.svg',
  },
  {
    name: 'rugby football',
    glyph: '🏉',
    id: 1057,
    img: 'Rugby football/Color/rugby_football_color.svg',
  },
  {
    name: 'tennis',
    glyph: '🎾',
    id: 1058,
    img: 'Tennis/Color/tennis_color.svg',
  },
  {
    name: 'flying disc',
    glyph: '🥏',
    id: 1059,
    img: 'Flying disc/Color/flying_disc_color.svg',
  },
  {
    name: 'bowling',
    glyph: '🎳',
    id: 1060,
    img: 'Bowling/Color/bowling_color.svg',
  },
  {
    name: 'cricket game',
    glyph: '🏏',
    id: 1061,
    img: 'Cricket game/Color/cricket_game_color.svg',
  },
  {
    name: 'field hockey',
    glyph: '🏑',
    id: 1062,
    img: 'Field hockey/Color/field_hockey_color.svg',
  },
  {
    name: 'ice hockey',
    glyph: '🏒',
    id: 1063,
    img: 'Ice hockey/Color/ice_hockey_color.svg',
  },
  {
    name: 'lacrosse',
    glyph: '🥍',
    id: 1064,
    img: 'Lacrosse/Color/lacrosse_color.svg',
  },
  {
    name: 'ping pong',
    glyph: '🏓',
    id: 1065,
    img: 'Ping pong/Color/ping_pong_color.svg',
  },
  {
    name: 'badminton',
    glyph: '🏸',
    id: 1066,
    img: 'Badminton/Color/badminton_color.svg',
  },
  {
    name: 'boxing glove',
    glyph: '🥊',
    id: 1067,
    img: 'Boxing glove/Color/boxing_glove_color.svg',
  },
  {
    name: 'martial arts uniform',
    glyph: '🥋',
    id: 1068,
    img: 'Martial arts uniform/Color/martial_arts_uniform_color.svg',
  },
  {
    name: 'goal net',
    glyph: '🥅',
    id: 1069,
    img: 'Goal net/Color/goal_net_color.svg',
  },
  {
    name: 'flag in hole',
    glyph: '⛳',
    id: 1070,
    img: 'Flag in hole/Color/flag_in_hole_color.svg',
  },
  {
    name: 'ice skate',
    glyph: '⛸️',
    id: 1071,
    img: 'Ice skate/Color/ice_skate_color.svg',
  },
  {
    name: 'fishing pole',
    glyph: '🎣',
    id: 1072,
    img: 'Fishing pole/Color/fishing_pole_color.svg',
  },
  {
    name: 'diving mask',
    glyph: '🤿',
    id: 1073,
    img: 'Diving mask/Color/diving_mask_color.svg',
  },
  {
    name: 'running shirt',
    glyph: '🎽',
    id: 1074,
    img: 'Running shirt/Color/running_shirt_color.svg',
  },
  {
    name: 'skis',
    glyph: '🎿',
    id: 1075,
    img: 'Skis/Color/skis_color.svg',
  },
  {
    name: 'sled',
    glyph: '🛷',
    id: 1076,
    img: 'Sled/Color/sled_color.svg',
  },
  {
    name: 'curling stone',
    glyph: '🥌',
    id: 1077,
    img: 'Curling stone/Color/curling_stone_color.svg',
  },
  {
    name: 'bullseye',
    glyph: '🎯',
    id: 1078,
    img: 'Bullseye/Color/bullseye_color.svg',
  },
  {
    name: 'yo-yo',
    glyph: '🪀',
    id: 1079,
    img: 'Yo-yo/Color/yo-yo_color.svg',
  },
  {
    name: 'kite',
    glyph: '🪁',
    id: 1080,
    img: 'Kite/Color/kite_color.svg',
  },
  {
    name: 'pool 8 ball',
    glyph: '🎱',
    id: 1081,
    img: 'Pool 8 ball/Color/pool_8_ball_color.svg',
  },
  {
    name: 'crystal ball',
    glyph: '🔮',
    id: 1082,
    img: 'Crystal ball/Color/crystal_ball_color.svg',
  },
  {
    name: 'magic wand',
    glyph: '🪄',
    id: 1083,
    img: 'Magic wand/Color/magic_wand_color.svg',
  },
  {
    name: 'nazar amulet',
    glyph: '🧿',
    id: 1084,
    img: 'Nazar amulet/Color/nazar_amulet_color.svg',
  },
  {
    name: 'hamsa',
    glyph: '🪬',
    id: 1085,
    img: 'Hamsa/Color/hamsa_color.svg',
  },
  {
    name: 'joystick',
    glyph: '🕹️',
    id: 1087,
    img: 'Joystick/Color/joystick_color.svg',
  },
  {
    name: 'slot machine',
    glyph: '🎰',
    id: 1088,
    img: 'Slot machine/Color/slot_machine_color.svg',
  },
  {
    name: 'game die',
    glyph: '🎲',
    id: 1089,
    img: 'Game die/Color/game_die_color.svg',
  },
  {
    name: 'puzzle piece',
    glyph: '🧩',
    id: 1090,
    img: 'Puzzle piece/Color/puzzle_piece_color.svg',
  },
  {
    name: 'teddy bear',
    glyph: '🧸',
    id: 1091,
    img: 'Teddy bear/Color/teddy_bear_color.svg',
  },
  {
    name: 'piñata',
    glyph: '🪅',
    id: 1092,
    img: 'Piñata/Color/piñata_color.svg',
  },
  {
    name: 'mirror ball',
    glyph: '🪩',
    id: 1093,
    img: 'Mirror ball/Color/mirror_ball_color.svg',
  },
  {
    name: 'nesting dolls',
    glyph: '🪆',
    id: 1094,
    img: 'Nesting dolls/Color/nesting_dolls_color.svg',
  },
  {
    name: 'spade suit',
    glyph: '♠️',
    id: 1095,
    img: 'Spade suit/Color/spade_suit_color.svg',
  },
  {
    name: 'heart suit',
    glyph: '♥️',
    id: 1096,
    img: 'Heart suit/Color/heart_suit_color.svg',
  },
  {
    name: 'diamond suit',
    glyph: '♦️',
    id: 1097,
    img: 'Diamond suit/Color/diamond_suit_color.svg',
  },
  {
    name: 'club suit',
    glyph: '♣️',
    id: 1098,
    img: 'Club suit/Color/club_suit_color.svg',
  },
  {
    name: 'chess pawn',
    glyph: '♟️',
    id: 1099,
    img: 'Chess pawn/Color/chess_pawn_color.svg',
  },
  {
    name: 'joker',
    glyph: '🃏',
    id: 1100,
    img: 'Joker/Color/joker_color.svg',
  },
  {
    name: 'mahjong red dragon',
    glyph: '🀄',
    id: 1101,
    img: 'Mahjong red dragon/Color/mahjong_red_dragon_color.svg',
  },
  {
    name: 'flower playing cards',
    glyph: '🎴',
    id: 1102,
    img: 'Flower playing cards/Color/flower_playing_cards_color.svg',
  },
  {
    name: 'performing arts',
    glyph: '🎭',
    id: 1103,
    img: 'Performing arts/Color/performing_arts_color.svg',
  },
  {
    name: 'framed picture',
    glyph: '🖼️',
    id: 1104,
    img: 'Framed picture/Color/framed_picture_color.svg',
  },
  {
    name: 'artist palette',
    glyph: '🎨',
    id: 1105,
    img: 'Artist palette/Color/artist_palette_color.svg',
  },
  {
    name: 'thread',
    glyph: '🧵',
    id: 1106,
    img: 'Thread/Color/thread_color.svg',
  },
  {
    name: 'sewing needle',
    glyph: '🪡',
    id: 1107,
    img: 'Sewing needle/Color/sewing_needle_color.svg',
  },
  {
    name: 'yarn',
    glyph: '🧶',
    id: 1108,
    img: 'Yarn/Color/yarn_color.svg',
  },
  {
    name: 'knot',
    glyph: '🪢',
    id: 1109,
    img: 'Knot/Color/knot_color.svg',
  },
  {
    name: 'glasses',
    glyph: '👓',
    id: 1110,
    img: 'Glasses/Color/glasses_color.svg',
  },
  {
    name: 'sunglasses',
    glyph: '🕶️',
    id: 1111,
    img: 'Sunglasses/Color/sunglasses_color.svg',
  },
  {
    name: 'goggles',
    glyph: '🥽',
    id: 1112,
    img: 'Goggles/Color/goggles_color.svg',
  },
  {
    name: 'lab coat',
    glyph: '🥼',
    id: 1113,
    img: 'Lab coat/Color/lab_coat_color.svg',
  },
  {
    name: 'safety vest',
    glyph: '🦺',
    id: 1114,
    img: 'Safety vest/Color/safety_vest_color.svg',
  },
  {
    name: 'necktie',
    glyph: '👔',
    id: 1115,
    img: 'Necktie/Color/necktie_color.svg',
  },
  {
    name: 't-shirt',
    glyph: '👕',
    id: 1116,
    img: 'T-shirt/Color/t-shirt_color.svg',
  },
  {
    name: 'jeans',
    glyph: '👖',
    id: 1117,
    img: 'Jeans/Color/jeans_color.svg',
  },
  {
    name: 'scarf',
    glyph: '🧣',
    id: 1118,
    img: 'Scarf/Color/scarf_color.svg',
  },
  {
    name: 'gloves',
    glyph: '🧤',
    id: 1119,
    img: 'Gloves/Color/gloves_color.svg',
  },
  {
    name: 'coat',
    glyph: '🧥',
    id: 1120,
    img: 'Coat/Color/coat_color.svg',
  },
  {
    name: 'socks',
    glyph: '🧦',
    id: 1121,
    img: 'Socks/Color/socks_color.svg',
  },
  {
    name: 'dress',
    glyph: '👗',
    id: 1122,
    img: 'Dress/Color/dress_color.svg',
  },
  {
    name: 'kimono',
    glyph: '👘',
    id: 1123,
    img: 'Kimono/Color/kimono_color.svg',
  },
  {
    name: 'sari',
    glyph: '🥻',
    id: 1124,
    img: 'Sari/Color/sari_color.svg',
  },
  {
    name: 'one-piece swimsuit',
    glyph: '🩱',
    id: 1125,
    img: 'One-piece swimsuit/Color/one-piece_swimsuit_color.svg',
  },
  {
    name: 'briefs',
    glyph: '🩲',
    id: 1126,
    img: 'Briefs/Color/briefs_color.svg',
  },
  {
    name: 'shorts',
    glyph: '🩳',
    id: 1127,
    img: 'Shorts/Color/shorts_color.svg',
  },
  {
    name: 'bikini',
    glyph: '👙',
    id: 1128,
    img: 'Bikini/Color/bikini_color.svg',
  },
  {
    name: 'woman’s clothes',
    glyph: '👚',
    id: 1129,
    img: 'Womans clothes/Color/womans_clothes_color.svg',
  },
  {
    name: 'purse',
    glyph: '👛',
    id: 1130,
    img: 'Purse/Color/purse_color.svg',
  },
  {
    name: 'handbag',
    glyph: '👜',
    id: 1131,
    img: 'Handbag/Color/handbag_color.svg',
  },
  {
    name: 'clutch bag',
    glyph: '👝',
    id: 1132,
    img: 'Clutch bag/Color/clutch_bag_color.svg',
  },
  {
    name: 'shopping bags',
    glyph: '🛍️',
    id: 1133,
    img: 'Shopping bags/Color/shopping_bags_color.svg',
  },
  {
    name: 'backpack',
    glyph: '🎒',
    id: 1134,
    img: 'Backpack/Color/backpack_color.svg',
  },
  {
    name: 'thong sandal',
    glyph: '🩴',
    id: 1135,
    img: 'Thong sandal/Color/thong_sandal_color.svg',
  },
  {
    name: 'man’s shoe',
    glyph: '👞',
    id: 1136,
    img: 'Mans shoe/Color/mans_shoe_color.svg',
  },
  {
    name: 'running shoe',
    glyph: '👟',
    id: 1137,
    img: 'Running shoe/Color/running_shoe_color.svg',
  },
  {
    name: 'hiking boot',
    glyph: '🥾',
    id: 1138,
    img: 'Hiking boot/Color/hiking_boot_color.svg',
  },
  {
    name: 'flat shoe',
    glyph: '🥿',
    id: 1139,
    img: 'Flat shoe/Color/flat_shoe_color.svg',
  },
  {
    name: 'high-heeled shoe',
    glyph: '👠',
    id: 1140,
    img: 'High-heeled shoe/Color/high-heeled_shoe_color.svg',
  },
  {
    name: 'woman’s sandal',
    glyph: '👡',
    id: 1141,
    img: 'Womans sandal/Color/womans_sandal_color.svg',
  },
  {
    name: 'ballet shoes',
    glyph: '🩰',
    id: 1142,
    img: 'Ballet shoes/Color/ballet_shoes_color.svg',
  },
  {
    name: 'woman’s boot',
    glyph: '👢',
    id: 1143,
    img: 'Womans boot/Color/womans_boot_color.svg',
  },
  {
    name: 'crown',
    glyph: '👑',
    id: 1144,
    img: 'Crown/Color/crown_color.svg',
  },
  {
    name: 'woman’s hat',
    glyph: '👒',
    id: 1145,
    img: 'Womans hat/Color/womans_hat_color.svg',
  },
  {
    name: 'top hat',
    glyph: '🎩',
    id: 1146,
    img: 'Top hat/Color/top_hat_color.svg',
  },
  {
    name: 'graduation cap',
    glyph: '🎓',
    id: 1147,
    img: 'Graduation cap/Color/graduation_cap_color.svg',
  },
  {
    name: 'billed cap',
    glyph: '🧢',
    id: 1148,
    img: 'Billed cap/Color/billed_cap_color.svg',
  },
  {
    name: 'military helmet',
    glyph: '🪖',
    id: 1149,
    img: 'Military helmet/Color/military_helmet_color.svg',
  },
  {
    name: 'rescue worker’s helmet',
    glyph: '⛑️',
    id: 1150,
    img: 'Rescue workers helmet/Color/rescue_workers_helmet_color.svg',
  },
  {
    name: 'prayer beads',
    glyph: '📿',
    id: 1151,
    img: 'Prayer beads/Color/prayer_beads_color.svg',
  },
  {
    name: 'lipstick',
    glyph: '💄',
    id: 1152,
    img: 'Lipstick/Color/lipstick_color.svg',
  },
  {
    name: 'ring',
    glyph: '💍',
    id: 1153,
    img: 'Ring/Color/ring_color.svg',
  },
  {
    name: 'gem stone',
    glyph: '💎',
    id: 1154,
    img: 'Gem stone/Color/gem_stone_color.svg',
  },
  {
    name: 'muted speaker',
    glyph: '🔇',
    id: 1155,
    img: 'Muted speaker/Color/muted_speaker_color.svg',
  },
  {
    name: 'speaker low volume',
    glyph: '🔈',
    id: 1156,
    img: 'Speaker low volume/Color/speaker_low_volume_color.svg',
  },
  {
    name: 'speaker medium volume',
    glyph: '🔉',
    id: 1157,
    img: 'Speaker medium volume/Color/speaker_medium_volume_color.svg',
  },
  {
    name: 'speaker high volume',
    glyph: '🔊',
    id: 1158,
    img: 'Speaker high volume/Color/speaker_high_volume_color.svg',
  },
  {
    name: 'loudspeaker',
    glyph: '📢',
    id: 1159,
    img: 'Loudspeaker/Color/loudspeaker_color.svg',
  },
  {
    name: 'megaphone',
    glyph: '📣',
    id: 1160,
    img: 'Megaphone/Color/megaphone_color.svg',
  },
  {
    name: 'postal horn',
    glyph: '📯',
    id: 1161,
    img: 'Postal horn/Color/postal_horn_color.svg',
  },
  {
    name: 'bell',
    glyph: '🔔',
    id: 1162,
    img: 'Bell/Color/bell_color.svg',
  },
  {
    name: 'bell with slash',
    glyph: '🔕',
    id: 1163,
    img: 'Bell with slash/Color/bell_with_slash_color.svg',
  },
  {
    name: 'musical score',
    glyph: '🎼',
    id: 1164,
    img: 'Musical score/Color/musical_score_color.svg',
  },
  {
    name: 'musical note',
    glyph: '🎵',
    id: 1165,
    img: 'Musical note/Color/musical_note_color.svg',
  },
  {
    name: 'musical notes',
    glyph: '🎶',
    id: 1166,
    img: 'Musical notes/Color/musical_notes_color.svg',
  },
  {
    name: 'studio microphone',
    glyph: '🎙️',
    id: 1167,
    img: 'Studio microphone/Color/studio_microphone_color.svg',
  },
  {
    name: 'level slider',
    glyph: '🎚️',
    id: 1168,
    img: 'Level slider/Color/level_slider_color.svg',
  },
  {
    name: 'control knobs',
    glyph: '🎛️',
    id: 1169,
    img: 'Control knobs/Color/control_knobs_color.svg',
  },
  {
    name: 'microphone',
    glyph: '🎤',
    id: 1170,
    img: 'Microphone/Color/microphone_color.svg',
  },
  {
    name: 'headphone',
    glyph: '🎧',
    id: 1171,
    img: 'Headphone/Color/headphone_color.svg',
  },
  {
    name: 'radio',
    glyph: '📻',
    id: 1172,
    img: 'Radio/Color/radio_color.svg',
  },
  {
    name: 'saxophone',
    glyph: '🎷',
    id: 1173,
    img: 'Saxophone/Color/saxophone_color.svg',
  },
  {
    name: 'accordion',
    glyph: '🪗',
    id: 1174,
    img: 'Accordion/Color/accordion_color.svg',
  },
  {
    name: 'guitar',
    glyph: '🎸',
    id: 1175,
    img: 'Guitar/Color/guitar_color.svg',
  },
  {
    name: 'musical keyboard',
    glyph: '🎹',
    id: 1176,
    img: 'Musical keyboard/Color/musical_keyboard_color.svg',
  },
  {
    name: 'trumpet',
    glyph: '🎺',
    id: 1177,
    img: 'Trumpet/Color/trumpet_color.svg',
  },
  {
    name: 'violin',
    glyph: '🎻',
    id: 1178,
    img: 'Violin/Color/violin_color.svg',
  },
  {
    name: 'banjo',
    glyph: '🪕',
    id: 1179,
    img: 'Banjo/Color/banjo_color.svg',
  },
  {
    name: 'drum',
    glyph: '🥁',
    id: 1180,
    img: 'Drum/Color/drum_color.svg',
  },
  {
    name: 'long drum',
    glyph: '🪘',
    id: 1181,
    img: 'Long drum/Color/long_drum_color.svg',
  },
  {
    name: 'mobile phone',
    glyph: '📱',
    id: 1182,
    img: 'Mobile phone/Color/mobile_phone_color.svg',
  },
  {
    name: 'mobile phone with arrow',
    glyph: '📲',
    id: 1183,
    img: 'Mobile phone with arrow/Color/mobile_phone_with_arrow_color.svg',
  },
  {
    name: 'telephone',
    glyph: '☎️',
    id: 1184,
    img: 'Telephone/Color/telephone_color.svg',
  },
  {
    name: 'telephone receiver',
    glyph: '📞',
    id: 1185,
    img: 'Telephone receiver/Color/telephone_receiver_color.svg',
  },
  {
    name: 'pager',
    glyph: '📟',
    id: 1186,
    img: 'Pager/Color/pager_color.svg',
  },
  {
    name: 'fax machine',
    glyph: '📠',
    id: 1187,
    img: 'Fax machine/Color/fax_machine_color.svg',
  },
  {
    name: 'battery',
    glyph: '🔋',
    id: 1188,
    img: 'Battery/Color/battery_color.svg',
  },
  {
    name: 'low battery',
    glyph: '🪫',
    id: 1189,
    img: 'Low battery/Color/low_battery_color.svg',
  },
  {
    name: 'electric plug',
    glyph: '🔌',
    id: 1190,
    img: 'Electric plug/Color/electric_plug_color.svg',
  },
  {
    name: 'laptop',
    glyph: '💻',
    id: 1191,
    img: 'Laptop/Color/laptop_color.svg',
  },
  {
    name: 'desktop computer',
    glyph: '🖥️',
    id: 1192,
    img: 'Desktop computer/Color/desktop_computer_color.svg',
  },
  {
    name: 'printer',
    glyph: '🖨️',
    id: 1193,
    img: 'Printer/Color/printer_color.svg',
  },
  {
    name: 'keyboard',
    glyph: '⌨️',
    id: 1194,
    img: 'Keyboard/Color/keyboard_color.svg',
  },
  {
    name: 'computer mouse',
    glyph: '🖱️',
    id: 1195,
    img: 'Computer mouse/Color/computer_mouse_color.svg',
  },
  {
    name: 'trackball',
    glyph: '🖲️',
    id: 1196,
    img: 'Trackball/Color/trackball_color.svg',
  },
  {
    name: 'computer disk',
    glyph: '💽',
    id: 1197,
    img: 'Computer disk/Color/computer_disk_color.svg',
  },
  {
    name: 'floppy disk',
    glyph: '💾',
    id: 1198,
    img: 'Floppy disk/Color/floppy_disk_color.svg',
  },
  {
    name: 'optical disk',
    glyph: '💿',
    id: 1199,
    img: 'Optical disk/Color/optical_disk_color.svg',
  },
  {
    name: 'dvd',
    glyph: '📀',
    id: 1200,
    img: 'Dvd/Color/dvd_color.svg',
  },
  {
    name: 'abacus',
    glyph: '🧮',
    id: 1201,
    img: 'Abacus/Color/abacus_color.svg',
  },
  {
    name: 'movie camera',
    glyph: '🎥',
    id: 1202,
    img: 'Movie camera/Color/movie_camera_color.svg',
  },
  {
    name: 'film frames',
    glyph: '🎞️',
    id: 1203,
    img: 'Film frames/Color/film_frames_color.svg',
  },
  {
    name: 'film projector',
    glyph: '📽️',
    id: 1204,
    img: 'Film projector/Color/film_projector_color.svg',
  },
  {
    name: 'clapper board',
    glyph: '🎬',
    id: 1205,
    img: 'Clapper board/Color/clapper_board_color.svg',
  },
  {
    name: 'television',
    glyph: '📺',
    id: 1206,
    img: 'Television/Color/television_color.svg',
  },
  {
    name: 'camera',
    glyph: '📷',
    id: 1207,
    img: 'Camera/Color/camera_color.svg',
  },
  {
    name: 'camera with flash',
    glyph: '📸',
    id: 1208,
    img: 'Camera with flash/Color/camera_with_flash_color.svg',
  },
  {
    name: 'video camera',
    glyph: '📹',
    id: 1209,
    img: 'Video camera/Color/video_camera_color.svg',
  },
  {
    name: 'videocassette',
    glyph: '📼',
    id: 1210,
    img: 'Videocassette/Color/videocassette_color.svg',
  },
  {
    name: 'magnifying glass tilted left',
    glyph: '🔍',
    id: 1211,
    img: 'Magnifying glass tilted left/Color/magnifying_glass_tilted_left_color.svg',
  },
  {
    name: 'magnifying glass tilted right',
    glyph: '🔎',
    id: 1212,
    img: 'Magnifying glass tilted right/Color/magnifying_glass_tilted_right_color.svg',
  },
  {
    name: 'candle',
    glyph: '🕯️',
    id: 1213,
    img: 'Candle/Color/candle_color.svg',
  },
  {
    name: 'light bulb',
    glyph: '💡',
    id: 1214,
    img: 'Light bulb/Color/light_bulb_color.svg',
  },
  {
    name: 'flashlight',
    glyph: '🔦',
    id: 1215,
    img: 'Flashlight/Color/flashlight_color.svg',
  },
  {
    name: 'red paper lantern',
    glyph: '🏮',
    id: 1216,
    img: 'Red paper lantern/Color/red_paper_lantern_color.svg',
  },
  {
    name: 'diya lamp',
    glyph: '🪔',
    id: 1217,
    img: 'Diya lamp/Color/diya_lamp_color.svg',
  },
  {
    name: 'notebook with decorative cover',
    glyph: '📔',
    id: 1218,
    img: 'Notebook with decorative cover/Color/notebook_with_decorative_cover_color.svg',
  },
  {
    name: 'closed book',
    glyph: '📕',
    id: 1219,
    img: 'Closed book/Color/closed_book_color.svg',
  },
  {
    name: 'open book',
    glyph: '📖',
    id: 1220,
    img: 'Open book/Color/open_book_color.svg',
  },
  {
    name: 'green book',
    glyph: '📗',
    id: 1221,
    img: 'Green book/Color/green_book_color.svg',
  },
  {
    name: 'blue book',
    glyph: '📘',
    id: 1222,
    img: 'Blue book/Color/blue_book_color.svg',
  },
  {
    name: 'orange book',
    glyph: '📙',
    id: 1223,
    img: 'Orange book/Color/orange_book_color.svg',
  },
  {
    name: 'books',
    glyph: '📚',
    id: 1224,
    img: 'Books/Color/books_color.svg',
  },
  {
    name: 'notebook',
    glyph: '📓',
    id: 1225,
    img: 'Notebook/Color/notebook_color.svg',
  },
  {
    name: 'ledger',
    glyph: '📒',
    id: 1226,
    img: 'Ledger/Color/ledger_color.svg',
  },
  {
    name: 'page with curl',
    glyph: '📃',
    id: 1227,
    img: 'Page with curl/Color/page_with_curl_color.svg',
  },
  {
    name: 'scroll',
    glyph: '📜',
    id: 1228,
    img: 'Scroll/Color/scroll_color.svg',
  },
  {
    name: 'page facing up',
    glyph: '📄',
    id: 1229,
    img: 'Page facing up/Color/page_facing_up_color.svg',
  },
  {
    name: 'newspaper',
    glyph: '📰',
    id: 1230,
    img: 'Newspaper/Color/newspaper_color.svg',
  },
  {
    name: 'rolled-up newspaper',
    glyph: '🗞️',
    id: 1231,
    img: 'Rolled-up newspaper/Color/rolled-up_newspaper_color.svg',
  },
  {
    name: 'bookmark tabs',
    glyph: '📑',
    id: 1232,
    img: 'Bookmark tabs/Color/bookmark_tabs_color.svg',
  },
  {
    name: 'bookmark',
    glyph: '🔖',
    id: 1233,
    img: 'Bookmark/Color/bookmark_color.svg',
  },
  {
    name: 'label',
    glyph: '🏷️',
    id: 1234,
    img: 'Label/Color/label_color.svg',
  },
  {
    name: 'money bag',
    glyph: '💰',
    id: 1235,
    img: 'Money bag/Color/money_bag_color.svg',
  },
  {
    name: 'coin',
    glyph: '🪙',
    id: 1236,
    img: 'Coin/Color/coin_color.svg',
  },
  {
    name: 'yen banknote',
    glyph: '💴',
    id: 1237,
    img: 'Yen banknote/Color/yen_banknote_color.svg',
  },
  {
    name: 'dollar banknote',
    glyph: '💵',
    id: 1238,
    img: 'Dollar banknote/Color/dollar_banknote_color.svg',
  },
  {
    name: 'euro banknote',
    glyph: '💶',
    id: 1239,
    img: 'Euro banknote/Color/euro_banknote_color.svg',
  },
  {
    name: 'pound banknote',
    glyph: '💷',
    id: 1240,
    img: 'Pound banknote/Color/pound_banknote_color.svg',
  },
  {
    name: 'money with wings',
    glyph: '💸',
    id: 1241,
    img: 'Money with wings/Color/money_with_wings_color.svg',
  },
  {
    name: 'credit card',
    glyph: '💳',
    id: 1242,
    img: 'Credit card/Color/credit_card_color.svg',
  },
  {
    name: 'receipt',
    glyph: '🧾',
    id: 1243,
    img: 'Receipt/Color/receipt_color.svg',
  },
  {
    name: 'chart increasing with yen',
    glyph: '💹',
    id: 1244,
    img: 'Chart increasing with yen/Color/chart_increasing_with_yen_color.svg',
  },
  {
    name: 'envelope',
    glyph: '✉️',
    id: 1245,
    img: 'Envelope/Color/envelope_color.svg',
  },
  {
    name: 'e-mail',
    glyph: '📧',
    id: 1246,
    img: 'E-mail/Color/e-mail_color.svg',
  },
  {
    name: 'incoming envelope',
    glyph: '📨',
    id: 1247,
    img: 'Incoming envelope/Color/incoming_envelope_color.svg',
  },
  {
    name: 'envelope with arrow',
    glyph: '📩',
    id: 1248,
    img: 'Envelope with arrow/Color/envelope_with_arrow_color.svg',
  },
  {
    name: 'outbox tray',
    glyph: '📤',
    id: 1249,
    img: 'Outbox tray/Color/outbox_tray_color.svg',
  },
  {
    name: 'inbox tray',
    glyph: '📥',
    id: 1250,
    img: 'Inbox tray/Color/inbox_tray_color.svg',
  },
  {
    name: 'package',
    glyph: '📦',
    id: 1251,
    img: 'Package/Color/package_color.svg',
  },
  {
    name: 'closed mailbox with raised flag',
    glyph: '📫',
    id: 1252,
    img: 'Closed mailbox with raised flag/Color/closed_mailbox_with_raised_flag_color.svg',
  },
  {
    name: 'closed mailbox with lowered flag',
    glyph: '📪',
    id: 1253,
    img: 'Closed mailbox with lowered flag/Color/closed_mailbox_with_lowered_flag_color.svg',
  },
  {
    name: 'open mailbox with raised flag',
    glyph: '📬',
    id: 1254,
    img: 'Open mailbox with raised flag/Color/open_mailbox_with_raised_flag_color.svg',
  },
  {
    name: 'open mailbox with lowered flag',
    glyph: '📭',
    id: 1255,
    img: 'Open mailbox with lowered flag/Color/open_mailbox_with_lowered_flag_color.svg',
  },
  {
    name: 'postbox',
    glyph: '📮',
    id: 1256,
    img: 'Postbox/Color/postbox_color.svg',
  },
  {
    name: 'ballot box with ballot',
    glyph: '🗳️',
    id: 1257,
    img: 'Ballot box with ballot/Color/ballot_box_with_ballot_color.svg',
  },
  {
    name: 'pencil',
    glyph: '✏️',
    id: 1258,
    img: 'Pencil/Color/pencil_color.svg',
  },
  {
    name: 'black nib',
    glyph: '✒️',
    id: 1259,
    img: 'Black nib/Color/black_nib_color.svg',
  },
  {
    name: 'fountain pen',
    glyph: '🖋️',
    id: 1260,
    img: 'Fountain pen/Color/fountain_pen_color.svg',
  },
  {
    name: 'pen',
    glyph: '🖊️',
    id: 1261,
    img: 'Pen/Color/pen_color.svg',
  },
  {
    name: 'paintbrush',
    glyph: '🖌️',
    id: 1262,
    img: 'Paintbrush/Color/paintbrush_color.svg',
  },
  {
    name: 'crayon',
    glyph: '🖍️',
    id: 1263,
    img: 'Crayon/Color/crayon_color.svg',
  },
  {
    name: 'memo',
    glyph: '📝',
    id: 1264,
    img: 'Memo/Color/memo_color.svg',
  },
  {
    name: 'briefcase',
    glyph: '💼',
    id: 1265,
    img: 'Briefcase/Color/briefcase_color.svg',
  },
  {
    name: 'file folder',
    glyph: '📁',
    id: 1266,
    img: 'File folder/Color/file_folder_color.svg',
  },
  {
    name: 'open file folder',
    glyph: '📂',
    id: 1267,
    img: 'Open file folder/Color/open_file_folder_color.svg',
  },
  {
    name: 'card index dividers',
    glyph: '🗂️',
    id: 1268,
    img: 'Card index dividers/Color/card_index_dividers_color.svg',
  },
  {
    name: 'calendar',
    glyph: '📅',
    id: 1269,
    img: 'Calendar/Color/calendar_color.svg',
  },
  {
    name: 'tear-off calendar',
    glyph: '📆',
    id: 1270,
    img: 'Tear-off calendar/Color/tear-off_calendar_color.svg',
  },
  {
    name: 'spiral notepad',
    glyph: '🗒️',
    id: 1271,
    img: 'Spiral notepad/Color/spiral_notepad_color.svg',
  },
  {
    name: 'spiral calendar',
    glyph: '🗓️',
    id: 1272,
    img: 'Spiral calendar/Color/spiral_calendar_color.svg',
  },
  {
    name: 'card index',
    glyph: '📇',
    id: 1273,
    img: 'Card index/Color/card_index_color.svg',
  },
  {
    name: 'chart increasing',
    glyph: '📈',
    id: 1274,
    img: 'Chart increasing/Color/chart_increasing_color.svg',
  },
  {
    name: 'chart decreasing',
    glyph: '📉',
    id: 1275,
    img: 'Chart decreasing/Color/chart_decreasing_color.svg',
  },
  {
    name: 'bar chart',
    glyph: '📊',
    id: 1276,
    img: 'Bar chart/Color/bar_chart_color.svg',
  },
  {
    name: 'clipboard',
    glyph: '📋',
    id: 1277,
    img: 'Clipboard/Color/clipboard_color.svg',
  },
  {
    name: 'pushpin',
    glyph: '📌',
    id: 1278,
    img: 'Pushpin/Color/pushpin_color.svg',
  },
  {
    name: 'round pushpin',
    glyph: '📍',
    id: 1279,
    img: 'Round pushpin/Color/round_pushpin_color.svg',
  },
  {
    name: 'linked paperclips',
    glyph: '🖇️',
    id: 1281,
    img: 'Linked paperclips/Color/linked_paperclips_color.svg',
  },
  {
    name: 'straight ruler',
    glyph: '📏',
    id: 1282,
    img: 'Straight ruler/Color/straight_ruler_color.svg',
  },
  {
    name: 'triangular ruler',
    glyph: '📐',
    id: 1283,
    img: 'Triangular ruler/Color/triangular_ruler_color.svg',
  },
  {
    name: 'scissors',
    glyph: '✂️',
    id: 1284,
    img: 'Scissors/Color/scissors_color.svg',
  },
  {
    name: 'card file box',
    glyph: '🗃️',
    id: 1285,
    img: 'Card file box/Color/card_file_box_color.svg',
  },
  {
    name: 'file cabinet',
    glyph: '🗄️',
    id: 1286,
    img: 'File cabinet/Color/file_cabinet_color.svg',
  },
  {
    name: 'wastebasket',
    glyph: '🗑️',
    id: 1287,
    img: 'Wastebasket/Color/wastebasket_color.svg',
  },
  {
    name: 'locked',
    glyph: '🔒',
    id: 1288,
    img: 'Locked/Color/locked_color.svg',
  },
  {
    name: 'unlocked',
    glyph: '🔓',
    id: 1289,
    img: 'Unlocked/Color/unlocked_color.svg',
  },
  {
    name: 'locked with pen',
    glyph: '🔏',
    id: 1290,
    img: 'Locked with pen/Color/locked_with_pen_color.svg',
  },
  {
    name: 'locked with key',
    glyph: '🔐',
    id: 1291,
    img: 'Locked with key/Color/locked_with_key_color.svg',
  },
  {
    name: 'key',
    glyph: '🔑',
    id: 1292,
    img: 'Key/Color/key_color.svg',
  },
  {
    name: 'old key',
    glyph: '🗝️',
    id: 1293,
    img: 'Old key/Color/old_key_color.svg',
  },
  {
    name: 'hammer',
    glyph: '🔨',
    id: 1294,
    img: 'Hammer/Color/hammer_color.svg',
  },
  {
    name: 'axe',
    glyph: '🪓',
    id: 1295,
    img: 'Axe/Color/axe_color.svg',
  },
  {
    name: 'pick',
    glyph: '⛏️',
    id: 1296,
    img: 'Pick/Color/pick_color.svg',
  },
  {
    name: 'hammer and pick',
    glyph: '⚒️',
    id: 1297,
    img: 'Hammer and pick/Color/hammer_and_pick_color.svg',
  },
  {
    name: 'hammer and wrench',
    glyph: '🛠️',
    id: 1298,
    img: 'Hammer and wrench/Color/hammer_and_wrench_color.svg',
  },
  {
    name: 'dagger',
    glyph: '🗡️',
    id: 1299,
    img: 'Dagger/Color/dagger_color.svg',
  },
  {
    name: 'crossed swords',
    glyph: '⚔️',
    id: 1300,
    img: 'Crossed swords/Color/crossed_swords_color.svg',
  },
  {
    name: 'water pistol',
    glyph: '🔫',
    id: 1301,
    img: 'Water pistol/Color/water_pistol_color.svg',
  },
  {
    name: 'boomerang',
    glyph: '🪃',
    id: 1302,
    img: 'Boomerang/Color/boomerang_color.svg',
  },
  {
    name: 'bow and arrow',
    glyph: '🏹',
    id: 1303,
    img: 'Bow and arrow/Color/bow_and_arrow_color.svg',
  },
  {
    name: 'shield',
    glyph: '🛡️',
    id: 1304,
    img: 'Shield/Color/shield_color.svg',
  },
  {
    name: 'carpentry saw',
    glyph: '🪚',
    id: 1305,
    img: 'Carpentry saw/Color/carpentry_saw_color.svg',
  },
  {
    name: 'wrench',
    glyph: '🔧',
    id: 1306,
    img: 'Wrench/Color/wrench_color.svg',
  },
  {
    name: 'screwdriver',
    glyph: '🪛',
    id: 1307,
    img: 'Screwdriver/Color/screwdriver_color.svg',
  },
  {
    name: 'nut and bolt',
    glyph: '🔩',
    id: 1308,
    img: 'Nut and bolt/Color/nut_and_bolt_color.svg',
  },
  {
    name: 'gear',
    glyph: '⚙️',
    id: 1309,
    img: 'Gear/Color/gear_color.svg',
  },
  {
    name: 'clamp',
    glyph: '🗜️',
    id: 1310,
    img: 'Clamp/Color/clamp_color.svg',
  },
  {
    name: 'balance scale',
    glyph: '⚖️',
    id: 1311,
    img: 'Balance scale/Color/balance_scale_color.svg',
  },
  {
    name: 'white cane',
    glyph: '🦯',
    id: 1312,
    img: 'White cane/Color/white_cane_color.svg',
  },
  {
    name: 'link',
    glyph: '🔗',
    id: 1313,
    img: 'Link/Color/link_color.svg',
  },
  {
    name: 'chains',
    glyph: '⛓️',
    id: 1314,
    img: 'Chains/Color/chains_color.svg',
  },
  {
    name: 'hook',
    glyph: '🪝',
    id: 1315,
    img: 'Hook/Color/hook_color.svg',
  },
  {
    name: 'toolbox',
    glyph: '🧰',
    id: 1316,
    img: 'Toolbox/Color/toolbox_color.svg',
  },
  {
    name: 'magnet',
    glyph: '🧲',
    id: 1317,
    img: 'Magnet/Color/magnet_color.svg',
  },
  {
    name: 'ladder',
    glyph: '🪜',
    id: 1318,
    img: 'Ladder/Color/ladder_color.svg',
  },
  {
    name: 'alembic',
    glyph: '⚗️',
    id: 1319,
    img: 'Alembic/Color/alembic_color.svg',
  },
  {
    name: 'test tube',
    glyph: '🧪',
    id: 1320,
    img: 'Test tube/Color/test_tube_color.svg',
  },
  {
    name: 'petri dish',
    glyph: '🧫',
    id: 1321,
    img: 'Petri dish/Color/petri_dish_color.svg',
  },
  {
    name: 'dna',
    glyph: '🧬',
    id: 1322,
    img: 'Dna/Color/dna_color.svg',
  },
  {
    name: 'microscope',
    glyph: '🔬',
    id: 1323,
    img: 'Microscope/Color/microscope_color.svg',
  },
  {
    name: 'telescope',
    glyph: '🔭',
    id: 1324,
    img: 'Telescope/Color/telescope_color.svg',
  },
  {
    name: 'satellite antenna',
    glyph: '📡',
    id: 1325,
    img: 'Satellite antenna/Color/satellite_antenna_color.svg',
  },
  {
    name: 'syringe',
    glyph: '💉',
    id: 1326,
    img: 'Syringe/Color/syringe_color.svg',
  },
  {
    name: 'drop of blood',
    glyph: '🩸',
    id: 1327,
    img: 'Drop of blood/Color/drop_of_blood_color.svg',
  },
  {
    name: 'pill',
    glyph: '💊',
    id: 1328,
    img: 'Pill/Color/pill_color.svg',
  },
  {
    name: 'adhesive bandage',
    glyph: '🩹',
    id: 1329,
    img: 'Adhesive bandage/Color/adhesive_bandage_color.svg',
  },
  {
    name: 'crutch',
    glyph: '🩼',
    id: 1330,
    img: 'Crutch/Color/crutch_color.svg',
  },
  {
    name: 'stethoscope',
    glyph: '🩺',
    id: 1331,
    img: 'Stethoscope/Color/stethoscope_color.svg',
  },
  {
    name: 'x-ray',
    glyph: '🩻',
    id: 1332,
    img: 'X-ray/Color/x-ray_color.svg',
  },
  {
    name: 'door',
    glyph: '🚪',
    id: 1333,
    img: 'Door/Color/door_color.svg',
  },
  {
    name: 'elevator',
    glyph: '🛗',
    id: 1334,
    img: 'Elevator/Color/elevator_color.svg',
  },
  {
    name: 'mirror',
    glyph: '🪞',
    id: 1335,
    img: 'Mirror/Color/mirror_color.svg',
  },
  {
    name: 'window',
    glyph: '🪟',
    id: 1336,
    img: 'Window/Color/window_color.svg',
  },
  {
    name: 'bed',
    glyph: '🛏️',
    id: 1337,
    img: 'Bed/Color/bed_color.svg',
  },
  {
    name: 'couch and lamp',
    glyph: '🛋️',
    id: 1338,
    img: 'Couch and lamp/Color/couch_and_lamp_color.svg',
  },
  {
    name: 'chair',
    glyph: '🪑',
    id: 1339,
    img: 'Chair/Color/chair_color.svg',
  },
  {
    name: 'toilet',
    glyph: '🚽',
    id: 1340,
    img: 'Toilet/Color/toilet_color.svg',
  },
  {
    name: 'plunger',
    glyph: '🪠',
    id: 1341,
    img: 'Plunger/Color/plunger_color.svg',
  },
  {
    name: 'shower',
    glyph: '🚿',
    id: 1342,
    img: 'Shower/Color/shower_color.svg',
  },
  {
    name: 'bathtub',
    glyph: '🛁',
    id: 1343,
    img: 'Bathtub/Color/bathtub_color.svg',
  },
  {
    name: 'mouse trap',
    glyph: '🪤',
    id: 1344,
    img: 'Mouse trap/Color/mouse_trap_color.svg',
  },
  {
    name: 'razor',
    glyph: '🪒',
    id: 1345,
    img: 'Razor/Color/razor_color.svg',
  },
  {
    name: 'lotion bottle',
    glyph: '🧴',
    id: 1346,
    img: 'Lotion bottle/Color/lotion_bottle_color.svg',
  },
  {
    name: 'safety pin',
    glyph: '🧷',
    id: 1347,
    img: 'Safety pin/Color/safety_pin_color.svg',
  },
  {
    name: 'broom',
    glyph: '🧹',
    id: 1348,
    img: 'Broom/Color/broom_color.svg',
  },
  {
    name: 'basket',
    glyph: '🧺',
    id: 1349,
    img: 'Basket/Color/basket_color.svg',
  },
  {
    name: 'roll of paper',
    glyph: '🧻',
    id: 1350,
    img: 'Roll of paper/Color/roll_of_paper_color.svg',
  },
  {
    name: 'bucket',
    glyph: '🪣',
    id: 1351,
    img: 'Bucket/Color/bucket_color.svg',
  },
  {
    name: 'soap',
    glyph: '🧼',
    id: 1352,
    img: 'Soap/Color/soap_color.svg',
  },
  {
    name: 'bubbles',
    glyph: '🫧',
    id: 1353,
    img: 'Bubbles/Color/bubbles_color.svg',
  },
  {
    name: 'toothbrush',
    glyph: '🪥',
    id: 1354,
    img: 'Toothbrush/Color/toothbrush_color.svg',
  },
  {
    name: 'sponge',
    glyph: '🧽',
    id: 1355,
    img: 'Sponge/Color/sponge_color.svg',
  },
  {
    name: 'fire extinguisher',
    glyph: '🧯',
    id: 1356,
    img: 'Fire extinguisher/Color/fire_extinguisher_color.svg',
  },
  {
    name: 'shopping cart',
    glyph: '🛒',
    id: 1357,
    img: 'Shopping cart/Color/shopping_cart_color.svg',
  },
  {
    name: 'cigarette',
    glyph: '🚬',
    id: 1358,
    img: 'Cigarette/Color/cigarette_color.svg',
  },
  {
    name: 'coffin',
    glyph: '⚰️',
    id: 1359,
    img: 'Coffin/Color/coffin_color.svg',
  },
  {
    name: 'headstone',
    glyph: '🪦',
    id: 1360,
    img: 'Headstone/Color/headstone_color.svg',
  },
  {
    name: 'funeral urn',
    glyph: '⚱️',
    id: 1361,
    img: 'Funeral urn/Color/funeral_urn_color.svg',
  },
  {
    name: 'moai',
    glyph: '🗿',
    id: 1362,
    img: 'Moai/Color/moai_color.svg',
  },
  {
    name: 'placard',
    glyph: '🪧',
    id: 1363,
    img: 'Placard/Color/placard_color.svg',
  },
  {
    name: 'identification card',
    glyph: '🪪',
    id: 1364,
    img: 'Identification card/Color/identification_card_color.svg',
  },
  {
    name: 'ATM sign',
    glyph: '🏧',
    id: 1365,
    img: 'Atm sign/Color/atm_sign_color.svg',
  },
  {
    name: 'litter in bin sign',
    glyph: '🚮',
    id: 1366,
    img: 'Litter in bin sign/Color/litter_in_bin_sign_color.svg',
  },
  {
    name: 'potable water',
    glyph: '🚰',
    id: 1367,
    img: 'Potable water/Color/potable_water_color.svg',
  },
  {
    name: 'wheelchair symbol',
    glyph: '♿',
    id: 1368,
    img: 'Wheelchair symbol/Color/wheelchair_symbol_color.svg',
  },
  {
    name: 'men’s room',
    glyph: '🚹',
    id: 1369,
    img: 'Mens room/Color/mens_room_color.svg',
  },
  {
    name: 'women’s room',
    glyph: '🚺',
    id: 1370,
    img: 'Womens room/Color/womens_room_color.svg',
  },
  {
    name: 'restroom',
    glyph: '🚻',
    id: 1371,
    img: 'Restroom/Color/restroom_color.svg',
  },
  {
    name: 'baby symbol',
    glyph: '🚼',
    id: 1372,
    img: 'Baby symbol/Color/baby_symbol_color.svg',
  },
  {
    name: 'water closet',
    glyph: '🚾',
    id: 1373,
    img: 'Water closet/Color/water_closet_color.svg',
  },
  {
    name: 'passport control',
    glyph: '🛂',
    id: 1374,
    img: 'Passport control/Color/passport_control_color.svg',
  },
  {
    name: 'customs',
    glyph: '🛃',
    id: 1375,
    img: 'Customs/Color/customs_color.svg',
  },
  {
    name: 'baggage claim',
    glyph: '🛄',
    id: 1376,
    img: 'Baggage claim/Color/baggage_claim_color.svg',
  },
  {
    name: 'left luggage',
    glyph: '🛅',
    id: 1377,
    img: 'Left luggage/Color/left_luggage_color.svg',
  },
  {
    name: 'warning',
    glyph: '⚠️',
    id: 1378,
    img: 'Warning/Color/warning_color.svg',
  },
  {
    name: 'children crossing',
    glyph: '🚸',
    id: 1379,
    img: 'Children crossing/Color/children_crossing_color.svg',
  },
  {
    name: 'no entry',
    glyph: '⛔',
    id: 1380,
    img: 'No entry/Color/no_entry_color.svg',
  },
  {
    name: 'prohibited',
    glyph: '🚫',
    id: 1381,
    img: 'Prohibited/Color/prohibited_color.svg',
  },
  {
    name: 'no bicycles',
    glyph: '🚳',
    id: 1382,
    img: 'No bicycles/Color/no_bicycles_color.svg',
  },
  {
    name: 'no smoking',
    glyph: '🚭',
    id: 1383,
    img: 'No smoking/Color/no_smoking_color.svg',
  },
  {
    name: 'no littering',
    glyph: '🚯',
    id: 1384,
    img: 'No littering/Color/no_littering_color.svg',
  },
  {
    name: 'non-potable water',
    glyph: '🚱',
    id: 1385,
    img: 'Non-potable water/Color/non-potable_water_color.svg',
  },
  {
    name: 'no pedestrians',
    glyph: '🚷',
    id: 1386,
    img: 'No pedestrians/Color/no_pedestrians_color.svg',
  },
  {
    name: 'no mobile phones',
    glyph: '📵',
    id: 1387,
    img: 'No mobile phones/Color/no_mobile_phones_color.svg',
  },
  {
    name: 'no one under eighteen',
    glyph: '🔞',
    id: 1388,
    img: 'No one under eighteen/Color/no_one_under_eighteen_color.svg',
  },
  {
    name: 'radioactive',
    glyph: '☢️',
    id: 1389,
    img: 'Radioactive/Color/radioactive_color.svg',
  },
  {
    name: 'biohazard',
    glyph: '☣️',
    id: 1390,
    img: 'Biohazard/Color/biohazard_color.svg',
  },
  {
    name: 'up arrow',
    glyph: '⬆️',
    id: 1391,
    img: 'Up arrow/Color/up_arrow_color.svg',
  },
  {
    name: 'up-right arrow',
    glyph: '↗️',
    id: 1392,
    img: 'Up-right arrow/Color/up-right_arrow_color.svg',
  },
  {
    name: 'right arrow',
    glyph: '➡️',
    id: 1393,
    img: 'Right arrow/Color/right_arrow_color.svg',
  },
  {
    name: 'down-right arrow',
    glyph: '↘️',
    id: 1394,
    img: 'Down-right arrow/Color/down-right_arrow_color.svg',
  },
  {
    name: 'down arrow',
    glyph: '⬇️',
    id: 1395,
    img: 'Down arrow/Color/down_arrow_color.svg',
  },
  {
    name: 'down-left arrow',
    glyph: '↙️',
    id: 1396,
    img: 'Down-left arrow/Color/down-left_arrow_color.svg',
  },
  {
    name: 'left arrow',
    glyph: '⬅️',
    id: 1397,
    img: 'Left arrow/Color/left_arrow_color.svg',
  },
  {
    name: 'up-left arrow',
    glyph: '↖️',
    id: 1398,
    img: 'Up-left arrow/Color/up-left_arrow_color.svg',
  },
  {
    name: 'up-down arrow',
    glyph: '↕️',
    id: 1399,
    img: 'Up-down arrow/Color/up-down_arrow_color.svg',
  },
  {
    name: 'left-right arrow',
    glyph: '↔️',
    id: 1400,
    img: 'Left-right arrow/Color/left-right_arrow_color.svg',
  },
  {
    name: 'right arrow curving left',
    glyph: '↩️',
    id: 1401,
    img: 'Right arrow curving left/Color/right_arrow_curving_left_color.svg',
  },
  {
    name: 'left arrow curving right',
    glyph: '↪️',
    id: 1402,
    img: 'Left arrow curving right/Color/left_arrow_curving_right_color.svg',
  },
  {
    name: 'right arrow curving up',
    glyph: '⤴️',
    id: 1403,
    img: 'Right arrow curving up/Color/right_arrow_curving_up_color.svg',
  },
  {
    name: 'right arrow curving down',
    glyph: '⤵️',
    id: 1404,
    img: 'Right arrow curving down/Color/right_arrow_curving_down_color.svg',
  },
  {
    name: 'clockwise vertical arrows',
    glyph: '🔃',
    id: 1405,
    img: 'Clockwise vertical arrows/Color/clockwise_vertical_arrows_color.svg',
  },
  {
    name: 'counterclockwise arrows button',
    glyph: '🔄',
    id: 1406,
    img: 'Counterclockwise arrows button/Color/counterclockwise_arrows_button_color.svg',
  },
  {
    name: 'BACK arrow',
    glyph: '🔙',
    id: 1407,
    img: 'Back arrow/Color/back_arrow_color.svg',
  },
  {
    name: 'END arrow',
    glyph: '🔚',
    id: 1408,
    img: 'End arrow/Color/end_arrow_color.svg',
  },
  {
    name: 'ON! arrow',
    glyph: '🔛',
    id: 1409,
    img: 'On! arrow/Color/on!_arrow_color.svg',
  },
  {
    name: 'SOON arrow',
    glyph: '🔜',
    id: 1410,
    img: 'Soon arrow/Color/soon_arrow_color.svg',
  },
  {
    name: 'TOP arrow',
    glyph: '🔝',
    id: 1411,
    img: 'Top arrow/Color/top_arrow_color.svg',
  },
  {
    name: 'place of worship',
    glyph: '🛐',
    id: 1412,
    img: 'Place of worship/Color/place_of_worship_color.svg',
  },
  {
    name: 'atom symbol',
    glyph: '⚛️',
    id: 1413,
    img: 'Atom symbol/Color/atom_symbol_color.svg',
  },
  {
    name: 'om',
    glyph: '🕉️',
    id: 1414,
    img: 'Om/Color/om_color.svg',
  },
  {
    name: 'star of David',
    glyph: '✡️',
    id: 1415,
    img: 'Star of david/Color/star_of_david_color.svg',
  },
  {
    name: 'wheel of dharma',
    glyph: '☸️',
    id: 1416,
    img: 'Wheel of dharma/Color/wheel_of_dharma_color.svg',
  },
  {
    name: 'yin yang',
    glyph: '☯️',
    id: 1417,
    img: 'Yin yang/Color/yin_yang_color.svg',
  },
  {
    name: 'latin cross',
    glyph: '✝️',
    id: 1418,
    img: 'Latin cross/Color/latin_cross_color.svg',
  },
  {
    name: 'orthodox cross',
    glyph: '☦️',
    id: 1419,
    img: 'Orthodox cross/Color/orthodox_cross_color.svg',
  },
  {
    name: 'star and crescent',
    glyph: '☪️',
    id: 1420,
    img: 'Star and crescent/Color/star_and_crescent_color.svg',
  },
  {
    name: 'peace symbol',
    glyph: '☮️',
    id: 1421,
    img: 'Peace symbol/Color/peace_symbol_color.svg',
  },
  {
    name: 'menorah',
    glyph: '🕎',
    id: 1422,
    img: 'Menorah/Color/menorah_color.svg',
  },
  {
    name: 'dotted six-pointed star',
    glyph: '🔯',
    id: 1423,
    img: 'Dotted six-pointed star/Color/dotted_six-pointed_star_color.svg',
  },
  {
    name: 'Aries',
    glyph: '♈',
    id: 1424,
    img: 'Aries/Color/aries_color.svg',
  },
  {
    name: 'Taurus',
    glyph: '♉',
    id: 1425,
    img: 'Taurus/Color/taurus_color.svg',
  },
  {
    name: 'Gemini',
    glyph: '♊',
    id: 1426,
    img: 'Gemini/Color/gemini_color.svg',
  },
  {
    name: 'Cancer',
    glyph: '♋',
    id: 1427,
    img: 'Cancer/Color/cancer_color.svg',
  },
  {
    name: 'Leo',
    glyph: '♌',
    id: 1428,
    img: 'Leo/Color/leo_color.svg',
  },
  {
    name: 'Virgo',
    glyph: '♍',
    id: 1429,
    img: 'Virgo/Color/virgo_color.svg',
  },
  {
    name: 'Libra',
    glyph: '♎',
    id: 1430,
    img: 'Libra/Color/libra_color.svg',
  },
  {
    name: 'Scorpio',
    glyph: '♏',
    id: 1431,
    img: 'Scorpio/Color/scorpio_color.svg',
  },
  {
    name: 'Sagittarius',
    glyph: '♐',
    id: 1432,
    img: 'Sagittarius/Color/sagittarius_color.svg',
  },
  {
    name: 'Capricorn',
    glyph: '♑',
    id: 1433,
    img: 'Capricorn/Color/capricorn_color.svg',
  },
  {
    name: 'Aquarius',
    glyph: '♒',
    id: 1434,
    img: 'Aquarius/Color/aquarius_color.svg',
  },
  {
    name: 'Pisces',
    glyph: '♓',
    id: 1435,
    img: 'Pisces/Color/pisces_color.svg',
  },
  {
    name: 'Ophiuchus',
    glyph: '⛎',
    id: 1436,
    img: 'Ophiuchus/Color/ophiuchus_color.svg',
  },
  {
    name: 'shuffle tracks button',
    glyph: '🔀',
    id: 1437,
    img: 'Shuffle tracks button/Color/shuffle_tracks_button_color.svg',
  },
  {
    name: 'repeat button',
    glyph: '🔁',
    id: 1438,
    img: 'Repeat button/Color/repeat_button_color.svg',
  },
  {
    name: 'repeat single button',
    glyph: '🔂',
    id: 1439,
    img: 'Repeat single button/Color/repeat_single_button_color.svg',
  },
  {
    name: 'play button',
    glyph: '▶️',
    id: 1440,
    img: 'Play button/Color/play_button_color.svg',
  },
  {
    name: 'fast-forward button',
    glyph: '⏩',
    id: 1441,
    img: 'Fast-forward button/Color/fast-forward_button_color.svg',
  },
  {
    name: 'next track button',
    glyph: '⏭️',
    id: 1442,
    img: 'Next track button/Color/next_track_button_color.svg',
  },
  {
    name: 'play or pause button',
    glyph: '⏯️',
    id: 1443,
    img: 'Play or pause button/Color/play_or_pause_button_color.svg',
  },
  {
    name: 'reverse button',
    glyph: '◀️',
    id: 1444,
    img: 'Reverse button/Color/reverse_button_color.svg',
  },
  {
    name: 'fast reverse button',
    glyph: '⏪',
    id: 1445,
    img: 'Fast reverse button/Color/fast_reverse_button_color.svg',
  },
  {
    name: 'last track button',
    glyph: '⏮️',
    id: 1446,
    img: 'Last track button/Color/last_track_button_color.svg',
  },
  {
    name: 'upwards button',
    glyph: '🔼',
    id: 1447,
    img: 'Upwards button/Color/upwards_button_color.svg',
  },
  {
    name: 'fast up button',
    glyph: '⏫',
    id: 1448,
    img: 'Fast up button/Color/fast_up_button_color.svg',
  },
  {
    name: 'downwards button',
    glyph: '🔽',
    id: 1449,
    img: 'Downwards button/Color/downwards_button_color.svg',
  },
  {
    name: 'fast down button',
    glyph: '⏬',
    id: 1450,
    img: 'Fast down button/Color/fast_down_button_color.svg',
  },
  {
    name: 'pause button',
    glyph: '⏸️',
    id: 1451,
    img: 'Pause button/Color/pause_button_color.svg',
  },
  {
    name: 'stop button',
    glyph: '⏹️',
    id: 1452,
    img: 'Stop button/Color/stop_button_color.svg',
  },
  {
    name: 'record button',
    glyph: '⏺️',
    id: 1453,
    img: 'Record button/Color/record_button_color.svg',
  },
  {
    name: 'eject button',
    glyph: '⏏️',
    id: 1454,
    img: 'Eject button/Color/eject_button_color.svg',
  },
  {
    name: 'cinema',
    glyph: '🎦',
    id: 1455,
    img: 'Cinema/Color/cinema_color.svg',
  },
  {
    name: 'dim button',
    glyph: '🔅',
    id: 1456,
    img: 'Dim button/Color/dim_button_color.svg',
  },
  {
    name: 'bright button',
    glyph: '🔆',
    id: 1457,
    img: 'Bright button/Color/bright_button_color.svg',
  },
  {
    name: 'antenna bars',
    glyph: '📶',
    id: 1458,
    img: 'Antenna bars/Color/antenna_bars_color.svg',
  },
  {
    name: 'vibration mode',
    glyph: '📳',
    id: 1459,
    img: 'Vibration mode/Color/vibration_mode_color.svg',
  },
  {
    name: 'mobile phone off',
    glyph: '📴',
    id: 1460,
    img: 'Mobile phone off/Color/mobile_phone_off_color.svg',
  },
  {
    name: 'female sign',
    glyph: '♀️',
    id: 1461,
    img: 'Female sign/Color/female_sign_color.svg',
  },
  {
    name: 'male sign',
    glyph: '♂️',
    id: 1462,
    img: 'Male sign/Color/male_sign_color.svg',
  },
  {
    name: 'transgender symbol',
    glyph: '⚧️',
    id: 1463,
    img: 'Transgender symbol/Color/transgender_symbol_color.svg',
  },
  {
    name: 'multiply',
    glyph: '✖️',
    id: 1464,
    img: 'Multiply/Color/multiply_color.svg',
  },
  {
    name: 'plus',
    glyph: '➕',
    id: 1465,
    img: 'Plus/Color/plus_color.svg',
  },
  {
    name: 'minus',
    glyph: '➖',
    id: 1466,
    img: 'Minus/Color/minus_color.svg',
  },
  {
    name: 'divide',
    glyph: '➗',
    id: 1467,
    img: 'Divide/Color/divide_color.svg',
  },
  {
    name: 'heavy equals sign',
    glyph: '🟰',
    id: 1468,
    img: 'Heavy equals sign/Color/heavy_equals_sign_color.svg',
  },
  {
    name: 'infinity',
    glyph: '♾️',
    id: 1469,
    img: 'Infinity/Color/infinity_color.svg',
  },
  {
    name: 'double exclamation mark',
    glyph: '‼️',
    id: 1470,
    img: 'Double exclamation mark/Color/double_exclamation_mark_color.svg',
  },
  {
    name: 'exclamation question mark',
    glyph: '⁉️',
    id: 1471,
    img: 'Exclamation question mark/Color/exclamation_question_mark_color.svg',
  },
  {
    name: 'red question mark',
    glyph: '❓',
    id: 1472,
    img: 'Red question mark/Color/red_question_mark_color.svg',
  },
  {
    name: 'white question mark',
    glyph: '❔',
    id: 1473,
    img: 'White question mark/Color/white_question_mark_color.svg',
  },
  {
    name: 'white exclamation mark',
    glyph: '❕',
    id: 1474,
    img: 'White exclamation mark/Color/white_exclamation_mark_color.svg',
  },
  {
    name: 'red exclamation mark',
    glyph: '❗',
    id: 1475,
    img: 'Red exclamation mark/Color/red_exclamation_mark_color.svg',
  },
  {
    name: 'wavy dash',
    glyph: '〰️',
    id: 1476,
    img: 'Wavy dash/Color/wavy_dash_color.svg',
  },
  {
    name: 'currency exchange',
    glyph: '💱',
    id: 1477,
    img: 'Currency exchange/Color/currency_exchange_color.svg',
  },
  {
    name: 'heavy dollar sign',
    glyph: '💲',
    id: 1478,
    img: 'Heavy dollar sign/Color/heavy_dollar_sign_color.svg',
  },
  {
    name: 'medical symbol',
    glyph: '⚕️',
    id: 1479,
    img: 'Medical symbol/Color/medical_symbol_color.svg',
  },
  {
    name: 'recycling symbol',
    glyph: '♻️',
    id: 1480,
    img: 'Recycling symbol/Color/recycling_symbol_color.svg',
  },
  {
    name: 'fleur-de-lis',
    glyph: '⚜️',
    id: 1481,
    img: 'Fleur-de-lis/Color/fleur-de-lis_color.svg',
  },
  {
    name: 'trident emblem',
    glyph: '🔱',
    id: 1482,
    img: 'Trident emblem/Color/trident_emblem_color.svg',
  },
  {
    name: 'name badge',
    glyph: '📛',
    id: 1483,
    img: 'Name badge/Color/name_badge_color.svg',
  },
  {
    name: 'Japanese symbol for beginner',
    glyph: '🔰',
    id: 1484,
    img: 'Japanese symbol for beginner/Color/japanese_symbol_for_beginner_color.svg',
  },
  {
    name: 'hollow red circle',
    glyph: '⭕',
    id: 1485,
    img: 'Hollow red circle/Color/hollow_red_circle_color.svg',
  },
  {
    name: 'check mark button',
    glyph: '✅',
    id: 1486,
    img: 'Check mark button/Color/check_mark_button_color.svg',
  },
  {
    name: 'check box with check',
    glyph: '☑️',
    id: 1487,
    img: 'Check box with check/Color/check_box_with_check_color.svg',
  },
  {
    name: 'check mark',
    glyph: '✔️',
    id: 1488,
    img: 'Check mark/Color/check_mark_color.svg',
  },
  {
    name: 'cross mark',
    glyph: '❌',
    id: 1489,
    img: 'Cross mark/Color/cross_mark_color.svg',
  },
  {
    name: 'cross mark button',
    glyph: '❎',
    id: 1490,
    img: 'Cross mark button/Color/cross_mark_button_color.svg',
  },
  {
    name: 'curly loop',
    glyph: '➰',
    id: 1491,
    img: 'Curly loop/Color/curly_loop_color.svg',
  },
  {
    name: 'double curly loop',
    glyph: '➿',
    id: 1492,
    img: 'Double curly loop/Color/double_curly_loop_color.svg',
  },
  {
    name: 'part alternation mark',
    glyph: '〽️',
    id: 1493,
    img: 'Part alternation mark/Color/part_alternation_mark_color.svg',
  },
  {
    name: 'eight-spoked asterisk',
    glyph: '✳️',
    id: 1494,
    img: 'Eight-spoked asterisk/Color/eight-spoked_asterisk_color.svg',
  },
  {
    name: 'eight-pointed star',
    glyph: '✴️',
    id: 1495,
    img: 'Eight-pointed star/Color/eight-pointed_star_color.svg',
  },
  {
    name: 'sparkle',
    glyph: '❇️',
    id: 1496,
    img: 'Sparkle/Color/sparkle_color.svg',
  },
  {
    name: 'copyright',
    glyph: '©️',
    id: 1497,
    img: 'Copyright/Color/copyright_color.svg',
  },
  {
    name: 'registered',
    glyph: '®️',
    id: 1498,
    img: 'Registered/Color/registered_color.svg',
  },
  {
    name: 'trade mark',
    glyph: '™️',
    id: 1499,
    img: 'Trade mark/Color/trade_mark_color.svg',
  },
  {
    name: 'keycap: hash',
    glyph: '#️⃣',
    id: 1500,
    img: 'Keycap hashtag/Color/keycap_hashtag_color.svg',
  },
  {
    name: 'keycap: asterisk',
    glyph: '*️⃣',
    id: 1501,
    img: 'Keycap asterisk/Color/keycap_asterisk_color.svg',
  },
  {
    name: 'keycap: 0',
    glyph: '0️⃣',
    id: 1502,
    img: 'Keycap 0/Color/keycap_0_color.svg',
  },
  {
    name: 'keycap: 1',
    glyph: '1️⃣',
    id: 1503,
    img: 'Keycap 1/Color/keycap_1_color.svg',
  },
  {
    name: 'keycap: 2',
    glyph: '2️⃣',
    id: 1504,
    img: 'Keycap 2/Color/keycap_2_color.svg',
  },
  {
    name: 'keycap: 3',
    glyph: '3️⃣',
    id: 1505,
    img: 'Keycap 3/Color/keycap_3_color.svg',
  },
  {
    name: 'keycap: 4',
    glyph: '4️⃣',
    id: 1506,
    img: 'Keycap 4/Color/keycap_4_color.svg',
  },
  {
    name: 'keycap: 5',
    glyph: '5️⃣',
    id: 1507,
    img: 'Keycap 5/Color/keycap_5_color.svg',
  },
  {
    name: 'keycap: 6',
    glyph: '6️⃣',
    id: 1508,
    img: 'Keycap 6/Color/keycap_6_color.svg',
  },
  {
    name: 'keycap: 7',
    glyph: '7️⃣',
    id: 1509,
    img: 'Keycap 7/Color/keycap_7_color.svg',
  },
  {
    name: 'keycap: 8',
    glyph: '8️⃣',
    id: 1510,
    img: 'Keycap 8/Color/keycap_8_color.svg',
  },
  {
    name: 'keycap: 9',
    glyph: '9️⃣',
    id: 1511,
    img: 'Keycap 9/Color/keycap_9_color.svg',
  },
  {
    name: 'keycap: 10',
    glyph: '🔟',
    id: 1512,
    img: 'Keycap 10/Color/keycap_10_color.svg',
  },
  {
    name: 'input latin uppercase',
    glyph: '🔠',
    id: 1513,
    img: 'Input latin uppercase/Color/input_latin_uppercase_color.svg',
  },
  {
    name: 'input latin lowercase',
    glyph: '🔡',
    id: 1514,
    img: 'Input latin lowercase/Color/input_latin_lowercase_color.svg',
  },
  {
    name: 'input numbers',
    glyph: '🔢',
    id: 1515,
    img: 'Input numbers/Color/input_numbers_color.svg',
  },
  {
    name: 'input symbols',
    glyph: '🔣',
    id: 1516,
    img: 'Input symbols/Color/input_symbols_color.svg',
  },
  {
    name: 'input latin letters',
    glyph: '🔤',
    id: 1517,
    img: 'Input latin letters/Color/input_latin_letters_color.svg',
  },
  {
    name: 'A button (blood type)',
    glyph: '🅰️',
    id: 1518,
    img: 'A button blood type/Color/a_button_blood_type_color.svg',
  },
  {
    name: 'AB button (blood type)',
    glyph: '🆎',
    id: 1519,
    img: 'Ab button blood type/Color/ab_button_blood_type_color.svg',
  },
  {
    name: 'B button (blood type)',
    glyph: '🅱️',
    id: 1520,
    img: 'B button blood type/Color/b_button_blood_type_color.svg',
  },
  {
    name: 'CL button',
    glyph: '🆑',
    id: 1521,
    img: 'Cl button/Color/cl_button_color.svg',
  },
  {
    name: 'COOL button',
    glyph: '🆒',
    id: 1522,
    img: 'Cool button/Color/cool_button_color.svg',
  },
  {
    name: 'FREE button',
    glyph: '🆓',
    id: 1523,
    img: 'Free button/Color/free_button_color.svg',
  },
  {
    name: 'information',
    glyph: 'ℹ️',
    id: 1524,
    img: 'Information/Color/information_color.svg',
  },
  {
    name: 'ID button',
    glyph: '🆔',
    id: 1525,
    img: 'Id button/Color/id_button_color.svg',
  },
  {
    name: 'circled M',
    glyph: 'Ⓜ️',
    id: 1526,
    img: 'Circled m/Color/circled_m_color.svg',
  },
  {
    name: 'NEW button',
    glyph: '🆕',
    id: 1527,
    img: 'New button/Color/new_button_color.svg',
  },
  {
    name: 'NG button',
    glyph: '🆖',
    id: 1528,
    img: 'Ng button/Color/ng_button_color.svg',
  },
  {
    name: 'O button (blood type)',
    glyph: '🅾️',
    id: 1529,
    img: 'O button blood type/Color/o_button_(blood_type)_color.svg',
  },
  {
    name: 'OK button',
    glyph: '🆗',
    id: 1530,
    img: 'Ok button/Color/ok_button_color.svg',
  },
  {
    name: 'P button',
    glyph: '🅿️',
    id: 1531,
    img: 'P button/Color/p_button_color.svg',
  },
  {
    name: 'SOS button',
    glyph: '🆘',
    id: 1532,
    img: 'Sos button/Color/sos_button_color.svg',
  },
  {
    name: 'UP! button',
    glyph: '🆙',
    id: 1533,
    img: 'Up! button/Color/up!_button_color.svg',
  },
  {
    name: 'VS button',
    glyph: '🆚',
    id: 1534,
    img: 'Vs button/Color/vs_button_color.svg',
  },
  {
    name: 'Japanese “here” button',
    glyph: '🈁',
    id: 1535,
    img: 'Japanese here button/Color/japanese_here_button_color.svg',
  },
  {
    name: 'Japanese “service charge” button',
    glyph: '🈂️',
    id: 1536,
    img: 'Japanese service charge button/Color/japanese_service_charge_button_color.svg',
  },
  {
    name: 'Japanese “monthly amount” button',
    glyph: '🈷️',
    id: 1537,
    img: 'Japanese monthly amount button/Color/japanese_monthly_amount_button_color.svg',
  },
  {
    name: 'Japanese “not free of charge” button',
    glyph: '🈶',
    id: 1538,
    img: 'Japanese not free of charge button/Color/japanese_not_free_of_charge_button_color.svg',
  },
  {
    name: 'Japanese “reserved” button',
    glyph: '🈯',
    id: 1539,
    img: 'Japanese reserved button/Color/japanese_reserved_button_color.svg',
  },
  {
    name: 'Japanese “bargain” button',
    glyph: '🉐',
    id: 1540,
    img: 'Japanese bargain button/Color/japanese_bargain_button_color.svg',
  },
  {
    name: 'Japanese “discount” button',
    glyph: '🈹',
    id: 1541,
    img: 'Japanese discount button/Color/japanese_discount_button_color.svg',
  },
  {
    name: 'Japanese “free of charge” button',
    glyph: '🈚',
    id: 1542,
    img: 'Japanese free of charge button/Color/japanese_free_of_charge_button_color.svg',
  },
  {
    name: 'Japanese “prohibited” button',
    glyph: '🈲',
    id: 1543,
    img: 'Japanese prohibited button/Color/japanese_prohibited_button_color.svg',
  },
  {
    name: 'Japanese “acceptable” button',
    glyph: '🉑',
    id: 1544,
    img: 'Japanese acceptable button/Color/japanese_acceptable_button_color.svg',
  },
  {
    name: 'Japanese “application” button',
    glyph: '🈸',
    id: 1545,
    img: 'Japanese application button/Color/japanese_application_button_color.svg',
  },
  {
    name: 'Japanese “passing grade” button',
    glyph: '🈴',
    id: 1546,
    img: 'Japanese passing grade button/Color/japanese_passing_grade_button_color.svg',
  },
  {
    name: 'Japanese “vacancy” button',
    glyph: '🈳',
    id: 1547,
    img: 'Japanese vacancy button/Color/japanese_vacancy_button_color.svg',
  },
  {
    name: 'Japanese “congratulations” button',
    glyph: '㊗️',
    id: 1548,
    img: 'Japanese congratulations button/Color/japanese_congratulations_button_color.svg',
  },
  {
    name: 'Japanese “secret” button',
    glyph: '㊙️',
    id: 1549,
    img: 'Japanese secret button/Color/japanese_secret_button_color.svg',
  },
  {
    name: 'Japanese “open for business” button',
    glyph: '🈺',
    id: 1550,
    img: 'Japanese open for business button/Color/japanese_open_for_business_button_color.svg',
  },
  {
    name: 'Japanese “no vacancy” button',
    glyph: '🈵',
    id: 1551,
    img: 'Japanese no vacancy button/Color/japanese_no_vacancy_button_color.svg',
  },
  {
    name: 'red circle',
    glyph: '🔴',
    id: 1552,
    img: 'Red circle/Color/red_circle_color.svg',
  },
  {
    name: 'orange circle',
    glyph: '🟠',
    id: 1553,
    img: 'Orange circle/Color/orange_circle_color.svg',
  },
  {
    name: 'yellow circle',
    glyph: '🟡',
    id: 1554,
    img: 'Yellow circle/Color/yellow_circle_color.svg',
  },
  {
    name: 'green circle',
    glyph: '🟢',
    id: 1555,
    img: 'Green circle/Color/green_circle_color.svg',
  },
  {
    name: 'blue circle',
    glyph: '🔵',
    id: 1556,
    img: 'Blue circle/Color/blue_circle_color.svg',
  },
  {
    name: 'purple circle',
    glyph: '🟣',
    id: 1557,
    img: 'Purple circle/Color/purple_circle_color.svg',
  },
  {
    name: 'brown circle',
    glyph: '🟤',
    id: 1558,
    img: 'Brown circle/Color/brown_circle_color.svg',
  },
  {
    name: 'black circle',
    glyph: '⚫',
    id: 1559,
    img: 'Black circle/Color/black_circle_color.svg',
  },
  {
    name: 'white circle',
    glyph: '⚪',
    id: 1560,
    img: 'White circle/Color/white_circle_color.svg',
  },
  {
    name: 'red square',
    glyph: '🟥',
    id: 1561,
    img: 'Red square/Color/red_square_color.svg',
  },
  {
    name: 'orange square',
    glyph: '🟧',
    id: 1562,
    img: 'Orange square/Color/orange_square_color.svg',
  },
  {
    name: 'yellow square',
    glyph: '🟨',
    id: 1563,
    img: 'Yellow square/Color/yellow_square_color.svg',
  },
  {
    name: 'green square',
    glyph: '🟩',
    id: 1564,
    img: 'Green square/Color/green_square_color.svg',
  },
  {
    name: 'blue square',
    glyph: '🟦',
    id: 1565,
    img: 'Blue square/Color/blue_square_color.svg',
  },
  {
    name: 'purple square',
    glyph: '🟪',
    id: 1566,
    img: 'Purple square/Color/purple_square_color.svg',
  },
  {
    name: 'brown square',
    glyph: '🟫',
    id: 1567,
    img: 'Brown square/Color/brown_square_color.svg',
  },
  {
    name: 'black large square',
    glyph: '⬛',
    id: 1568,
    img: 'Black large square/Color/black_large_square_color.svg',
  },
  {
    name: 'white large square',
    glyph: '⬜',
    id: 1569,
    img: 'White large square/Color/white_large_square_color.svg',
  },
  {
    name: 'black medium square',
    glyph: '◼️',
    id: 1570,
    img: 'Black medium square/Color/black_medium_square_color.svg',
  },
  {
    name: 'white medium square',
    glyph: '◻️',
    id: 1571,
    img: 'White medium square/Color/white_medium_square_color.svg',
  },
  {
    name: 'black medium-small square',
    glyph: '◾',
    id: 1572,
    img: 'Black medium-small square/Color/black_medium-small_square_color.svg',
  },
  {
    name: 'white medium-small square',
    glyph: '◽',
    id: 1573,
    img: 'White medium-small square/Color/white_medium-small_square_color.svg',
  },
  {
    name: 'black small square',
    glyph: '▪️',
    id: 1574,
    img: 'Black small square/Color/black_small_square_color.svg',
  },
  {
    name: 'white small square',
    glyph: '▫️',
    id: 1575,
    img: 'White small square/Color/white_small_square_color.svg',
  },
  {
    name: 'large orange diamond',
    glyph: '🔶',
    id: 1576,
    img: 'Large orange diamond/Color/large_orange_diamond_color.svg',
  },
  {
    name: 'large blue diamond',
    glyph: '🔷',
    id: 1577,
    img: 'Large blue diamond/Color/large_blue_diamond_color.svg',
  },
  {
    name: 'small orange diamond',
    glyph: '🔸',
    id: 1578,
    img: 'Small orange diamond/Color/small_orange_diamond_color.svg',
  },
  {
    name: 'small blue diamond',
    glyph: '🔹',
    id: 1579,
    img: 'Small blue diamond/Color/small_blue_diamond_color.svg',
  },
  {
    name: 'red triangle pointed up',
    glyph: '🔺',
    id: 1580,
    img: 'Red triangle/Color/red_triangle_color.svg',
  },
  {
    name: 'red triangle pointed down',
    glyph: '🔻',
    id: 1581,
    img: 'Red triangle pointed down/Color/red_triangle_pointed_down_color.svg',
  },
  {
    name: 'diamond with a dot',
    glyph: '💠',
    id: 1582,
    img: 'Diamond with a dot/Color/diamond_with_a_dot_color.svg',
  },
  {
    name: 'radio button',
    glyph: '🔘',
    id: 1583,
    img: 'Radio button/Color/radio_button_color.svg',
  },
  {
    name: 'white square button',
    glyph: '🔳',
    id: 1584,
    img: 'White square button/Color/white_square_button_color.svg',
  },
  {
    name: 'black square button',
    glyph: '🔲',
    id: 1585,
    img: 'Black square button/Color/black_square_button_color.svg',
  },
  {
    name: 'chequered flag',
    glyph: '🏁',
    id: 1586,
    img: 'Chequered flag/Color/chequered_flag_color.svg',
  },
  {
    name: 'triangular flag',
    glyph: '🚩',
    id: 1587,
    img: 'Triangular flag/Color/triangular_flag_color.svg',
  },
  {
    name: 'crossed flags',
    glyph: '🎌',
    id: 1588,
    img: 'Crossed flags/Color/crossed_flags_color.svg',
  },
  {
    name: 'black flag',
    glyph: '🏴',
    id: 1589,
    img: 'Black flag/Color/black_flag_color.svg',
  },
  {
    name: 'white flag',
    glyph: '🏳️',
    id: 1590,
    img: 'White flag/Color/white_flag_color.svg',
  },
  {
    name: 'rainbow flag',
    glyph: '🏳️‍🌈',
    id: 1591,
    img: 'Rainbow flag/Color/rainbow_flag_color.svg',
  },
  {
    name: 'transgender flag',
    glyph: '🏳️‍⚧️',
    id: 1592,
    img: 'Transgender flag/Color/transgender_flag_color.svg',
  },
  {
    name: 'pirate flag',
    glyph: '🏴‍☠️',
    id: 1593,
    img: 'Pirate flag/Color/pirate_flag_color.svg',
  },
];
export enum EmojiName {
  GrinningFace = 1,
  GrinningFaceWithBigEyes = 2,
  GrinningFaceWithSmilingEyes = 3,
  BeamingFaceWithSmilingEyes = 4,
  GrinningSquintingFace = 5,
  GrinningFaceWithSweat = 6,
  RollingOnTheFloorLaughing = 7,
  FaceWithTearsOfJoy = 8,
  SlightlySmilingFace = 9,
  UpsideDownFace = 10,
  MeltingFace = 11,
  WinkingFace = 12,
  SmilingFaceWithSmilingEyes = 13,
  SmilingFaceWithHalo = 14,
  SmilingFaceWithHearts = 15,
  SmilingFaceWithHeartEyes = 16,
  StarStruck = 17,
  FaceBlowingAKiss = 18,
  KissingFace = 19,
  SmilingFace = 20,
  KissingFaceWithClosedEyes = 21,
  KissingFaceWithSmilingEyes = 22,
  SmilingFaceWithTear = 23,
  FaceSavoringFood = 24,
  FaceWithTongue = 25,
  WinkingFaceWithTongue = 26,
  ZanyFace = 27,
  SquintingFaceWithTongue = 28,
  MoneyMouthFace = 29,
  HuggingFace = 30,
  FaceWithHandOverMouth = 31,
  FaceWithOpenEyesAndHandOverMouth = 32,
  FaceWithPeekingEye = 33,
  ShushingFace = 34,
  ThinkingFace = 35,
  SalutingFace = 36,
  ZipperMouthFace = 37,
  FaceWithRaisedEyebrow = 38,
  NeutralFace = 39,
  ExpressionlessFace = 40,
  FaceWithoutMouth = 41,
  DottedLineFace = 42,
  FaceInClouds = 43,
  SmirkingFace = 44,
  UnamusedFace = 45,
  FaceWithRollingEyes = 46,
  GrimacingFace = 47,
  FaceExhaling = 48,
  LyingFace = 49,
  RelievedFace = 50,
  PensiveFace = 51,
  SleepyFace = 52,
  DroolingFace = 53,
  SleepingFace = 54,
  FaceWithMedicalMask = 55,
  FaceWithThermometer = 56,
  FaceWithHeadBandage = 57,
  NauseatedFace = 58,
  FaceVomiting = 59,
  SneezingFace = 60,
  HotFace = 61,
  ColdFace = 62,
  WoozyFace = 63,
  KnockedOutFace = 64,
  FaceWithSpiralEyes = 65,
  ExplodingHead = 66,
  CowboyHatFace = 67,
  PartyingFace = 68,
  DisguisedFace = 69,
  SmilingFaceWithSunglasses = 70,
  NerdFace = 71,
  FaceWithMonocle = 72,
  ConfusedFace = 73,
  FaceWithDiagonalMouth = 74,
  WorriedFace = 75,
  SlightlyFrowningFace = 76,
  FrowningFace = 77,
  FaceWithOpenMouth = 78,
  HushedFace = 79,
  AstonishedFace = 80,
  FlushedFace = 81,
  PleadingFace = 82,
  FaceHoldingBackTears = 83,
  FrowningFaceWithOpenMouth = 84,
  AnguishedFace = 85,
  FearfulFace = 86,
  AnxiousFaceWithSweat = 87,
  SadButRelievedFace = 88,
  CryingFace = 89,
  LoudlyCryingFace = 90,
  FaceScreamingInFear = 91,
  ConfoundedFace = 92,
  PerseveringFace = 93,
  DisappointedFace = 94,
  DowncastFaceWithSweat = 95,
  WearyFace = 96,
  TiredFace = 97,
  YawningFace = 98,
  FaceWithSteamFromNose = 99,
  PoutingFace = 100,
  AngryFace = 101,
  FaceWithSymbolsOnMouth = 102,
  SmilingFaceWithHorns = 103,
  AngryFaceWithHorns = 104,
  Skull = 105,
  SkullAndCrossbones = 106,
  PileOfPoo = 107,
  ClownFace = 108,
  Ogre = 109,
  Goblin = 110,
  Ghost = 111,
  Alien = 112,
  AlienMonster = 113,
  Robot = 114,
  GrinningCat = 115,
  GrinningCatWithSmilingEyes = 116,
  CatWithTearsOfJoy = 117,
  SmilingCatWithHeartEyes = 118,
  CatWithWrySmile = 119,
  KissingCat = 120,
  WearyCat = 121,
  CryingCat = 122,
  PoutingCat = 123,
  SeeNoEvilMonkey = 124,
  HearNoEvilMonkey = 125,
  SpeakNoEvilMonkey = 126,
  KissMark = 127,
  LoveLetter = 128,
  HeartWithArrow = 129,
  HeartWithRibbon = 130,
  SparklingHeart = 131,
  GrowingHeart = 132,
  BeatingHeart = 133,
  RevolvingHearts = 134,
  TwoHearts = 135,
  HeartDecoration = 136,
  HeartExclamation = 137,
  BrokenHeart = 138,
  HeartOnFire = 139,
  MendingHeart = 140,
  RedHeart = 141,
  OrangeHeart = 142,
  YellowHeart = 143,
  GreenHeart = 144,
  BlueHeart = 145,
  PurpleHeart = 146,
  BrownHeart = 147,
  BlackHeart = 148,
  WhiteHeart = 149,
  HundredPoints = 150,
  AngerSymbol = 151,
  Collision = 152,
  Dizzy = 153,
  SweatDroplets = 154,
  DashingAway = 155,
  Hole = 156,
  Bomb = 157,
  SpeechBalloon = 158,
  EyeInSpeechBubble = 159,
  LeftSpeechBubble = 160,
  RightAngerBubble = 161,
  ThoughtBalloon = 162,
  Zzz = 163,
  WavingHand = 164,
  RaisedBackOfHand = 165,
  HandWithFingersSplayed = 166,
  RaisedHand = 167,
  VulcanSalute = 168,
  RightwardsHand = 169,
  LeftwardsHand = 170,
  PalmDownHand = 171,
  PalmUpHand = 172,
  OKHand = 173,
  PinchedFingers = 174,
  PinchingHand = 175,
  VictoryHand = 176,
  CrossedFingers = 177,
  HandWithIndexFingerAndThumbCrossed = 178,
  LoveYouGesture = 179,
  SignOfTheHorns = 180,
  CallMeHand = 181,
  BackhandIndexPointingLeft = 182,
  BackhandIndexPointingRight = 183,
  BackhandIndexPointingUp = 184,
  MiddleFinger = 185,
  BackhandIndexPointingDown = 186,
  IndexPointingUp = 187,
  IndexPointingAtTheViewer = 188,
  ThumbsUp = 189,
  ThumbsDown = 190,
  RaisedFist = 191,
  OncomingFist = 192,
  LeftFacingFist = 193,
  RightFacingFist = 194,
  ClappingHands = 195,
  RaisingHands = 196,
  HeartHands = 197,
  OpenHands = 198,
  PalmsUpTogether = 199,
  Handshake = 200,
  FoldedHands = 201,
  WritingHand = 202,
  NailPolish = 203,
  Selfie = 204,
  FlexedBiceps = 205,
  MechanicalArm = 206,
  MechanicalLeg = 207,
  Leg = 208,
  Foot = 209,
  Ear = 210,
  EarWithHearingAid = 211,
  Nose = 212,
  Brain = 213,
  AnatomicalHeart = 214,
  Lungs = 215,
  Tooth = 216,
  Bone = 217,
  Eyes = 218,
  Eye = 219,
  Tongue = 220,
  Mouth = 221,
  BitingLip = 222,
  Baby = 223,
  Child = 224,
  Boy = 225,
  Girl = 226,
  Person = 227,
  PersonBlondHair = 228,
  Man = 229,
  PersonBeard = 230,
  ManBeard = 231,
  WomanBeard = 232,
  ManRedHair = 233,
  ManCurlyHair = 234,
  ManWhiteHair = 235,
  ManBald = 236,
  Woman = 237,
  WomanRedHair = 238,
  PersonRedHair = 239,
  WomanCurlyHair = 240,
  PersonCurlyHair = 241,
  WomanWhiteHair = 242,
  PersonWhiteHair = 243,
  WomanBald = 244,
  PersonBald = 245,
  WomanBlondHair = 246,
  ManBlondHair = 247,
  OlderPerson = 248,
  OldMan = 249,
  OldWoman = 250,
  PersonFrowning = 251,
  ManFrowning = 252,
  WomanFrowning = 253,
  PersonPouting = 254,
  ManPouting = 255,
  WomanPouting = 256,
  PersonGesturingNO = 257,
  ManGesturingNO = 258,
  WomanGesturingNO = 259,
  PersonGesturingOK = 260,
  ManGesturingOK = 261,
  WomanGesturingOK = 262,
  PersonTippingHand = 263,
  ManTippingHand = 264,
  WomanTippingHand = 265,
  PersonRaisingHand = 266,
  ManRaisingHand = 267,
  WomanRaisingHand = 268,
  DeafPerson = 269,
  DeafMan = 270,
  DeafWoman = 271,
  PersonBowing = 272,
  ManBowing = 273,
  WomanBowing = 274,
  PersonFacepalming = 275,
  ManFacepalming = 276,
  WomanFacepalming = 277,
  PersonShrugging = 278,
  ManShrugging = 279,
  WomanShrugging = 280,
  HealthWorker = 281,
  ManHealthWorker = 282,
  WomanHealthWorker = 283,
  Student = 284,
  ManStudent = 285,
  WomanStudent = 286,
  Teacher = 287,
  ManTeacher = 288,
  WomanTeacher = 289,
  Judge = 290,
  ManJudge = 291,
  WomanJudge = 292,
  Farmer = 293,
  ManFarmer = 294,
  WomanFarmer = 295,
  Cook = 296,
  ManCook = 297,
  WomanCook = 298,
  Mechanic = 299,
  ManMechanic = 300,
  WomanMechanic = 301,
  FactoryWorker = 302,
  ManFactoryWorker = 303,
  WomanFactoryWorker = 304,
  OfficeWorker = 305,
  ManOfficeWorker = 306,
  WomanOfficeWorker = 307,
  Scientist = 308,
  ManScientist = 309,
  WomanScientist = 310,
  Singer = 314,
  ManSinger = 315,
  WomanSinger = 316,
  Artist = 317,
  ManArtist = 318,
  WomanArtist = 319,
  Pilot = 320,
  ManPilot = 321,
  WomanPilot = 322,
  Astronaut = 323,
  ManAstronaut = 324,
  WomanAstronaut = 325,
  Firefighter = 326,
  ManFirefighter = 327,
  WomanFirefighter = 328,
  PoliceOfficer = 329,
  ManPoliceOfficer = 330,
  WomanPoliceOfficer = 331,
  Detective = 332,
  ManDetective = 333,
  WomanDetective = 334,
  Guard = 335,
  ManGuard = 336,
  WomanGuard = 337,
  Ninja = 338,
  ConstructionWorker = 339,
  ManConstructionWorker = 340,
  WomanConstructionWorker = 341,
  PersonWithCrown = 342,
  Prince = 343,
  Princess = 344,
  PersonWearingTurban = 345,
  ManWearingTurban = 346,
  WomanWearingTurban = 347,
  PersonWithSkullcap = 348,
  WomanWithHeadscarf = 349,
  PersonInTuxedo = 350,
  ManInTuxedo = 351,
  WomanInTuxedo = 352,
  PersonWithVeil = 353,
  ManWithVeil = 354,
  WomanWithVeil = 355,
  PregnantWoman = 356,
  PregnantMan = 357,
  PregnantPerson = 358,
  BreastFeeding = 359,
  WomanFeedingBaby = 360,
  ManFeedingBaby = 361,
  PersonFeedingBaby = 362,
  BabyAngel = 363,
  SantaClaus = 364,
  MrsClaus = 365,
  MxClaus = 366,
  Superhero = 367,
  ManSuperhero = 368,
  WomanSuperhero = 369,
  Supervillain = 370,
  ManSupervillain = 371,
  WomanSupervillain = 372,
  Mage = 373,
  ManMage = 374,
  WomanMage = 375,
  Fairy = 376,
  ManFairy = 377,
  WomanFairy = 378,
  Vampire = 379,
  ManVampire = 380,
  WomanVampire = 381,
  Merperson = 382,
  Merman = 383,
  Mermaid = 384,
  Elf = 385,
  ManElf = 386,
  WomanElf = 387,
  Genie = 388,
  ManGenie = 389,
  WomanGenie = 390,
  Zombie = 391,
  ManZombie = 392,
  WomanZombie = 393,
  Troll = 394,
  PersonGettingMassage = 395,
  ManGettingMassage = 396,
  WomanGettingMassage = 397,
  PersonGettingHaircut = 398,
  ManGettingHaircut = 399,
  WomanGettingHaircut = 400,
  PersonWalking = 401,
  ManWalking = 402,
  WomanWalking = 403,
  PersonStanding = 404,
  ManStanding = 405,
  WomanStanding = 406,
  PersonKneeling = 407,
  ManKneeling = 408,
  WomanKneeling = 409,
  PersonWithWhiteCane = 410,
  ManWithWhiteCane = 411,
  WomanWithWhiteCane = 412,
  PersonInMotorizedWheelchair = 413,
  ManInMotorizedWheelchair = 414,
  WomanInMotorizedWheelchair = 415,
  PersonInManualWheelchair = 416,
  ManInManualWheelchair = 417,
  WomanInManualWheelchair = 418,
  PersonRunning = 419,
  ManRunning = 420,
  WomanRunning = 421,
  WomanDancing = 422,
  ManDancing = 423,
  PersonInSuitLevitating = 424,
  PeopleWithBunnyEars = 425,
  MenWithBunnyEars = 426,
  WomenWithBunnyEars = 427,
  PersonInSteamyRoom = 428,
  ManInSteamyRoom = 429,
  WomanInSteamyRoom = 430,
  PersonClimbing = 431,
  ManClimbing = 432,
  WomanClimbing = 433,
  PersonFencing = 434,
  HorseRacing = 435,
  Skier = 436,
  Snowboarder = 437,
  PersonGolfing = 438,
  ManGolfing = 439,
  WomanGolfing = 440,
  PersonSurfing = 441,
  ManSurfing = 442,
  WomanSurfing = 443,
  PersonRowingBoat = 444,
  ManRowingBoat = 445,
  WomanRowingBoat = 446,
  PersonSwimming = 447,
  ManSwimming = 448,
  WomanSwimming = 449,
  PersonBouncingBall = 450,
  ManBouncingBall = 451,
  WomanBouncingBall = 452,
  PersonLiftingWeights = 453,
  ManLiftingWeights = 454,
  WomanLiftingWeights = 455,
  PersonBiking = 456,
  ManBiking = 457,
  WomanBiking = 458,
  PersonMountainBiking = 459,
  ManMountainBiking = 460,
  WomanMountainBiking = 461,
  PersonCartwheeling = 462,
  ManCartwheeling = 463,
  WomanCartwheeling = 464,
  PeopleWrestling = 465,
  MenWrestling = 466,
  WomenWrestling = 467,
  PersonPlayingWaterPolo = 468,
  ManPlayingWaterPolo = 469,
  WomanPlayingWaterPolo = 470,
  PersonPlayingHandball = 471,
  ManPlayingHandball = 472,
  WomanPlayingHandball = 473,
  PersonJuggling = 474,
  ManJuggling = 475,
  WomanJuggling = 476,
  PersonInLotusPosition = 477,
  ManInLotusPosition = 478,
  WomanInLotusPosition = 479,
  PersonTakingBath = 480,
  PersonInBed = 481,
  SpeakingHead = 520,
  BustInSilhouette = 521,
  BustsInSilhouette = 522,
  PeopleHugging = 523,
  Footprints = 524,
  MonkeyFace = 530,
  Monkey = 531,
  Gorilla = 532,
  Orangutan = 533,
  DogFace = 534,
  Dog = 535,
  GuideDog = 536,
  ServiceDog = 537,
  Poodle = 538,
  Wolf = 539,
  Fox = 540,
  Raccoon = 541,
  CatFace = 542,
  Cat = 543,
  BlackCat = 544,
  Lion = 545,
  TigerFace = 546,
  Tiger = 547,
  Leopard = 548,
  HorseFace = 549,
  Horse = 550,
  Unicorn = 551,
  Zebra = 552,
  Deer = 553,
  Bison = 554,
  CowFace = 555,
  Ox = 556,
  WaterBuffalo = 557,
  Cow = 558,
  PigFace = 559,
  Pig = 560,
  Boar = 561,
  PigNose = 562,
  Ram = 563,
  Ewe = 564,
  Goat = 565,
  Camel = 566,
  TwoHumpCamel = 567,
  Llama = 568,
  Giraffe = 569,
  Elephant = 570,
  Mammoth = 571,
  Rhinoceros = 572,
  Hippopotamus = 573,
  MouseFace = 574,
  Mouse = 575,
  Rat = 576,
  Hamster = 577,
  RabbitFace = 578,
  Rabbit = 579,
  Chipmunk = 580,
  Beaver = 581,
  Hedgehog = 582,
  Bat = 583,
  Bear = 584,
  PolarBear = 585,
  Koala = 586,
  Panda = 587,
  Sloth = 588,
  Otter = 589,
  Skunk = 590,
  Kangaroo = 591,
  Badger = 592,
  PawPrints = 593,
  Turkey = 594,
  Chicken = 595,
  Rooster = 596,
  HatchingChick = 597,
  BabyChick = 598,
  FrontFacingBabyChick = 599,
  Bird = 600,
  Penguin = 601,
  Dove = 602,
  Eagle = 603,
  Duck = 604,
  Swan = 605,
  Owl = 606,
  Dodo = 607,
  Feather = 608,
  Flamingo = 609,
  Peacock = 610,
  Parrot = 611,
  Frog = 612,
  Crocodile = 613,
  Turtle = 614,
  Lizard = 615,
  Snake = 616,
  DragonFace = 617,
  Dragon = 618,
  Sauropod = 619,
  TRex = 620,
  SpoutingWhale = 621,
  Whale = 622,
  Dolphin = 623,
  Seal = 624,
  Fish = 625,
  TropicalFish = 626,
  Blowfish = 627,
  Shark = 628,
  Octopus = 629,
  SpiralShell = 630,
  Coral = 631,
  Snail = 632,
  Butterfly = 633,
  Bug = 634,
  Ant = 635,
  Honeybee = 636,
  Beetle = 637,
  LadyBeetle = 638,
  Cricket = 639,
  Cockroach = 640,
  Spider = 641,
  SpiderWeb = 642,
  Scorpion = 643,
  Mosquito = 644,
  Fly = 645,
  Worm = 646,
  Microbe = 647,
  Bouquet = 648,
  CherryBlossom = 649,
  WhiteFlower = 650,
  Lotus = 651,
  Rosette = 652,
  Rose = 653,
  WiltedFlower = 654,
  Hibiscus = 655,
  Sunflower = 656,
  Blossom = 657,
  Tulip = 658,
  Seedling = 659,
  PottedPlant = 660,
  EvergreenTree = 661,
  DeciduousTree = 662,
  PalmTree = 663,
  Cactus = 664,
  SheafOfRice = 665,
  Herb = 666,
  Shamrock = 667,
  FourLeafClover = 668,
  MapleLeaf = 669,
  FallenLeaf = 670,
  LeafFlutteringInWind = 671,
  EmptyNest = 672,
  NestWithEggs = 673,
  Grapes = 674,
  Melon = 675,
  Watermelon = 676,
  Tangerine = 677,
  Lemon = 678,
  Banana = 679,
  Pineapple = 680,
  Mango = 681,
  RedApple = 682,
  GreenApple = 683,
  Pear = 684,
  Peach = 685,
  Cherries = 686,
  Strawberry = 687,
  Blueberries = 688,
  KiwiFruit = 689,
  Tomato = 690,
  Olive = 691,
  Coconut = 692,
  Avocado = 693,
  Eggplant = 694,
  Potato = 695,
  Carrot = 696,
  EarOfCorn = 697,
  HotPepper = 698,
  BellPepper = 699,
  Cucumber = 700,
  LeafyGreen = 701,
  Broccoli = 702,
  Garlic = 703,
  Onion = 704,
  Mushroom = 705,
  Peanuts = 706,
  Beans = 707,
  Chestnut = 708,
  Bread = 709,
  Croissant = 710,
  BaguetteBread = 711,
  Flatbread = 712,
  Pretzel = 713,
  Bagel = 714,
  Pancakes = 715,
  Waffle = 716,
  CheeseWedge = 717,
  MeatOnBone = 718,
  PoultryLeg = 719,
  CutOfMeat = 720,
  Bacon = 721,
  Hamburger = 722,
  FrenchFries = 723,
  Pizza = 724,
  HotDog = 725,
  Sandwich = 726,
  Taco = 727,
  Burrito = 728,
  Tamale = 729,
  StuffedFlatbread = 730,
  Falafel = 731,
  Egg = 732,
  Cooking = 733,
  ShallowPanOfFood = 734,
  PotOfFood = 735,
  Fondue = 736,
  BowlWithSpoon = 737,
  GreenSalad = 738,
  Popcorn = 739,
  Butter = 740,
  Salt = 741,
  CannedFood = 742,
  BentoBox = 743,
  RiceCracker = 744,
  RiceBall = 745,
  CookedRice = 746,
  CurryRice = 747,
  SteamingBowl = 748,
  Spaghetti = 749,
  RoastedSweetPotato = 750,
  Oden = 751,
  Sushi = 752,
  FriedShrimp = 753,
  FishCakeWithSwirl = 754,
  MoonCake = 755,
  Dango = 756,
  Dumpling = 757,
  FortuneCookie = 758,
  TakeoutBox = 759,
  Crab = 760,
  Lobster = 761,
  Shrimp = 762,
  Squid = 763,
  Oyster = 764,
  SoftIceCream = 765,
  ShavedIce = 766,
  IceCream = 767,
  Doughnut = 768,
  Cookie = 769,
  BirthdayCake = 770,
  Shortcake = 771,
  Cupcake = 772,
  Pie = 773,
  ChocolateBar = 774,
  Candy = 775,
  Lollipop = 776,
  Custard = 777,
  HoneyPot = 778,
  BabyBottle = 779,
  GlassOfMilk = 780,
  HotBeverage = 781,
  Teapot = 782,
  TeacupWithoutHandle = 783,
  Sake = 784,
  BottleWithPoppingCork = 785,
  WineGlass = 786,
  CocktailGlass = 787,
  TropicalDrink = 788,
  BeerMug = 789,
  ClinkingBeerMugs = 790,
  ClinkingGlasses = 791,
  TumblerGlass = 792,
  PouringLiquid = 793,
  CupWithStraw = 794,
  BubbleTea = 795,
  BeverageBox = 796,
  Mate = 797,
  Ice = 798,
  Chopsticks = 799,
  ForkAndKnifeWithPlate = 800,
  ForkAndKnife = 801,
  Spoon = 802,
  KitchenKnife = 803,
  Jar = 804,
  Amphora = 805,
  GlobeShowingEuropeAfrica = 806,
  GlobeShowingAmericas = 807,
  GlobeShowingAsiaAustralia = 808,
  GlobeWithMeridians = 809,
  WorldMap = 810,
  MapOfJapan = 811,
  Compass = 812,
  SnowCappedMountain = 813,
  Mountain = 814,
  Volcano = 815,
  MountFuji = 816,
  Camping = 817,
  BeachWithUmbrella = 818,
  Desert = 819,
  DesertIsland = 820,
  NationalPark = 821,
  Stadium = 822,
  ClassicalBuilding = 823,
  BuildingConstruction = 824,
  Brick = 825,
  Rock = 826,
  Wood = 827,
  Hut = 828,
  Houses = 829,
  DerelictHouse = 830,
  House = 831,
  HouseWithGarden = 832,
  OfficeBuilding = 833,
  JapanesePostOffice = 834,
  PostOffice = 835,
  Hospital = 836,
  Bank = 837,
  Hotel = 838,
  LoveHotel = 839,
  ConvenienceStore = 840,
  School = 841,
  DepartmentStore = 842,
  Factory = 843,
  JapaneseCastle = 844,
  Castle = 845,
  Wedding = 846,
  TokyoTower = 847,
  StatueOfLiberty = 848,
  Church = 849,
  Mosque = 850,
  HinduTemple = 851,
  Synagogue = 852,
  ShintoShrine = 853,
  Kaaba = 854,
  Fountain = 855,
  Tent = 856,
  Foggy = 857,
  NightWithStars = 858,
  Cityscape = 859,
  SunriseOverMountains = 860,
  Sunrise = 861,
  CityscapeAtDusk = 862,
  Sunset = 863,
  BridgeAtNight = 864,
  HotSprings = 865,
  CarouselHorse = 866,
  PlaygroundSlide = 867,
  FerrisWheel = 868,
  RollerCoaster = 869,
  BarberPole = 870,
  CircusTent = 871,
  Locomotive = 872,
  RailwayCar = 873,
  HighSpeedTrain = 874,
  BulletTrain = 875,
  Train = 876,
  Metro = 877,
  LightRail = 878,
  Station = 879,
  Tram = 880,
  Monorail = 881,
  MountainRailway = 882,
  TramCar = 883,
  Bus = 884,
  OncomingBus = 885,
  Trolleybus = 886,
  Minibus = 887,
  Ambulance = 888,
  FireEngine = 889,
  PoliceCar = 890,
  OncomingPoliceCar = 891,
  Taxi = 892,
  OncomingTaxi = 893,
  Automobile = 894,
  OncomingAutomobile = 895,
  SportUtilityVehicle = 896,
  PickupTruck = 897,
  DeliveryTruck = 898,
  ArticulatedLorry = 899,
  Tractor = 900,
  RacingCar = 901,
  Motorcycle = 902,
  MotorScooter = 903,
  ManualWheelchair = 904,
  MotorizedWheelchair = 905,
  AutoRickshaw = 906,
  Bicycle = 907,
  KickScooter = 908,
  Skateboard = 909,
  RollerSkate = 910,
  BusStop = 911,
  Motorway = 912,
  RailwayTrack = 913,
  OilDrum = 914,
  FuelPump = 915,
  Wheel = 916,
  PoliceCarLight = 917,
  HorizontalTrafficLight = 918,
  VerticalTrafficLight = 919,
  StopSign = 920,
  Construction = 921,
  Anchor = 922,
  RingBuoy = 923,
  Sailboat = 924,
  Canoe = 925,
  Speedboat = 926,
  PassengerShip = 927,
  Ferry = 928,
  MotorBoat = 929,
  Ship = 930,
  Airplane = 931,
  SmallAirplane = 932,
  AirplaneDeparture = 933,
  AirplaneArrival = 934,
  Parachute = 935,
  Seat = 936,
  Helicopter = 937,
  SuspensionRailway = 938,
  MountainCableway = 939,
  AerialTramway = 940,
  Satellite = 941,
  Rocket = 942,
  FlyingSaucer = 943,
  BellhopBell = 944,
  Luggage = 945,
  HourglassDone = 946,
  HourglassNotDone = 947,
  Watch = 948,
  AlarmClock = 949,
  Stopwatch = 950,
  TimerClock = 951,
  MantelpieceClock = 952,
  TwelveOclock = 953,
  TwelveThirty = 954,
  OneOclock = 955,
  OneThirty = 956,
  TwoOclock = 957,
  TwoThirty = 958,
  ThreeOclock = 959,
  ThreeThirty = 960,
  FourOclock = 961,
  FourThirty = 962,
  FiveOclock = 963,
  FiveThirty = 964,
  SixOclock = 965,
  SixThirty = 966,
  SevenOclock = 967,
  SevenThirty = 968,
  EightOclock = 969,
  EightThirty = 970,
  NineOclock = 971,
  NineThirty = 972,
  TenOclock = 973,
  TenThirty = 974,
  ElevenOclock = 975,
  ElevenThirty = 976,
  NewMoon = 977,
  WaxingCrescentMoon = 978,
  FirstQuarterMoon = 979,
  WaxingGibbousMoon = 980,
  FullMoon = 981,
  WaningGibbousMoon = 982,
  LastQuarterMoon = 983,
  WaningCrescentMoon = 984,
  CrescentMoon = 985,
  NewMoonFace = 986,
  FirstQuarterMoonFace = 987,
  LastQuarterMoonFace = 988,
  Thermometer = 989,
  Sun = 990,
  FullMoonFace = 991,
  SunWithFace = 992,
  RingedPlanet = 993,
  Star = 994,
  GlowingStar = 995,
  ShootingStar = 996,
  MilkyWay = 997,
  Cloud = 998,
  SunBehindCloud = 999,
  CloudWithLightningAndRain = 1000,
  SunBehindSmallCloud = 1001,
  SunBehindLargeCloud = 1002,
  SunBehindRainCloud = 1003,
  CloudWithRain = 1004,
  CloudWithSnow = 1005,
  CloudWithLightning = 1006,
  Tornado = 1007,
  Fog = 1008,
  WindFace = 1009,
  Cyclone = 1010,
  Rainbow = 1011,
  ClosedUmbrella = 1012,
  Umbrella = 1013,
  UmbrellaWithRainDrops = 1014,
  UmbrellaOnGround = 1015,
  HighVoltage = 1016,
  Snowflake = 1017,
  Snowman = 1018,
  SnowmanWithoutSnow = 1019,
  Comet = 1020,
  Fire = 1021,
  Droplet = 1022,
  WaterWave = 1023,
  JackOLantern = 1024,
  ChristmasTree = 1025,
  Fireworks = 1026,
  Sparkler = 1027,
  Firecracker = 1028,
  Sparkles = 1029,
  Balloon = 1030,
  PartyPopper = 1031,
  ConfettiBall = 1032,
  TanabataTree = 1033,
  PineDecoration = 1034,
  JapaneseDolls = 1035,
  CarpStreamer = 1036,
  WindChime = 1037,
  MoonViewingCeremony = 1038,
  RedEnvelope = 1039,
  Ribbon = 1040,
  WrappedGift = 1041,
  ReminderRibbon = 1042,
  AdmissionTickets = 1043,
  Ticket = 1044,
  MilitaryMedal = 1045,
  Trophy = 1046,
  SportsMedal = 1047,
  _1stPlaceMedal = 1048,
  _2ndPlaceMedal = 1049,
  _3rdPlaceMedal = 1050,
  SoccerBall = 1051,
  Baseball = 1052,
  Softball = 1053,
  Basketball = 1054,
  Volleyball = 1055,
  AmericanFootball = 1056,
  RugbyFootball = 1057,
  Tennis = 1058,
  FlyingDisc = 1059,
  Bowling = 1060,
  CricketGame = 1061,
  FieldHockey = 1062,
  IceHockey = 1063,
  Lacrosse = 1064,
  PingPong = 1065,
  Badminton = 1066,
  BoxingGlove = 1067,
  MartialArtsUniform = 1068,
  GoalNet = 1069,
  FlagInHole = 1070,
  IceSkate = 1071,
  FishingPole = 1072,
  DivingMask = 1073,
  RunningShirt = 1074,
  Skis = 1075,
  Sled = 1076,
  CurlingStone = 1077,
  Bullseye = 1078,
  YoYo = 1079,
  Kite = 1080,
  Pool8Ball = 1081,
  CrystalBall = 1082,
  MagicWand = 1083,
  NazarAmulet = 1084,
  Hamsa = 1085,
  Joystick = 1087,
  SlotMachine = 1088,
  GameDie = 1089,
  PuzzlePiece = 1090,
  TeddyBear = 1091,
  Piñata = 1092,
  MirrorBall = 1093,
  NestingDolls = 1094,
  SpadeSuit = 1095,
  HeartSuit = 1096,
  DiamondSuit = 1097,
  ClubSuit = 1098,
  ChessPawn = 1099,
  Joker = 1100,
  MahjongRedDragon = 1101,
  FlowerPlayingCards = 1102,
  PerformingArts = 1103,
  FramedPicture = 1104,
  ArtistPalette = 1105,
  Thread = 1106,
  SewingNeedle = 1107,
  Yarn = 1108,
  Knot = 1109,
  Glasses = 1110,
  Sunglasses = 1111,
  Goggles = 1112,
  LabCoat = 1113,
  SafetyVest = 1114,
  Necktie = 1115,
  TShirt = 1116,
  Jeans = 1117,
  Scarf = 1118,
  Gloves = 1119,
  Coat = 1120,
  Socks = 1121,
  Dress = 1122,
  Kimono = 1123,
  Sari = 1124,
  OnePieceSwimsuit = 1125,
  Briefs = 1126,
  Shorts = 1127,
  Bikini = 1128,
  WomansClothes = 1129,
  Purse = 1130,
  Handbag = 1131,
  ClutchBag = 1132,
  ShoppingBags = 1133,
  Backpack = 1134,
  ThongSandal = 1135,
  MansShoe = 1136,
  RunningShoe = 1137,
  HikingBoot = 1138,
  FlatShoe = 1139,
  HighHeeledShoe = 1140,
  WomansSandal = 1141,
  BalletShoes = 1142,
  WomansBoot = 1143,
  Crown = 1144,
  WomansHat = 1145,
  TopHat = 1146,
  GraduationCap = 1147,
  BilledCap = 1148,
  MilitaryHelmet = 1149,
  RescueWorkersHelmet = 1150,
  PrayerBeads = 1151,
  Lipstick = 1152,
  Ring = 1153,
  GemStone = 1154,
  MutedSpeaker = 1155,
  SpeakerLowVolume = 1156,
  SpeakerMediumVolume = 1157,
  SpeakerHighVolume = 1158,
  Loudspeaker = 1159,
  Megaphone = 1160,
  PostalHorn = 1161,
  Bell = 1162,
  BellWithSlash = 1163,
  MusicalScore = 1164,
  MusicalNote = 1165,
  MusicalNotes = 1166,
  StudioMicrophone = 1167,
  LevelSlider = 1168,
  ControlKnobs = 1169,
  Microphone = 1170,
  Headphone = 1171,
  Radio = 1172,
  Saxophone = 1173,
  Accordion = 1174,
  Guitar = 1175,
  MusicalKeyboard = 1176,
  Trumpet = 1177,
  Violin = 1178,
  Banjo = 1179,
  Drum = 1180,
  LongDrum = 1181,
  MobilePhone = 1182,
  MobilePhoneWithArrow = 1183,
  Telephone = 1184,
  TelephoneReceiver = 1185,
  Pager = 1186,
  FaxMachine = 1187,
  Battery = 1188,
  LowBattery = 1189,
  ElectricPlug = 1190,
  Laptop = 1191,
  DesktopComputer = 1192,
  Printer = 1193,
  Keyboard = 1194,
  ComputerMouse = 1195,
  Trackball = 1196,
  ComputerDisk = 1197,
  FloppyDisk = 1198,
  OpticalDisk = 1199,
  Dvd = 1200,
  Abacus = 1201,
  MovieCamera = 1202,
  FilmFrames = 1203,
  FilmProjector = 1204,
  ClapperBoard = 1205,
  Television = 1206,
  Camera = 1207,
  CameraWithFlash = 1208,
  VideoCamera = 1209,
  Videocassette = 1210,
  MagnifyingGlassTiltedLeft = 1211,
  MagnifyingGlassTiltedRight = 1212,
  Candle = 1213,
  LightBulb = 1214,
  Flashlight = 1215,
  RedPaperLantern = 1216,
  DiyaLamp = 1217,
  NotebookWithDecorativeCover = 1218,
  ClosedBook = 1219,
  OpenBook = 1220,
  GreenBook = 1221,
  BlueBook = 1222,
  OrangeBook = 1223,
  Books = 1224,
  Notebook = 1225,
  Ledger = 1226,
  PageWithCurl = 1227,
  Scroll = 1228,
  PageFacingUp = 1229,
  Newspaper = 1230,
  RolledUpNewspaper = 1231,
  BookmarkTabs = 1232,
  Bookmark = 1233,
  Label = 1234,
  MoneyBag = 1235,
  Coin = 1236,
  YenBanknote = 1237,
  DollarBanknote = 1238,
  EuroBanknote = 1239,
  PoundBanknote = 1240,
  MoneyWithWings = 1241,
  CreditCard = 1242,
  Receipt = 1243,
  ChartIncreasingWithYen = 1244,
  Envelope = 1245,
  EMail = 1246,
  IncomingEnvelope = 1247,
  EnvelopeWithArrow = 1248,
  OutboxTray = 1249,
  InboxTray = 1250,
  Package = 1251,
  ClosedMailboxWithRaisedFlag = 1252,
  ClosedMailboxWithLoweredFlag = 1253,
  OpenMailboxWithRaisedFlag = 1254,
  OpenMailboxWithLoweredFlag = 1255,
  Postbox = 1256,
  BallotBoxWithBallot = 1257,
  Pencil = 1258,
  BlackNib = 1259,
  FountainPen = 1260,
  Pen = 1261,
  Paintbrush = 1262,
  Crayon = 1263,
  Memo = 1264,
  Briefcase = 1265,
  FileFolder = 1266,
  OpenFileFolder = 1267,
  CardIndexDividers = 1268,
  Calendar = 1269,
  TearOffCalendar = 1270,
  SpiralNotepad = 1271,
  SpiralCalendar = 1272,
  CardIndex = 1273,
  ChartIncreasing = 1274,
  ChartDecreasing = 1275,
  BarChart = 1276,
  Clipboard = 1277,
  Pushpin = 1278,
  RoundPushpin = 1279,
  LinkedPaperclips = 1281,
  StraightRuler = 1282,
  TriangularRuler = 1283,
  Scissors = 1284,
  CardFileBox = 1285,
  FileCabinet = 1286,
  Wastebasket = 1287,
  Locked = 1288,
  Unlocked = 1289,
  LockedWithPen = 1290,
  LockedWithKey = 1291,
  Key = 1292,
  OldKey = 1293,
  Hammer = 1294,
  Axe = 1295,
  Pick = 1296,
  HammerAndPick = 1297,
  HammerAndWrench = 1298,
  Dagger = 1299,
  CrossedSwords = 1300,
  WaterPistol = 1301,
  Boomerang = 1302,
  BowAndArrow = 1303,
  Shield = 1304,
  CarpentrySaw = 1305,
  Wrench = 1306,
  Screwdriver = 1307,
  NutAndBolt = 1308,
  Gear = 1309,
  Clamp = 1310,
  BalanceScale = 1311,
  WhiteCane = 1312,
  Link = 1313,
  Chains = 1314,
  Hook = 1315,
  Toolbox = 1316,
  Magnet = 1317,
  Ladder = 1318,
  Alembic = 1319,
  TestTube = 1320,
  PetriDish = 1321,
  Dna = 1322,
  Microscope = 1323,
  Telescope = 1324,
  SatelliteAntenna = 1325,
  Syringe = 1326,
  DropOfBlood = 1327,
  Pill = 1328,
  AdhesiveBandage = 1329,
  Crutch = 1330,
  Stethoscope = 1331,
  XRay = 1332,
  Door = 1333,
  Elevator = 1334,
  Mirror = 1335,
  Window = 1336,
  Bed = 1337,
  CouchAndLamp = 1338,
  Chair = 1339,
  Toilet = 1340,
  Plunger = 1341,
  Shower = 1342,
  Bathtub = 1343,
  MouseTrap = 1344,
  Razor = 1345,
  LotionBottle = 1346,
  SafetyPin = 1347,
  Broom = 1348,
  Basket = 1349,
  RollOfPaper = 1350,
  Bucket = 1351,
  Soap = 1352,
  Bubbles = 1353,
  Toothbrush = 1354,
  Sponge = 1355,
  FireExtinguisher = 1356,
  ShoppingCart = 1357,
  Cigarette = 1358,
  Coffin = 1359,
  Headstone = 1360,
  FuneralUrn = 1361,
  Moai = 1362,
  Placard = 1363,
  IdentificationCard = 1364,
  ATMSign = 1365,
  LitterInBinSign = 1366,
  PotableWater = 1367,
  WheelchairSymbol = 1368,
  MensRoom = 1369,
  WomensRoom = 1370,
  Restroom = 1371,
  BabySymbol = 1372,
  WaterCloset = 1373,
  PassportControl = 1374,
  Customs = 1375,
  BaggageClaim = 1376,
  LeftLuggage = 1377,
  Warning = 1378,
  ChildrenCrossing = 1379,
  NoEntry = 1380,
  Prohibited = 1381,
  NoBicycles = 1382,
  NoSmoking = 1383,
  NoLittering = 1384,
  NonPotableWater = 1385,
  NoPedestrians = 1386,
  NoMobilePhones = 1387,
  NoOneUnderEighteen = 1388,
  Radioactive = 1389,
  Biohazard = 1390,
  UpArrow = 1391,
  UpRightArrow = 1392,
  RightArrow = 1393,
  DownRightArrow = 1394,
  DownArrow = 1395,
  DownLeftArrow = 1396,
  LeftArrow = 1397,
  UpLeftArrow = 1398,
  UpDownArrow = 1399,
  LeftRightArrow = 1400,
  RightArrowCurvingLeft = 1401,
  LeftArrowCurvingRight = 1402,
  RightArrowCurvingUp = 1403,
  RightArrowCurvingDown = 1404,
  ClockwiseVerticalArrows = 1405,
  CounterclockwiseArrowsButton = 1406,
  BACKArrow = 1407,
  ENDArrow = 1408,
  ONArrow = 1409,
  SOONArrow = 1410,
  TOPArrow = 1411,
  PlaceOfWorship = 1412,
  AtomSymbol = 1413,
  Om = 1414,
  StarOfDavid = 1415,
  WheelOfDharma = 1416,
  YinYang = 1417,
  LatinCross = 1418,
  OrthodoxCross = 1419,
  StarAndCrescent = 1420,
  PeaceSymbol = 1421,
  Menorah = 1422,
  DottedSixPointedStar = 1423,
  Aries = 1424,
  Taurus = 1425,
  Gemini = 1426,
  Cancer = 1427,
  Leo = 1428,
  Virgo = 1429,
  Libra = 1430,
  Scorpio = 1431,
  Sagittarius = 1432,
  Capricorn = 1433,
  Aquarius = 1434,
  Pisces = 1435,
  Ophiuchus = 1436,
  ShuffleTracksButton = 1437,
  RepeatButton = 1438,
  RepeatSingleButton = 1439,
  PlayButton = 1440,
  FastForwardButton = 1441,
  NextTrackButton = 1442,
  PlayOrPauseButton = 1443,
  ReverseButton = 1444,
  FastReverseButton = 1445,
  LastTrackButton = 1446,
  UpwardsButton = 1447,
  FastUpButton = 1448,
  DownwardsButton = 1449,
  FastDownButton = 1450,
  PauseButton = 1451,
  StopButton = 1452,
  RecordButton = 1453,
  EjectButton = 1454,
  Cinema = 1455,
  DimButton = 1456,
  BrightButton = 1457,
  AntennaBars = 1458,
  VibrationMode = 1459,
  MobilePhoneOff = 1460,
  FemaleSign = 1461,
  MaleSign = 1462,
  TransgenderSymbol = 1463,
  Multiply = 1464,
  Plus = 1465,
  Minus = 1466,
  Divide = 1467,
  HeavyEqualsSign = 1468,
  Infinity = 1469,
  DoubleExclamationMark = 1470,
  ExclamationQuestionMark = 1471,
  RedQuestionMark = 1472,
  WhiteQuestionMark = 1473,
  WhiteExclamationMark = 1474,
  RedExclamationMark = 1475,
  WavyDash = 1476,
  CurrencyExchange = 1477,
  HeavyDollarSign = 1478,
  MedicalSymbol = 1479,
  RecyclingSymbol = 1480,
  FleurDeLis = 1481,
  TridentEmblem = 1482,
  NameBadge = 1483,
  JapaneseSymbolForBeginner = 1484,
  HollowRedCircle = 1485,
  CheckMarkButton = 1486,
  CheckBoxWithCheck = 1487,
  CheckMark = 1488,
  CrossMark = 1489,
  CrossMarkButton = 1490,
  CurlyLoop = 1491,
  DoubleCurlyLoop = 1492,
  PartAlternationMark = 1493,
  EightSpokedAsterisk = 1494,
  EightPointedStar = 1495,
  Sparkle = 1496,
  Copyright = 1497,
  Registered = 1498,
  TradeMark = 1499,
  KeycapHash = 1500,
  KeycapAsterisk = 1501,
  Keycap0 = 1502,
  Keycap1 = 1503,
  Keycap2 = 1504,
  Keycap3 = 1505,
  Keycap4 = 1506,
  Keycap5 = 1507,
  Keycap6 = 1508,
  Keycap7 = 1509,
  Keycap8 = 1510,
  Keycap9 = 1511,
  Keycap10 = 1512,
  InputLatinUppercase = 1513,
  InputLatinLowercase = 1514,
  InputNumbers = 1515,
  InputSymbols = 1516,
  InputLatinLetters = 1517,
  AButtonBloodType = 1518,
  ABButtonBloodType = 1519,
  BButtonBloodType = 1520,
  CLButton = 1521,
  COOLButton = 1522,
  FREEButton = 1523,
  Information = 1524,
  IDButton = 1525,
  CircledM = 1526,
  NEWButton = 1527,
  NGButton = 1528,
  OButtonBloodType = 1529,
  OKButton = 1530,
  PButton = 1531,
  SOSButton = 1532,
  UPButton = 1533,
  VSButton = 1534,
  JapaneseHereButton = 1535,
  JapaneseServiceChargeButton = 1536,
  JapaneseMonthlyAmountButton = 1537,
  JapaneseNotFreeOfChargeButton = 1538,
  JapaneseReservedButton = 1539,
  JapaneseBargainButton = 1540,
  JapaneseDiscountButton = 1541,
  JapaneseFreeOfChargeButton = 1542,
  JapaneseProhibitedButton = 1543,
  JapaneseAcceptableButton = 1544,
  JapaneseApplicationButton = 1545,
  JapanesePassingGradeButton = 1546,
  JapaneseVacancyButton = 1547,
  JapaneseCongratulationsButton = 1548,
  JapaneseSecretButton = 1549,
  JapaneseOpenForBusinessButton = 1550,
  JapaneseNoVacancyButton = 1551,
  RedCircle = 1552,
  OrangeCircle = 1553,
  YellowCircle = 1554,
  GreenCircle = 1555,
  BlueCircle = 1556,
  PurpleCircle = 1557,
  BrownCircle = 1558,
  BlackCircle = 1559,
  WhiteCircle = 1560,
  RedSquare = 1561,
  OrangeSquare = 1562,
  YellowSquare = 1563,
  GreenSquare = 1564,
  BlueSquare = 1565,
  PurpleSquare = 1566,
  BrownSquare = 1567,
  BlackLargeSquare = 1568,
  WhiteLargeSquare = 1569,
  BlackMediumSquare = 1570,
  WhiteMediumSquare = 1571,
  BlackMediumSmallSquare = 1572,
  WhiteMediumSmallSquare = 1573,
  BlackSmallSquare = 1574,
  WhiteSmallSquare = 1575,
  LargeOrangeDiamond = 1576,
  LargeBlueDiamond = 1577,
  SmallOrangeDiamond = 1578,
  SmallBlueDiamond = 1579,
  RedTrianglePointedUp = 1580,
  RedTrianglePointedDown = 1581,
  DiamondWithADot = 1582,
  RadioButton = 1583,
  WhiteSquareButton = 1584,
  BlackSquareButton = 1585,
  ChequeredFlag = 1586,
  TriangularFlag = 1587,
  CrossedFlags = 1588,
  BlackFlag = 1589,
  WhiteFlag = 1590,
  RainbowFlag = 1591,
  TransgenderFlag = 1592,
  PirateFlag = 1593,
}

/**
 * It's set in the appResolver
 */
export const EmojiMap = new Map<number, Emoji>();

<alto-impersonation-header></alto-impersonation-header>
<div class="panel-full d-flex justify-content-center align-items-center">
  <img
    class="position-absolute top-0 end-0 background-img m-7"
    src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Confused%20face/Color/confused_face_color.svg"
  />
  <div class="text-center text-404">
    <img
      class="emoji"
      src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Confused%20face/Color/confused_face_color.svg"
      height="48"
    />
    <h1 class="my-5">{{ I18ns.shared.noCompany.title }}</h1>
    <p class="fs-5">{{ I18ns.shared.noCompany.subtitle }}</p>
    <a class="btn btn-primary mt-6" href="mailto:romain@usealto.com">
      <i class="bi bi-envelope"></i>
      <span>{{ I18ns.shared.noCompany.button }}</span>
    </a>
  </div>
</div>

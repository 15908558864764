  <img
    class="position-absolute top-0 end-0 background-img w-40 m-7"
    src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Confused%20face/Color/confused_face_color.svg"
  />
<div class="panel-full d-flex justify-content-center align-items-center">
  <div class="text-center text-404">
    <img
      class="emoji"
      src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Confused%20face/Color/confused_face_color.svg"
      height="48"
    />
    <h1 class="my-5">{{ I18ns.errors.NotFound404.title }}</h1>
    <p>{{ I18ns.errors.NotFound404.subtitle }}</p>
    <a type="button" class="btn btn-primary mt-6" [routerLink]="route">
      <i class="bi bi-arrow-left-short ps-0 pe-3"></i>
      {{ I18ns.errors.NotFound404.return }}
    </a>
  </div>
</div>

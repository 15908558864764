<div class="panel-full d-flex flex-column justify-content-center align-items-center text-center">
  <img
    class="emoji"
    src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Building%20construction/Color/building_construction_color.svg"
    height="20"
  />
  <h1 class="mt-3">
    {{ I18ns.shared.noSmallScreen.title }}
  </h1>
  <p class="mt-3">{{ I18ns.shared.noSmallScreen.subtitle }}</p>
</div>

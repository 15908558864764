<alto-impersonation-header></alto-impersonation-header>
<img
  class="position-absolute top-0 end-0 background-img m-7"
  src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Rocket/Color/rocket_color.svg"
/>
<div class="panel-full d-flex justify-content-center align-items-center">
  <div class="text-center text-404">
    <img class="mb-8" width="182" src="assets/img/logo.png" alt="Alto Logo" />
    <h1 class="mb-5">
      {{ I18ns.shared.noWebAccess.title }}
    </h1>
    <p class="fs-5 w-60 d-inline-block">{{ I18ns.shared.noWebAccess.subtitle }}</p>
    <p class="mt-5 fs-5">
      {{ I18ns.shared.noWebAccess.goodbye }}
      <img
        class="emoji ms-2"
        src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Waving%20hand/Default/Color/waving_hand_color_default.svg"
        height="21"
      />
    </p>
  </div>
</div>
